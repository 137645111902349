import React from "react";

export const Input = React.forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      label,
      css,
      type,
      errors,
      placeHolder,
      icon,
      wrapperCls,
      disabled
    },
    ref
  ) => (
    <div
      className={[
        "relative flex flex-col items-start justify-start",
        wrapperCls,
      ].join(" ")}
    >
      <div className='relative flex w-full flex-row items-center justify-between'>
        {icon && (
          <label className='relative capitalize text-white mr-2'>{icon}</label>
        )}
        <input
          type={type}
          name={name || label}
          id={label}
          className={[css && css.input, ""].join(" ")}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeHolder}
          value={value}
          disabled={disabled}
        />
      </div>
      <div className='relative w-full text-right text-sm text-red-500'>
        {errors && errors.type === "required" && <span>This is required</span>}
      </div>
    </div>
  )
);
Input.defaultProps = {
  type: "number",
};

export const InputAlt = React.forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      name,
      label,
      css,
      type,
      errors,
      placeHolder,
      icon,
      accept,
      disabled
    },
    ref
  ) => (
    <input
      className={css || ""}
      type={type}
      name={name || label}
      id={label}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeHolder}
      value={value}
      tabIndex={type === "file" ? -1 : 0}
      accept={accept}
      disabled={disabled}
    />
  )
);
