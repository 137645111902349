/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Link } from "react-router-dom";

import { sideBarNavData } from "store";

const Sidebar = ({ isAuthenicated, lat, lan }) => {
  return (
    <aside
      className='md:relative fixed md:block hidden md:w-1/4 h-screen bg-gray-100 md:bg-transparent z-20 md:pt-10'
      aria-label='Sidebar'
      id='sideBarContainer'
    >
      <div className='sticky overflow-y-auto py-4 px-3  rounded'>
        <span className='flex items-center pl-2.5 mb-5'>
          <img
            src='/images/logo-detail.png'
            className='pr-3 h-auto max-h-20'
            alt='Logo'
          />
        </span>
        <ul className='space-y-2'>
          {isAuthenicated
            ? sideBarNavData?.map((item, i) => (
                <li key={i.toString()}>
                  <SidebarNavItem
                    name={item.name}
                    path={item.path}
                    icon={item.icon}
                    lat={lat}
                    lan={lan}
                  />
                </li>
              ))
            : null}
        </ul>
      </div>
    </aside>
  );
};

const SidebarNavItem = ({ name, path, icon, lat, lan }) => (
  <div className='flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100'>
    {icon && <div className='relative mr-4'>{icon}</div>}
    {name === "navigation" ? (
      <div
        className='relative text-left text-sm md:text-base font-normal capitalize map_hover'
        onClick={() => showInMapClicked(lat, lan)}
      >
        {name}
      </div>
    ) : (
      <Link to={path}>
        <div className='text-sm md:text-base font-normal capitalize'>
          {name}
        </div>
      </Link>
    )}
  </div>
);

const showInMapClicked = (lat, lan) => {
  window.open(
    `https://maps.google.com?q=${lat},${lan}`,
    "_blank",
    "noreferrer"
  );
};

export default Sidebar;
