import React from "react";

const Title = ({ text, size, wrapperCls, innerCls, ...titleProps }) => {
  const getSize = (size) => {
    let sizeCls = "";
    switch (size) {
      case "sm":
        sizeCls = "text-xl";
        break;
      case "md":
        sizeCls = "text-2xl";
        break;
      case "lg":
        sizeCls = "text-3xl";
        break;
      case "xl":
        sizeCls = "text-4xl";
        break;

      default:
        sizeCls = "text-xl";
        break;
    }

    return sizeCls;
  };
  return (
    <div
      className={["relative text-start capitalize", wrapperCls].join(" ")}
      {...titleProps}
    >
      <span
        className={[
          "text-blue-600 font-semibold",
          getSize(size),
          innerCls,
        ].join(" ")}
      >
        {text}
      </span>
    </div>
  );
};

export default Title;
