import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { BsPlayBtnFill } from "react-icons/bs";
import GoogleMapReact from "google-map-react";
import SideBar from "components/SideBar";
import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";

import { CURRENT_USER, OTHER_USER } from "store";
import { connect } from "react-redux";
import { getUserByHandleData } from "../../redux/actions/userActions";

const MAPS_API_KEY = "AIzaSyCQNtXI1gSI9u3BI65XMZ2-L6zntYnsrLo";

const MyLocation = (props) => {
  const params = useParams();
  const [user, setUser] = useState(undefined);
  const location = useLocation();

  const userInfo = {
    center: {
      lat: props.user.credentials?.latitude,
      lng: props.user.credentials?.longitude,
    },
    zoom: 10,
  };

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     console.log("Available");
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       const userlocation = {
  //         lat: position.coords.latitude,
  //         lng: position.coords.longitude,
  //       }
  //       setCenter(userlocation);
  //     });
  //   } else {
  //     console.log("Not Available");
  //   }
  //   // console.log(params);
  //   // let friend = friendsList.find(
  //   //   (f) => f.nickname.toLowerCase() === params.name.toLowerCase()
  //   // );
  //   // setCurrentFriend({ ...friend, coverImg: friend.avatar });
  // }, []);

  // useEffect(() => {
  //   const query = location.pathname;
  //   if (query !== null) {
  //     const pathArray = query.split("/");
  //     props.getUserByHandleData(pathArray[2]).then((data) => {
  //       setUser(data);
  //     });
  //   }
  // }, []);

  const renderMarker = (map, maps) => {
    let marker = new maps.Marker({
      position: userInfo.center,
      map,
      title: "user location",
    });
    return marker;
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div
        className="relative w-full flex items-start justify-star md:pr-8"
      >
               <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
      <div className='relative w-full md:w-3/4 mt-8 md:mr-4'>
        <Title text='map' size='lg' wrapperCls='my-8' />
        <div
          className='relative my-4 max-w-5xl w-full bg-blue-100'
          style={{ height: 500 }}
        >
          <GoogleMapReact
            bootstrapURLKeys={MAPS_API_KEY}
            defaultCenter={userInfo.center}
            defaultZoom={userInfo.zoom}
            onGoogleApiLoaded={({ map, maps }) => {
              renderMarker(map, maps);
            }}
          />
        </div>
        {/* <div className='relative flex flex-col items-center justify-start mb-8'>
          <Title text='5.58' size='md' />
          <div className='relative flex items-center justify-evenly space-x-4'>
            <span className='text-lg text-gray-400'>18 min</span>
            <span className='text-xl text-blue-500'>
              <BsPlayBtnFill />
            </span>
            <span className='text-lg text-gray-400'>15 mile</span>
          </div>
        </div> */}
        {/* <div className='relative w-2/3 flex flex-col items-center justify-start'>
          <div className='relative'>
            <span className='text-md text-gray-600 font-normal mr-4 capitalize'>
              {user?.fullName} Location :
            </span>
            <span className='text-md text-gray-900 font-normal mr-4 capitalize'>
              {user?.credentials?.city?.concat(
                ", ",
                user?.credentials?.state,
                ", ",
                user?.credentials?.country
              )}
            </span>
          </div>
          <div className='relative'>
            <span className='text-md text-gray-600 font-normal mr-4 capitalize'>
              Your Location :
            </span>
            <span className='text-md text-gray-900 font-normal mr-4 capitalize'>
              {props.user.credentials?.city?.concat(
                ", ",
                props.user.credentials?.state,
                ", ",
                props.user.credentials?.country
              ) || CURRENT_USER?.location}
            </span>
          </div>
        </div> */}
      </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getUserByHandleData,
};

export default connect(mapStateToProps, mapActionsToProps)(MyLocation);
