export const featuresData = [
  {
    title: 'Attend Motorcycle Events',
    img: '/images/landing_page/1.png',
  },
  {
    title: 'Connect with Riding Communities',
    img: '/images/landing_page/2.png',
  },
  {
    title: 'Discounted Parts, Apparel, Gas, Food & Lodging',
    img: '/images/landing_page/3.png',
  },
  {
    title: 'Coordinate Rides',
    img: '/images/landing_page/4.png',
  },
  {
    title: 'Road Conditions & Stolen bike Alerts',
    img: '/images/landing_page/6.png',
  },
  {
    title: 'Informational Videos',
    img: '/images/landing_page/7.png',
  },
];
