import { CTA } from 'components/shared/Button';

export const Hero = () => {
  return (
    <>
      <div className="relative inset-0 h-screen w-full overflow-hidden -z-[1]">
        <video
          playsInline
          autoPlay
          muted
          loop
          controls={false}
          className="absolute inset-0 h-[100%] w-full object-cover hidden md:block"
          src="/images/landing_page/dekstop_compressed.mp4"
        >
          Your browser doesn't support video playback.
        </video>

        <video
          id="mobile-video"
          className="absolute inset-0 h-full w-full object-cover md:hidden"
          playsInline
          autoPlay
          muted
          loop
          controls={false}
          src="/images/landing_page/mobile_compressed.mp4"
        ></video>
      </div>

      <div className="relative w-full h-max max-w-7xl mx-auto flex flex-col justify-center md:justify-end items-center px-2 md:px-0">
        {/* <span className="text-base pl-2">
          Unite, Discover, and Share Your Motorcycle Journey Globally
        </span> */}

        <h1 className="my-4 text-5xl md:text-7xl font-semibold mix-blend-difference text-center">
          Ride, Connect, Explore
        </h1>
        <div>
          <CTA className="text-white bg-blue-500">Learn More</CTA>
        </div>
      </div>
    </>
  );
};

export default Hero;
