import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AuthLayout from "components/shared/layouts/AuthLayout";
import FormWrapper from "components/shared/containers/FormWrapper";
import { Input } from "components/shared/Input";
import Button from "components/shared/Button";

import { connect } from "react-redux";
import { resetPassword } from "../../redux/actions/userActions";

const ForgotPassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onsubmit = (data) => {
    console.log(data);
    props.resetPassword(data, navigate);
  };
  const css = {
    input: "border-b border-gray-300 bg-transparent text-white flex-1 p-2 my-2",
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onsubmit)} className='relative w-full'>
        {/* <Input
          label="name"
          name="name"
          placeHolder="Name"
          value=""
          type="text"
          {...register("name", { required: true })}
          css={css}
          errors={errors.name}
          icon={<AiOutlineUser />}
        /> */}
        <Input
          label='email'
          name='email'
          placeHolder='E-Mail'
          type='text'
          {...register("email", { required: true })}
          css={css}
          errors={errors.email}
          icon={<AiOutlineLock />}
        />

        <Button
          type='submit'
          cls='bg-white text-blue-600 mt-8 w-full'
          value='Send'
        />
      </form>
      <div className='relative w-full text-center text-white text-sm flex flex-col items-center mt-8'>
        <span className='relative capitalize text-xs md:text-base'>
          after clicking "Send", your password will be sent to your Email
        </span>
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  resetPassword,
};

export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword);
