import React from "react";
import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";
import ParagraphWrapper from "components/shared/ParagraphWrapper";

const Disclaimer = (props) => {
  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude} isAuthenicated={props.user?.authenticated}>
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-48 md:pb-32 space-y-2'>
        <Title text='disclaimer' size='lg' wrapperCls='my-8' />
        <ParagraphWrapper innerCls='text-start'>
          The information contained in this website is for general information
          purposes only. The information is provided by Ibusiness name) and
          while we endeavour to keep the information up to date and correct, we
          make no representations or warranties of any kind, express or implied,
          about the completeness, accuracy, reliability, suitability or
          availability with respect to the website or the information, products,
          services, or related graphics contained on the website for any
          purpose. Any reliance you place on such information is therefore
          strictly at your own risk.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          In no event will we be liable for any loss or damage including without
          limitation, indirect or consequential loss or damage, or any loss or
          damage whatsoever arising from loss of data or profits arising out of,
          or in connection with, the use of this website. Through this website
          you are able to link to other websites which are not under the control
          of [business name. We have no control over the nature, content and
          availability of those sites. The inclusion of any links does not
          necessarily imply a recommendation or endorse the views expressed
          within them.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          Every effort is made to keep the website up and running smoothly.
          However, (business name) takes no responsibility for, and will not be
          liable for the website being temporarily unavailable due to technical
          issues beyond our control.
        </ParagraphWrapper>
      </div>
    </PageLayout>
  );
};

export default Disclaimer;
