import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PageLayout from "components/shared/layouts/PageLayout";
import ProfileHeader from "components/ProfileHeader";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import Button from "components/shared/Button";
import {
  getMyFriendRequestsData,
  updateFriendRequest,
  confirmFriendRequest,
} from "../../redux/actions/userActions";
import { updateNotifications } from "../../redux/actions/commonActions";

import useBounds from "hooks/useBounds";

import { PROFILE_HEADER_BUTTONS } from "store";

const FriendRequests = (props) => {
  // console.log(props.user.notifications);
  const { height } = useBounds(400);

  const [friendRequestList, setFriendRequestList] = useState([]);
  useEffect(() => {
    props.updateNotifications("friendRequest");
    props.getMyFriendRequestsData();
  }, []);

  const confirmRequestClick = (id) => {
    console.log("Confirm friend request");
    props.confirmFriendRequest(id);
  };
  const rejectRequestClick = (id) => {
    console.log("reject friend request");
    const selectedFriendRequests = props.user?.friendRequests.filter(
      (user) => user.frId === id
    );
    if (selectedFriendRequests && selectedFriendRequests.length > 0) {
      const friendRequestData = {
        frId: id,
        status: "reject",
      };
      props.updateFriendRequest(friendRequestData);
    }
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={props.user?.credentials}
        isAuthenicated={props.user?.authenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative w-full md:w-3/4 mt-8 md:mr-4'>
          <div className='relative py-4 px-8 md:px-0 flex w-full md:w-3/4 items-center justify-between border-b border-gray-800'>
            <div className='relative w-full flex flex-col md:flex-row items-center justify-between'>
              <Title
                text={
                  <span>
                    friend requests{" "}
                    <span className='text-gray-400 text-xl md:text-4xl'>
                      ({props.user.friendRequests?.length})
                    </span>
                  </span>
                }
                size='xl'
              />
            </div>
          </div>
          <div className='relative w-full'></div>
          {props.user.friendRequests?.length ? (
            props.user.friendRequests?.map((item, i) => {
              return (
                <div
                  className='relative flex items-center justify-between px-8 py-2 md:p-2 md:w-3/4 border-b'
                  key={i}
                >
                  <div className='flex items-center justify-start'>
                    <div className='relative w-4 md:w-12 h-4 md:h-12 rounded-full overflow-hidden'>
                      <img
                        src={item.senderImageUrl}
                        alt=''
                        className='relative w-full h-full object-center object-cover'
                      />
                    </div>
                    <div className='relative p-4'>
                      <span className='text-lg font-semibold capitalize'>
                        {item.senderName}
                      </span>
                    </div>
                    <div className='relative p-4'>
                      <span className='text-lg font-semibold capitalize'>
                        {item?.senderCity?.concat(
                          ", ",
                          item?.senderState,
                          ", ",
                          item?.senderCountry
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='flex items-center justify-end space-x-2'>
                    <Button
                      type='button'
                      cls='bg-blue-500 text-white w-96 tracking-widest uppercase'
                      inputCls='uppercase text-xs'
                      value='Accept'
                      onClick={() => confirmRequestClick(item.frId)}
                    />
                    <Button
                      type='button'
                      cls='bg-red-500 text-white w-96 tracking-widest uppercase'
                      inputCls='uppercase text-xs'
                      value='Reject'
                      onClick={() => rejectRequestClick(item.frId)}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <span>no requests</span>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  friendRequests :  state.friendRequests
});

const mapActionsToProps = {
  getMyFriendRequestsData,
  updateFriendRequest,
  updateNotifications,
  confirmFriendRequest,
};

export default connect(mapStateToProps, mapActionsToProps)(FriendRequests);
