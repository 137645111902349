import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCheck, AiFillDelete } from "react-icons/ai";

import PageLayout from "components/shared/layouts/PageLayout";
import SideBar from "components/SideBar";
import Button from "components/shared/Button";
import { notificationsList } from "store/data";
import useBounds from "hooks/useBounds";
import ProfileHeader from "components/ProfileHeader";
import Title from "components/shared/Title";

import { connect } from "react-redux";
import {
  getMyAlertsData,
  deleteAlert,
} from "../../redux/actions/commonActions";

import { PROFILE_HEADER_BUTTONS } from "store";

const MyAlerts = (props) => {
  const { height } = useBounds(532);
  const navigate = useNavigate();
  const [alertsList, setAlertsList] = useState([]);

  useEffect(() => {
    getAlerts();
  }, []);

  const getAlerts = () => {
    props
      .getMyAlertsData()
      .then((data) => {
        setAlertsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (eventId) => {
    props.deleteAlert(eventId, navigate).then(() => {
      getAlerts();
      console.log("Alert  delete successfully");
    });
  };

  return (
    <PageLayout 
    lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}
    >
      <ProfileHeader
        currentUser={props.user.credentials}
        buttonNames={PROFILE_HEADER_BUTTONS}
        isAuthenicated={props.user.authenticated}
      />
      <div className='relative w-full flex items-start justify-star md:pr-8'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative w-full md:w-3/4 mt-8 md:mr-4'>
          <div className='relative py-4 px-8 md:px-0 flex w-full md:w-3/4 items-center justify-between border-b border-gray-800'>
            <Title text='my alerts' size='lg' />
            <Button
              type='button'
              cls='bg-blue-600 text-xs text-white capitalize'
              value='make an alert'
              onClick={() => navigate("/alerts/add")}
            />
          </div>
          {/* <div className='relative w-full md:w-3/4 flex flex-col items-start pb-8 px-4 md:px-0'>
            {alertsList.map((item, i) => {
              return (
                <React.Fragment key={i.toString()}>
                  <div className='relative w-full py-8 flex flex-row items-center justify-start border-b'>
                    <div className='relative w-48 h-auto bg-blue-500'>
                      <img
                        src={item.uploadImageUrl}
                        alt='alert-img'
                        className='relative object-cover object-fit'
                      />
                    </div>
                    <div className='relative flex-1 px-4 flex flex-col items-start justify-start'>
                      <Link to={`/alert/${item.alertId}`}>
                        <div className='relative'>
                          <span className='relative text-lg md:text-2xl uppercase'>
                            {item.headline}
                          </span>
                        </div>
                      </Link>
                      <div className='relative'>
                        <span className='relative text-gray-600 text-sm md:text-base'>
                          {item.message}
                        </span>
                      </div>
                      <div className='relative flex flex-row items-center justify-between mt-4'>
                        <div className='relative flex items-center justify-start flex-row w-1/3 space-x-3'>
                          <span className='relative text-gray-900 mr-2 font-normal text-xs md:text-sm whitespace-pre'>
                            From :
                          </span>
                          <div className='relative w-4 md:w-8 h-4 md:h-8 rounded-full overflow-hidden'>
                            <img
                              src={item.userImage}
                              alt='header'
                              className='absolute object-center object-contain w-full h-full'
                            />
                          </div>
                          <div className='relative flex flex-col items-start justify-center ml-4'>
                            <span className='relative text-gray-900 font-semibold text-xs md:text-sm uppercase'>
                              {item.fullName}
                            </span>
                          </div>
                        </div>
                        <div className='relative flex flex-col items-start justify-center ml-4'>
                          <span className='relative text-gray-900 font-normal text-xs md:text-sm'>
                            {item.location}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='relative flex-1 flex items-start justify-end'>
                      <span
                        aria-hidden
                        className='text-xl text-blue-500 p-2 rounded-full hover:bg-blue-500 hover:text-white cursor-pointer'
                        // onClick={() => typeof onDelete === "function" && onDelete()}
                      >
                        <AiFillDelete />
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div> */}
          <div className='relative w-full md:w-3/4 flex flex-col items-start pb-8 px-4 md:px-0'>
            {alertsList.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <div className='relative py-8 flex flex-row items-center justify-start border-b w-full'>
                  <div className='relative'>
                    <img
                      src={item.uploadImageUrl}
                      alt=''
                      className='relative object-contain object-fit w-36 md:max-w-48 max-h-48 overflow-hidden'
                    />
                  </div>
                  <div className='relative flex-1 px-2 md:px-4 flex flex-col items-start justify-start'>
                    <div className='relative w-full flex flex-row flex-nowrap items-center justify-between'>
                      <Link to={`/alert/${item.alertId}`}>
                        <span className='relative text-lg md:text-2xl uppercase'>
                          {item.headline}
                        </span>
                      </Link>
                      <div className='relative flex-1 flex items-start justify-end'>
                        <span
                          aria-hidden
                          className='text-xl text-blue-600 p-2 rounded-full hover:bg-blue-600 hover:text-white cursor-pointer'
                          onClick={() => onDelete(item?.alertId)}
                          // onClick={() => typeof onDelete === "function" && onDelete()}
                        >
                          <AiFillDelete />
                        </span>
                      </div>
                    </div>
                    <div className='relative'>
                      <span className='relative text-gray-600 text-sm md:text-base'>
                        {item.message}
                      </span>
                    </div>
                    <div className='relative flex flex-col  md:flex-row items-start md:items-center justify-between w-full mt-4'>
                      <div className='relative flex items-center justify-start flex-row w-1/3 space-x-3'>
                        <span className='relative text-black md:mr-2 font-normal text-xs md:text-sm whitespace-pre'>
                          Posted by :
                        </span>
                        <div className='relative w-4 md:w-8 h-4 md:h-8 rounded-full overflow-hidden'>
                          <img
                            src={item.userImage}
                            alt='header'
                            className='absolute object-center object-contain w-full h-full z-0'
                          />
                        </div>
                        <div className='relative flex flex-col items-start justify-center ml-4'>
                          <span className='relative text-black font-semibold text-xs md:text-sm uppercase whitespace-nowrap md:whitespace-pre-wrap'>
                            {item.fullName}
                          </span>
                        </div>
                      </div>
                      <div className='relative flex flex-col items-start justify-center ml-0 md:ml-4'>
                        <span className='relative text-black font-normal text-xs md:text-sm'>
                          {item.location}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getMyAlertsData,
  deleteAlert,
};

export default connect(mapStateToProps, mapActionsToProps)(MyAlerts);
