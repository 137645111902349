import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";

import { useAuth } from "hooks/useAuth";

import AuthLayout from "components/shared/layouts/AuthLayout";
import { Input } from "components/shared/Input";
import Button from "components/shared/Button";

const ConfirmEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { login } = useAuth();
  const navigate = useNavigate();

  const onsubmit = (data) => {
    console.log(data);
    login(data).then(() => navigate("/home"));
  };
  const css = {
    input: "border-b border-gray-300 bg-transparent text-white flex-1 p-2 my-2",
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onsubmit)} className="relative w-full">
        <Input
          label="confirmCode"
          name="confirmCode"
          placeHolder="Enter the code sent to your email"
          value=""
          type="number"
          {...register("confirmCode", { required: true })}
          css={css}
          errors={errors.confirmCode}
          icon={<AiOutlineUser />}
        />
        <Button
          type="submit"
          cls="bg-white text-blue-600 mt-8 w-full"
          value="Confirm"
          onClick={onsubmit}
        />
      </form>
      <div className="relative w-full text-right text-white text-sm flex flex-col items-end mt-4">
        {/* <Link to="/forgot-password">
          <span className="relative capitalize">forgot password</span>
        </Link> */}
      </div>
    </AuthLayout>
  );
};

export default ConfirmEmail;
