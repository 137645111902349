import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import PageLayout from "components/shared/layouts/PageLayout";
import ProfileHeader from "components/ProfileHeader";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import Button from "components/shared/Button";

import { PROFILE_HEADER_BUTTONS } from "store";
import { Fragment } from "react";
import { messagesList } from "store/data";
import { getMyStolenBikeData, updateNotifications } from "../../redux/actions/commonActions";

const StolenBikes = (props) => {
  const navigate = useNavigate();
  const isAuthenticated = props.user.authenticated;
  const curUser = props.user.credentials;
  const [stolenBikesList, setStolenBikesList] = useState([]);

  useEffect(() => {
    props.updateNotifications("stolenBike");
    props
      .getMyStolenBikeData()
      .then((data) => {
        setStolenBikesList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={curUser}
        isAuthenicated={isAuthenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative flex-1 my-4 p-4 max-h-96 overflow-hidden'>
          <Title
            text={
              <span>
                stolen bikes{" "}
                <span className='text-gray-400 text-xl md:text-4xl'>
                  ({stolenBikesList.length})
                </span>
              </span>
            }
            size='xl'
            wrapperCls='pb-4'
          />
          <div className='relative w-full h-auto pr-32 border-t'>
            <div className='relative w-full h-auto'>
              {stolenBikesList.map((bike, i) => {
                return (
                  <Fragment key={i.toString()}>
                    <div className='relative w-full flex items-center justify-between border-b'>
                      <div className='relative p-2 flex flex-col items-center w-auto mr-4'>
                        <img
                          src={bike?.userImage}
                          alt='user'
                          className='w-8 lg:w-16 h-8 lg:h-16 object-fill rounded-full'
                        />
                        <span className='text-sm py-2 capitalize text-gray-400 font-semibold'>
                          {bike?.fullName}
                        </span>
                      </div>
                      <div className='flex-1 flex flex-col items-start justify-start'>
                        <ul className='w-full grid grid-cols-2 mr-4'>
                          <StolenBike left='Location' right={bike.location} />
                          <StolenBike left='Date' right={bike.date} />
                          <StolenBike left='Model' right={bike.model} />
                          <StolenBike left='Year' right={bike.year} />
                          <StolenBike left='Color' right={bike.color} />
                          <StolenBike
                            left='Manufacturer'
                            right={bike.manufacturer}
                          />
                        </ul>
                      </div>
                      <div className='relative flex flex-row items-center justify-end px-4'>
                        <Button
                          type='button'
                          cls='bg-blue-500 text-white  tracking-widest uppercase my-1 md:my-0'
                          inputCls={"text-xs md:text-base"}
                          value='View'
                          onClick={() =>
                            navigate(`/stolen-bikes/${bike?.stolenBikeId}`)
                          }
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const StolenBike = ({ left, right }) => {
  return (
    <li>
      <div className='relative my-1 w-full flex items-center justify-start'>
        <span className='text-gray-400 mr-2'>{left || ""}</span>
        <span className='text-black mr-2'>{right || ""}</span>
      </div>
    </li>
  );
};

const mapActionsToProps = {
  getMyStolenBikeData,
  updateNotifications
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(StolenBikes);
