import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI
  } from '../types';
  import axios from 'axios';
  import toast from "react-hot-toast";

  import { uuid } from 'uuidv4';

  import {storage} from '../../firebase'

  import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

  export const uploadVideo = (uploadData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
            .post('/uploadVideo', uploadData)
            .then((res) => {    
              toast.success(res.data.message);    
              dispatch({ type: CLEAR_ERRORS });
              navigate("/home")
            })
            .catch((err) => {
              toast.error(err.response.data);
              dispatch({
                type: SET_ERRORS,
                payload: err.response.data
              });
            });
  }

  export const upload = (uploadData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });    
    axios
      .post('/upload', uploadData)
      .then((res) => {  
        toast.success(res.data.message);     
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        toast.error(err.response.data);
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const getAllUploadData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/uploads')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getMyUploadData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/my-uploads')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getUploadsByUserHandleData = (userHandle) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/friends-upload/${userHandle}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getUploadsByRidersNearbyData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/riders-nearby`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getSearchData = (queryParam) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/uploads/search/${queryParam}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

