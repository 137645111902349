import React, { useState, useEffect, Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { AiOutlineClose } from 'react-icons/ai';
import { Popover } from '@headlessui/react';

import 'react-datepicker/dist/react-datepicker.css';

import Button from 'components/shared/Button';
import PageLayout from 'components/shared/layouts/PageLayout';
import { InputAlt } from 'components/shared/Input';
import InputWrapper from 'components/shared/InputWrapper';
import Title from 'components/shared/Title';
import Modal from 'components/Modal';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createEvent } from '../../redux/actions/eventActions';
import { friendsList } from 'store/data';
import { storage } from '../../firebase';

import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from 'firebase/storage';

const AddEvent = (props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [isRecipientButtonEnable, setIsRecipientButtonEnable] = useState(true);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploadState, setUploadState] = useState('uploading');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const css =
    'relative w-full appearance-none p-4 rounded-md border border-gray-200';

  useEffect(() => {
    console.log(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setSelectedFriends([]);
  }, []);

  async function uploadImage(image) {
    const storageRef = ref(storage, `/events/${Date.now()}-${image.name}`);

    const response = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(response.ref);
    return url;
  }

  async function uploadImages(images) {
    const imagePromises = Array.from(images, (image) => uploadImage(image));

    const imageRes = await Promise.all(imagePromises);
    return imageRes; // list of url like ["https://..", ...]
  }

  const onsubmit = async (data) => {
    if (selectedFiles && selectedFiles.length > 0) {
      setSubmitting(true);
      if (data.privacy === 'Private') {
        if (selectedFriends && selectedFriends.length > 0) {         
          data.recipients = selectedFriends;          
        }
      }
      if(!data.startTime){
        data.startTime = new Date();
      }
      if(!data.endTime){
        data.endTime = new Date();
      }
      data.imageUrls = await uploadImages(selectedFiles);
      data.uploadImageUrl = data.imageUrls[0];
      props.createEvent(data, navigate);
      setSubmitting(false);
    }
  };

  const getRecipientsData = (data) => {
    console.log('parent', data);
    const selectedFriends = data.filter((friend) => friend.isSelected);
    //  data.map((item) => {
    //   if (item.isSelected) {
    //     return item;
    //   }
    // });
    setSelectedFriends(selectedFriends);
  };

  const openModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  const onRecipientTypeChange = (e) => {
    console.log(e.target.value);
    if (e.target.value == 'Public') {
      setIsRecipientButtonEnable(false);
    } else {
      setIsRecipientButtonEnable(true);
    }
  };

  const onChangeFileInput = (e) => {
    const files = [...e.target.files];
    const uploaded = selectedFiles ? [...selectedFiles] : [];
    files.some((file) => {
      uploaded.push(file);
    });
    setSelectedFiles(uploaded);
    console.log(uploaded);
  };

  return (
    <PageLayout
      lat={props.user?.credentials?.latitude}
      lan={props.user?.credentials?.longitude}
      isAuthenicated={props.user?.authenticated}
    >
      {/* {modalOpen && (
        <FriendsModal
          closeAction={closeModal}
          changeAction={getRecipientsData}
          friends={props.user?.friends.map((friend) => {
            friend.isSelected = false;
            return friend;
          })}
        />
      )} */}
      <div className="relative w-full flex flex-col items-center justify-start">
        <Title text="add event" size="lg" wrapperCls="my-8" />
        <form
          onSubmit={handleSubmit(onsubmit)}
          className="relative w-full md:w-1/3 px-8 md:px-0 pb-16"
        >
          <InputWrapper label="Add Location" name="location" fullWidth>
            <InputAlt
              label="location"
              name="location"
              placeHolder="Location"
              type="text"
              css={css}
              {...register('location')}
            />
          </InputWrapper>
          <InputWrapper label="Event Title" name="title" fullWidth>
            <InputAlt
              label="title"
              name="title"
              placeHolder="Event Title"
              type="text"
              css={css}
              {...register('title')}
            />
          </InputWrapper>
          <InputWrapper label="Select Date" name="date" fullWidth>
            <Controller
              control={control}
              name={'date'}
              render={({ field }) => (
                <DatePicker
                  selected={startDate}
                  placeholderText="Select date"
                  onChange={(date) => {
                    field.onChange(date);
                    setStartDate(date);
                  }}
                  className={css}
                  // tabIndex={-1}
                />
              )}
            />
          </InputWrapper>
          <div className="relative flex items-center justify-evenly space-x-2">
            <InputWrapper
              label="Start Time"
              name="startTime"
              wrapperCls={'flex-1'}
            >
              <Controller
                control={control}
                name="startTime"
                render={({ field }) => (
                  <DatePicker
                    selected={startTime}
                    placeholderText="Select start time"
                    onChange={(time) => {
                      field.onChange(time);
                      setStartTime(time);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={[css, ''].join(' ')}
                  />
                )}
              />
            </InputWrapper>

            <InputWrapper label="End Time" name="endTime" wrapperCls={'flex-1'}>
              <Controller
                control={control}
                name="endTime"
                render={({ field }) => (
                  <DatePicker
                    selected={endTime}
                    placeholderText="Select End Time"
                    onChange={(time) => {
                      field.onChange(time);
                      setEndTime(time);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={[css, ''].join(' ')}
                  />
                )}
              />
            </InputWrapper>
          </div>
          <InputWrapper
            label={`${selectedFile ? selectedFile.name : 'Attach Files'}`}
            name="file"
            labelCls={css + ' py-5 cursor-pointer hover:bg-gray-200'}
            fullWidth
          >
            {/* <InputAlt
              label='file'
              name='file'
              placeHolder='Attach Files'
              type='file'
              css={" input-file"}
              {...register("file")}
              onChange={(e) => setSelectedFile(e.target.files[0])}
            /> */}
            <input
              type="file"
              name="file"
              id="file"
              multiple
              className="input-file focus:ring-blue-500 focus:border-blue-500 focus-visible:ring-blue-500 focus-visible:border-blue-500"
              accept="image/*"
              {...register('file')}
              onChange={onChangeFileInput}
              tabIndex={-1}
            />
            <div
              className="w-full flex flex-col items-start overflow-y-scroll"
              style={{ maxHeight: 100 }}
            >
              {selectedFiles &&
                selectedFiles.map((file, i) => (
                  <span className="text-xs py-0.5 px-1" key={i}>
                    {i + 1}. {file.name}
                  </span>
                ))}
            </div>
          </InputWrapper>
          <InputWrapper label="Privacy" name="privacy" required fullWidth>
            <select
              name="privacy"
              id="privacy"
              className={css}
              {...register('privacy')}
              onChange={(e) => onRecipientTypeChange(e)}
            >
              <option value="Private">Private</option>
              <option value="Public">Public</option>
            </select>
          </InputWrapper>
          <InputWrapper label="Brief Description" name="description" fullWidth>
            <textarea
              label="description"
              name="description"
              placeholder="Brief Description"
              type="textarea"
              className={css}
              rows={4}
              cols={22}
              {...register('description')}
            />
          </InputWrapper>

          <Popover>
            <Popover.Button as={Fragment} className="w-full">
              <Button
                type="button"
                cls={
                  !isRecipientButtonEnable
                    ? 'bg-gray-100 text-black my-3 w-full'
                    : 'bg-gray-700 text-white my-3 w-full'
                }
                value="Reciepients"
                disabled={!isRecipientButtonEnable}
              />
            </Popover.Button>

            <Popover.Panel className="absolute z-10">
              {({ close }) => (
                <FriendsModal
                  closeAction={close}
                  changeAction={getRecipientsData}
                  friends={props.user?.friends.map((friend) => {
                    friend.isSelected = false;
                    return friend;
                  })}
                />
              )}
            </Popover.Panel>
          </Popover>

          <Button
            type="submit"
            cls="bg-blue-600 text-white my-3 w-full"
            value="Post event"
            isLoading={isSubmitting}
            //   onClick={onsubmit}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const FriendsModal = ({ closeAction, changeAction, friends }) => {
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selected, setSelected] = useState(
    new Array(friends.length).fill(false)
  );

  const updateSelectedFriends = (index) => {
    // const updatedCheckedState = selected.map((item, i) =>
    //   i === index ? !item : item
    // );
    const selectedFriend = [...friends].find((_, i) => i === index);
    //const isFriendSelected = selected[index];

    const updatedSelectedFriends = [...friends];

    if (selectedFriend.isSelected) {
      updatedSelectedFriends.forEach((f, i) => {
        if (f.fullName === selectedFriend.fullName) {
          f.isSelected = false;
        }
      });
      setSelectedFriends(updatedSelectedFriends);
    } else {
      updatedSelectedFriends.forEach((f, i) => {
        if (f.fullName === selectedFriend.fullName) {
          f.isSelected = true;
        }
      });
      setSelectedFriends(updatedSelectedFriends);
    }
    friends = updatedSelectedFriends;
    // setSelected(updatedCheckedState);
  };

  const onClickConfirm = () => {
    console.log('confirm', selectedFriends);
    typeof changeAction === 'function' && changeAction(selectedFriends);
    typeof closeAction === 'function' && closeAction();
  };

  return (
    <Modal>
      <div className="relative w-full bg-gray-50 rounded-md p-2 flex items-center justify-between">
        <span className="relative text-gray-700 font-bold capitalize">
          select friends
        </span>
        <span
          className="relative text-red-700 cursor-pointer"
          onClick={() => {
            typeof closeAction === 'function' && closeAction();
          }}
        >
          <AiOutlineClose />
        </span>
      </div>
      <div className="relative my-4 w-full h-96 overflow-y-auto">
        <div className="relative  flex flex-col w-full">
          {friends.map((friend, i) => {
            return (
              <div
                className="relative w-full flex flex-row items-center justify-between py-2 px-4 border-b border-gray-100"
                key={i.toString()}
              >
                <span className="relative capitalize">{friend.fullName}</span>
                <span className="relative capitalize text-gray-400 hidden md:inline-block">
                  {friend?.city?.concat(
                    ', ',
                    friend?.state,
                    ', ',
                    friend?.country
                  )}
                </span>{' '}
                {/*optional data*/}
                <button
                  className={[
                    'relative capitalize cursor-pointer text-sm text-white rounded-full px-2 py-1',
                    friend?.isSelected ? 'bg-green-500' : 'bg-blue-500',
                  ].join(' ')}
                  onClick={() => updateSelectedFriends(i)}
                >
                  {friend?.isSelected ? 'selected' : 'select'}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <Button
          type="button"
          cls="bg-blue-500 text-white w-96 tracking-widest uppercase"
          inputCls="uppercase text-xs"
          value="confirm"
          onClick={onClickConfirm}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  createEvent,
};

export default connect(mapStateToProps, mapActionsToProps)(AddEvent);
