import React, { useState, useRef, useEffect } from "react";
import { AiOutlineClose, AiOutlineFullscreen } from "react-icons/ai";

const LightBox = ({ imgUrl }) => {
  const [showFull, setShowFull] = useState(false);
  const imgC = useRef();

  const fullScreen = () => {
    if (!imgC.current) return;

    const elem = imgC.current;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  // useEffect(() => {
  //   if (showFull) {
  //     window.history.pushState(null, null, window.location.href);
  //     window.history.back();
  //     window.onpopstate = () => window.history.forward();
  //   }
  // }, [showFull]);

  const toggleFullScreen = () => {
    window.history.pushState(null, null, window.location.pathname);
    setShowFull(!showFull);
  };

  return (
    <div className='relative'>
      <img
        src={imgUrl || ""}
        alt='thumb'
        className='relative object-cover object-center'
        onClick={toggleFullScreen}
      />
      {showFull && (
        <div className='fixed inset-0 bg-black bg-opacity-90 z-50 flex flex-col items-center justify-center'>
          <div className='relative m-4 flex items-center justify-center space-x-3'>
            <span
              className='relative py-1 px-2 md:p-3 text-sm md:text-lg text-white bg-blue-500 rounded-full flex items-center justify-center cursor-pointer'
              onClick={fullScreen}
            >
              Full screen {"  "}
              <AiOutlineFullscreen />
            </span>
            <span
              className='relative py-1 px-2 md:p-3 text-sm md:text-lg text-white bg-blue-500 rounded-full flex items-center justify-center cursor-pointer'
              onClick={() => setShowFull(false)}
            >
              Close {"  "}
              <AiOutlineClose />
            </span>
          </div>
          <img
            src={imgUrl || ""}
            alt='full'
            className='relative h-3/4 w-auto object-contain object-center'
            ref={imgC}
          />
        </div>
      )}
    </div>
  );
};

export default LightBox;
