import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI
  } from '../types';
  import axios from 'axios';

  import { uuid } from 'uuidv4';

  export const createStolenBike = (stolenBikeData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/stolenBike', stolenBikeData)
      .then((res) => {       
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const createHelp = (helpData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/help', helpData)
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  }

  export const createAlert = (alertData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/alert', alertData)
      .then((res) => {       
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const getAllAlertsData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/alerts')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getMyAlertsData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/my-alerts')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getMyHelpsData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/my-helps')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getAlertData = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/alert/${id}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getHelpData = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/help/${id}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const createSuggeston = (suggestionData, selectedFile, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    const formData = new FormData();    
    formData.append("file", selectedFile);
    formData.append("name", suggestionData.name);
    formData.append("email", suggestionData.email);
    formData.append("message", suggestionData.message);
    axios
      .post('/suggestion', formData)
      .then((res) => {       
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const createContactUs = (contactUsData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/contact-us', contactUsData)
      .then((res) => {       
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const updateNotifications = (type, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .patch(`/notifications/${type}`)
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const deleteAlert  =  (id, navigate) => (dispatch) => {
    dispatch({type : LOADING_UI});
    return new Promise((resolve, reject) => {
      axios.delete(`/alert/${id}`)
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        navigate("/alerts/my-alerts");
        resolve(res.data)
      })
      .catch(
        (err) => {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          reject(err)
        } 
          );
    })
  }

  export const getMyStolenBikeData = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get('/my-stolen-bikes')
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };

  export const getStolenBikeData = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
      .get(`/stolen-bikes/${id}`)
      .then((res) => {
        resolve(res.data)
      })
      .catch(
          (err) => 
          reject(err)
          );
    })    
  };