import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const leftMenu = ['©2022', 'riders on video', 'All Rights Reserved'];
const rightMenu = [
  { name: 'about us', path: '/about-us' },
  { name: 'terms and conditions', path: '/terms' },
  { name: 'privacy', path: '/privacy' },
  { name: 'disclaimer', path: '/disclaimer' },
  { name: 'contact us', path: '/contact' },
  { name: 'suggestions', path: '/suggestions' },
];

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <footer
      className={[
        'relative bottom-14 md:bottom-0 w-full bg-gray-800 border-t border-blue-600',
        pathname === '/inbox' ? 'hidden' : 'block',
      ].join(' ')}
    >
      <div className="max-w-7xl mx-auto flex flex-wrap items-center justify-center px-3 py-3 md:py-5 md:justify-between">
        <div className="flex flex-wrap justify-center space-x-2">
          {leftMenu.map((item, i) => {
            let menuItem = '';
            i === 1
              ? (menuItem = (
                  <span
                    className="uppercase text-blue-500 text-xs"
                    key={i.toString()}
                  >
                    {item}
                  </span>
                ))
              : (menuItem = (
                  <span className="text-white text-xs" key={i.toString()}>
                    {item}
                  </span>
                ));
            return menuItem;
          })}
        </div>
        <div className="grid grid-cols-2 justify-start md:flex md:justify-center mt-2 md:mt-0 md:space-x-2">
          {rightMenu.map((menuItem, i) => {
            return (
              <React.Fragment key={i.toString()}>
                <Link to={menuItem.path}>
                  <span
                    className={[
                      'text-white flex-1 hover:underline w-full hover:text-gray-200 text-xs capitalize',
                      i % 2 ? '' : 'text-end',
                    ].join(' ')}
                    tabIndex={-1}
                  >
                    {menuItem.name}
                  </span>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
