import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { connect } from "react-redux";
import { getUserByHandleData } from "../../redux/actions/userActions";

import SideBar from "components/SideBar";

let center = { lat: 48.8584, lng: 2.2945 };

const Location = (props) => {
  const location = useLocation();

  const [dest, setDest] = useState("");
  const [origin, setOrigin] = useState(null);

  const userInfo = {
    center: {
      lat: props.user.credentials?.latitude,
      lng: props.user.credentials?.longitude,
    },
    zoom: 10,
  };

  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const pathArray = query.split("/");
      props.getUserByHandleData(pathArray[2]).then((data) => {
        setDest({
          lat: data?.credentials?.latitude,
          lng: data?.credentials?.longitude,
        });
      });
    }
  }, []);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    center = {
      lat: props.user?.credentials?.latitude,
      lng: props.user?.credentials?.longitude,
    };
    if (
      !props.user?.credentials?.latitude ||
      !props.user?.credentials?.longitude
    ) {
      setOrigin(props.user?.credentials?.city);
    } else {
      setOrigin({
        lat: props.user?.credentials?.latitude,
        lng: props.user?.credentials?.longitude,
      });
    }
  }, [props.user]);

  useEffect(() => {
    if (isLoaded) calculateRoute();
  }, [dest, origin, isLoaded]);

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  if (!isLoaded) {
    return <span>Loading ...</span>;
  }

  async function calculateRoute() {
    if (dest === "" || !origin) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    directionsService
      .route({
        origin: origin,
        destination: dest,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((results) => {
        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      });
  }

  // function clearRoute() {
  //   setDirectionsResponse(null)
  //   setDistance('')
  //   setDuration('')
  // }
  console.log(props.user);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div
        className='relative w-full flex items-start justify-start'
        style={{ height: 900 }}
      >
               <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <div className='relative w-full h-3/4 m-8 border rounded-sm'>
          <div className='relative py-4 px-8 md:px-0 flex w-full items-center justify-center border-b '>
            <Title text='location' size='lg' />
          </div>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
          {dest ? (
            <div className='relative flex flex-col items-center justify-center w-full py-4'>
              <span>Distance: {distance} </span>
              <span>Duration: {duration} </span>
            </div>
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getUserByHandleData,
};

export default connect(mapStateToProps, mapActionsToProps)(Location);
