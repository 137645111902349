import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI
  } from '../types';
  import axios from 'axios';

  import toast from "react-hot-toast";

  export const createEvent = (eventData, navigate) => (dispatch) => {
    dispatch({ type: LOADING_UI });
   
    if(eventData.privacy == "Private")
    eventData.recipients = JSON.stringify(eventData.recipients);
    axios
      .post('/event', eventData)
      .then((res) => {       
        toast.success(res.data.message);    
        dispatch({ type: CLEAR_ERRORS });
        navigate("/home")
      })
      .catch((err) => {
        toast.error(err.response.data);
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  }

  export const getAllEventsData = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return new Promise((resolve, reject) => {
        axios
      .get('/events')
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        resolve(res.data)
      })
      .catch(
          (err) => {
            dispatch({
              type: SET_ERRORS,
              payload: err.response.data
            });
            reject(err)
          } 
          
          );
    })    
  };

  export const getMyEventsData = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return new Promise((resolve, reject) => {
        axios
      .get('/my-events')
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        resolve(res.data)
      })
      .catch(
        (err) => {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          reject(err)
        } 
          );
    })    
  };

  export const getEventData = (id) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return new Promise((resolve, reject) => {
        axios
      .get(`/event/${id}`)
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        resolve(res.data)
      })
      .catch(
        (err) => {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          reject(err)
        } 
          );
    })    
  };

  export const deleteEvent  =  (id, navigate) => (dispatch) => {
    dispatch({type : LOADING_UI});
    return new Promise((resolve, reject) => {
      axios.delete(`/event/${id}`)
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        navigate("/events/my-events");
        resolve(res.data)
      })
      .catch(
        (err) => {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          reject(err)
        } 
          );
    })
  }

  export const updateEventAction = (id, type) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return new Promise((resolve, reject) => {
        axios
      .patch(`/event/${id}/eventAction`, {eventId: id, type})
      .then((res) => {
        dispatch({ type: CLEAR_ERRORS });
        resolve(res.data)
      })
      .catch(
        (err) => {
          dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          reject(err)
        } 
          );
    })    
  };