import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import Home from 'pages/Home';
import Alerts from 'pages/Alerts/index';
import SingleAlert from 'pages/Alerts/SingleAlert';
import Events from 'pages/Events/index';
import SingleEvent from 'pages/Events/SingleEvent';
import AddEvent from 'pages/Events/AddEvent';
import StolenBikesForm from 'pages/StolenBikesForm';
import StolenBikes from 'pages/common/StolenBikes';
import StolenBikeSingle from 'pages/common/StolenBikeSingle';
import Inbox from 'pages/Chat/Inbox';
import UploadVideo from 'pages/UploadVideo';
import UploadImage from 'pages/UploadImage';
import RidersNearby from 'pages/RidersNearby';
import Help from 'pages/common/Help';
import MyEvents from 'pages/Events/MyEvents';
import MyAlerts from 'pages/Alerts/MyAlerts';
import AddAlert from 'pages/Alerts/AddAlert';
import MyUploads from 'pages/MyUploads';
import Navigation from 'pages/Navigation';
import Helps from 'pages/common/Helps';
import HelpSingle from 'pages/common/HelpSingle';

// profile
import MyProfile from 'pages/profile/MyProfile';
import EditProfile from 'pages/profile/EditProfile';
import EditAccount from 'pages/profile/EditAccount';
import FriendsPage from 'pages/profile/FriendsPage';
import FriendProfile from 'pages/profile/FriendProfile';
import Location from 'pages/profile/Location';
import MyLocation from 'pages/profile/MyLocation';
import FriendRequests from 'pages/profile/FriendsRequests';

// auth
import LogIn from 'pages/auth/Login';
import SignUp from 'pages/auth/SignUp';
import ChangePassword from 'pages/auth/ChangePassword';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ConfirmEmail from 'pages/auth/ConfirmEmail';

//common
import About from 'pages/common/About';
import ContactUs from 'pages/common/ContactUs';
import Disclaimer from 'pages/common/Disclaimer';
import Privacy from 'pages/common/Privacy';
import Suggestions from 'pages/common/Suggestions';
import Terms from 'pages/common/Terms';

//landing page
import LandingPage from 'pages/LandingPage';

const RoutesTree = () => {
  return (
    <Routes>
      {/* <Route exact path="events" element={<ProtectedRoute />}></Route> */}
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/search" element={<Home />} />
      <Route exact path="/stolen-bikes/add" element={<StolenBikesForm />} />
      <Route exact path="/stolen-bikes" element={<StolenBikes />} />
      <Route exact path="/stolen-bikes/:bike" element={<StolenBikeSingle />} />

      <Route exact path="/riders-nearby" element={<RidersNearby />} />
      <Route exact path="/help" element={<Help />} />
      <Route exact path="/navigation" element={<Navigation />} />

      <Route exact path="/alerts" element={<Alerts />} />
      <Route exact path="/alert/:id" element={<SingleAlert />} />
      <Route exact path="/alerts/my-alerts" element={<MyAlerts />} />
      <Route exact path="/alerts/add" element={<AddAlert />} />

      <Route exact path="/events" element={<Events />} />
      <Route exact path="/event/:id" element={<SingleEvent />} />
      <Route exact path="/events/add" element={<AddEvent />} />
      <Route exact path="/events/my-events" element={<MyEvents />} />

      <Route exact path="/my-uploads" element={<MyUploads />} />

      <Route exact path="/inbox" element={<Inbox />} />
      <Route exact path="/inbox/:id" element={<Inbox />} />

      <Route exact path="/upload-video" element={<UploadVideo />} />
      <Route exact path="/upload-image" element={<UploadImage />} />

      <Route exact path="/my-profile" element={<MyProfile />} />
      <Route exact path="/edit-profile" element={<EditProfile />} />
      <Route exact path="/account-settings" element={<EditAccount />} />
      <Route exact path="/friends" element={<FriendsPage />} />
      <Route exact path="/friend/:handle" element={<FriendProfile />} />
      <Route exact path="/friend/:handle/location" element={<Location />} />
      <Route exact path="/my-location" element={<MyLocation />} />
      <Route exact path="/friend-requests" element={<FriendRequests />} />

      <Route exact path="/login" element={<LogIn />} />
      <Route exact path="/signup" element={<SignUp />} />
      <Route exact path="/change-password" element={<ChangePassword />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/confirm-email" element={<ConfirmEmail />} />

      <Route exact path="/about-us" element={<About />} />
      <Route exact path="/contact" element={<ContactUs />} />
      <Route exact path="/disclaimer" element={<Disclaimer />} />
      <Route exact path="/privacy" element={<Privacy />} />
      <Route exact path="/suggestions" element={<Suggestions />} />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/helps" element={<Helps />} />
      <Route exact path="/helps/:id" element={<HelpSingle />} />
    </Routes>
  );
};

// const ProtectedRoute = () => {
//   let { user } = useAuth();
//   return user ? <Outlet /> : <Navigate to="/login" />;
// };

export default RoutesTree;
