import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";

import Button from "components/shared/Button";
import PageLayout from "components/shared/layouts/PageLayout";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";

import { connect } from "react-redux";
import { createAlert } from "../../redux/actions/commonActions";
import { useNavigate } from "react-router-dom";
import { storage } from "../../firebase";

import { ref, getDownloadURL, uploadBytesResumable, uploadBytes } from "firebase/storage";

const AddAlert = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState("");

  const css =
    "relative w-full appearance-none p-4 rounded-md border border-gray-200";

    async function uploadImage(image) {
      const storageRef = ref(storage, `/events/${Date.now()}-${image.name}`);
    
      const response = await uploadBytes(storageRef, image);
      const url = await getDownloadURL(response.ref);
      return url;
    }
    
    async function uploadImages(images) {
      const imagePromises = Array.from(images, (image) => uploadImage(image));
    
      const imageRes = await Promise.all(imagePromises);
      return imageRes; // list of url like ["https://..", ...]
    }
  const onsubmit = async (data) => {
    if (selectedFiles && selectedFiles.length > 0) {  
      data.imageUrls = await uploadImages(selectedFiles);  
      data.uploadImageUrl = data.imageUrls[0];
      console.log(data);
      props.createAlert(data, navigate);
    }
  };
  const onChangeField = (e) => {
    console.log(e.target.value);
    setData(e.target.value);
  };
  const onChangeImageInput = (e) => {
    const files = [...e.target.files];
    const uploaded = selectedFiles ? [...selectedFiles] : [];
    files.some((file) => {
      uploaded.push(file);
    });
    setSelectedFiles(uploaded);
    console.log(uploaded);
  };

  return (
    <PageLayout
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
          isAuthenicated={props.user?.authenticated}
    >
      <div className='relative w-full flex flex-col items-center justify-start'>
        <div className='relative my-8 text-center'>
          <span className='text-3xl tracking-widest text-blue-500 uppercase font-semibold'>
            make an alert
          </span>
        </div>
        <form
          onSubmit={handleSubmit(onsubmit)}
          className='relative w-full md:w-1/3 px-8 md:px-0'
        >
          <InputWrapper label='Add Location' name='location' fullWidth>
            <InputAlt
              label='location'
              name='location'
              placeHolder='Location'
              value={data}
              type='text'
              css={css}
              {...register("location")}
              onChange={onChangeField}
            />
          </InputWrapper>
          <InputWrapper label='Alert Headline' name='headline' fullWidth>
            <InputAlt
              label='headline'
              name='headline'
              placeHolder='Alert Headline'
              type='text'
              css={css}
              {...register("headline")}
            />
          </InputWrapper>
          <InputWrapper label='Add Message' name='message' fullWidth>
            <textarea
              label='message'
              name='message'
              placeholder='Add Message'
              type='textarea'
              className={css}
              rows={4}
              cols={22}
              {...register("message")}
            />
          </InputWrapper>
          <InputWrapper
            label='Upload Image'
            name='file'
            labelCls={css + " py-5 cursor-pointer hover:bg-gray-200"}
            fullWidth
          >
            <input
              type='file'
              name='file'
              id='file'
              multiple
              className='input-file'
              accept='image/*'
              {...register("attachments")}
              onChange={onChangeImageInput}
            />
            <div
              className='w-full flex flex-col items-start overflow-y-scroll'
              style={{ maxHeight: 100 }}
            >
              {selectedFiles &&
                selectedFiles.map((file, i) => (
                  <span className='text-xs py-0.5 px-1' key={i}>
                    {i + 1}. {file.name}
                  </span>
                ))}
            </div>
            {/* <InputAlt
              label='file'
              name='file'
              placeHolder='Upload Image'
              type='file'
              accept='image/*'
              multiple
              css={"input-file"}
              {...register("attachments")}
              onChange={(e) => setSelectedFile(e.target.files[0])}
            /> */}
          </InputWrapper>
          <Button
            type='submit'
            cls='bg-blue-500 text-white my-3 w-full'
            value='Send to Nearby Bikers'
            //   onClick={onsubmit}
          />
          <Button
            type='button'
            cls='bg-gray-700 text-white my-3 w-full'
            value='Cancel'
            onClick={() => navigate("/home")}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  createAlert,
};

export default connect(mapStateToProps, mapActionsToProps)(AddAlert);
