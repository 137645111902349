import React from "react";

import { Link } from "react-router-dom";

import { sideBarNavData } from "store";

const FooterNavBar = ({lat, lan}) => {
  return (
    <div
      className='fixed shadow-lg border-t bottom-0 left-0 w-full h-full bg-gray-300 flex md:hidden items-center justify-evenly z-top'
      style={{ maxHeight: 56 }}
    >
      {sideBarNavData.map((item, i) => (
       item. name === "navigation" ? (
        <div className='flex-1 border-r h-full flex flex-col items-center justify-center cursor-pointer'
            onClick={() => showInMapClicked(lat, lan)}
          >
          <span className=''>{item.icon}</span>
          <span
              className='text-center capitalize whitespace-nowrap truncate w-max px-0.5'
              style={{ fontSize: "0.5rem" }}
            >
            {item.name}
            </span>
          </div>
        ) : (
        <Link to={item.path} key={i} className='flex-1 h-full'>
          <div className='flex-1 border-r h-full flex flex-col items-center justify-center cursor-pointer'>
            <span className=''>{item.icon}</span>
            <span
              className='text-center capitalize whitespace-nowrap truncate w-max px-0.5'
              style={{ fontSize: "0.5rem" }}
            >
              {item.name}
            </span>
          </div>
        </Link>
      )))}
    </div>
  );
};

const showInMapClicked = (lat, lan) => {
  window.open(
    `https://maps.google.com?q=${lat},${lan}`,
    "_blank",
    "noreferrer"
  );
};

export default FooterNavBar;
