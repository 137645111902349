import React from "react";

const Modal = ({ children }) => {
  return (
    <div className='fixed inset-0 w-full h-screen bg-gray-500 z-top bg-opacity-60 flex items-center justify-center'>
      <div className='relative w-2/3 md:w-2/5 h-auto p-4 bg-white rounded-md shadow-md flex flex-col items-center justify-start'>
        {children}
      </div>
    </div>
  );
};

export default Modal;
