import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { BsThreeDotsVertical } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineClockCircle} from "react-icons/ai";

import Slider from "react-slick";

import PageLayout from "components/shared/layouts/PageLayout";
import { useLocation } from "react-router-dom";
import { notificationsList } from "store/data";
import { connect } from "react-redux";
import { getAlertData, deleteAlert } from "../../redux/actions/commonActions";
import moment from "moment";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SingleAlert = (props) => {
  const params = useParams();
  const [alertData, setAlertData] = useState(null);
  const [alertHeader, setAlertHeader] = useState(
    () => notificationsList[0] || {}
  );
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "relative",
  };

  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const id = query.split("/").pop();
      props
        .getAlertData(id)
        .then((data) => {
          setAlertData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const toggleDropDownOpen = () => setDropDownOpen(!dropDownOpen);

  const onDelete = (eventId) => {
    props.deleteAlert(eventId, navigate).then(() => {
      console.log("Event  delete successfully");
    });
  };

  const renderLocationDataBlock = (icon, line1) => {
    return (
      // <Link to={`/navigation?dst=${line1}`}>
      <div
        className='relative text-black w-full flex items-center py-4 md:py-8 map_hover'
        onClick={() => showInMapClicked(line1)}
      >
        <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
        <span className='text-base font-semibold'>{line1}</span>
      </div>
      // </Link>
    );
  };

  const renderDataBlock = (icon, line1, line2) => {
    return (
      <div className='relative text-black w-full flex items-center py-4 md:py-8'>
        <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
        <div className='relative flex flex-col items-start justify-center'>
          <span className='text-base font-semibold'>{line1}</span>
          <span className='text-gray-600 text-xs md:text-sm'>{line2}</span>
        </div>
      </div>
    );
  };

  const showInMapClicked = (des) => {
    window.open(
      `https://www.google.lk/maps/dir/${props.user.credentials.latitude},${props.user.credentials.longitude}/${des}`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <PageLayout 
      lat={props.user?.credentials?.latitude}
      lan={props.user?.credentials?.longitude}
      isAuthenicated={props.user?.authenticated}
    >
      <div
        className='relative w-full h-60 md:h-96 border-b-2 border-blue-600'
        // style={{ height: 400 }}
      >
        <Slider {...sliderSettings} className='relative w-full h-60 md:h-72'>
        {alertData?.imageUrls.map((url) => {
          return  (<img
           src={url}
           alt='header'
           className='relative object-bottom object-contain w-full h-full z-0 pb-16'
         />)
        })}       
        </Slider>

        {/* <img
          src={alertHeader?.thumbnail}
          alt='header'
          className='absolute inset-0 object-bottom object-cover w-full h-full opacity-50'
          style={{ zIndex: -1 }}
        /> */}
        {/* <img
          src={alertHeader?.thumbnail}
          alt='header'
          className='absolute object-bottom object-cover w-full h-full z-0'
        /> */}
        <div className='absolute bottom-0 w-full h-16 px-4 md:px-72 bg-black bg-opacity-60 flex items-center justify-between'>
          <div className='relative'>
            <span className='relative text-white text-base md:text-2xl font-bold uppercase'>
              {alertData?.headline}
            </span>
          </div>
          {props.user?.credentials?.handle == alertData?.userHandle ? (
            <div className='relative text-black w-3 md:w-4 bg-white flex items-center justify-center p-1 rounded-full'>
              <span
                className='relative text-xs md:text-2xl'
                onClick={toggleDropDownOpen}
              >
                <BsThreeDotsVertical />
              </span>
              {dropDownOpen && (
                <div className='absolute top-full z-50 shadow-md bg-white p-2 flex flex-col items-center justify-center'>
                  <span className='relative capitalize text-sm text-black'>
                    edit
                  </span>
                  <span
                    className='relative capitalize text-sm text-black'
                    onClick={() => onDelete(alertData?.alertId)}
                  >
                    delete
                  </span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className='relative py-4 px-4 lg:px-48 w-full flex items-center justify-between border-b border-blue-500 bg-gray-100'>
        <div className='relative'>
          <span className='relative text-gray-700 text-sm md:text-lg capitalize'>
            from:
            <span className='text-gray-800 font-semibold'>
              {alertData?.fullName}
            </span>
          </span>
        </div>
        {alertData?.recepients && alertData?.recepients.length > 0 ? (
          <div className='relative flex flex-row items-center justify-center space-x-4'>
            <span className='text-gray-800 font-semibold text-sm md:text-lg'>
              To {alertData?.recepients[0].fullName} and{" "}
              {alertData?.recepients.length} others
            </span>
          </div>
        ) : null}
      </div>
      <div className='relative bg-white md:left-1/2 transform md:-translate-x-1/2 w-full md:w-3/4 flex flex-col items-start justify-center px-4 border-r border-l border-blue-500'>
        <div className='flex flex-col items-center justify-center w-full md:p-8 p-4'>
          <div className='relative flex w-full py-2 items-start justify-between border-b border-gray-300'>
            <div
              className='relative flex flex-col items-start justify-start'
              style={{ width: 400 }}
            >
              <div className='relative text-lg md:text-3xl pt-4 md:pt-8 uppercase md:tracking-widest'>
                message
              </div>
            </div>
          </div>
          <div className='relative w-full flex flex-col items-start justify-start'>
            <div className='relative mt-4 w-2/3 whitespace-wrap'>
              <span className='text-base'>{alertData?.message}</span>
            </div>
          </div>
          <div className='relative w-full mt-8 flex flex-row items-center justify-start'>
          {renderDataBlock(
                <AiOutlineClockCircle />,
                alertData?.createdAt
                  ? moment
                      .utc(alertData?.createdAt)
                      .format("MMMM Do YYYY, h:mm:ss a")
                  : "",
                moment
                  .utc(alertData?.createdAt)
                  .local()
                  .startOf("seconds")
                  .fromNow()
              )}
            {renderLocationDataBlock(
              <IoLocationOutline />,
              alertData?.location
            )}
          </div>
          <div className='relative w-full'>
            <div className='relative'>
              <span className='relative capitalize text-sm text-gray-400'>
                images
              </span>
            </div>
            <div className='relative w-full flex flex-row items-center justify-center space-x-1'>
              {/* {alertData?.images?.map((img, i) => ( */}
              <div className='relative w-1/2 lg:w-3/12 h-52 rounded-sm overflow-hidden'>
                <img
                  src={alertData?.uploadImageUrl}
                  alt='img'
                  className='absolute left-0 top-0 w-full h-full object-cover'
                />
              </div>
              {/* ))} */}
              {/* // <div className="relative flex-1 px-8">
                //   <span className="relative capitalize text-gray-300 text-sm">
                //     view more +
                //   </span>
                // </div> */}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getAlertData,
  deleteAlert,
};

export default connect(mapStateToProps, mapActionsToProps)(SingleAlert);
