import { CTA } from 'components/shared/Button';
import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="max-w-7xl mx-auto mt-8 md:mt-16">
      <div className="flex flex-col md:flex-row items-center justify-center w-full rounded-3xl overflow-hidden container mx-auto">
        <div className="flex flex-col items-center justify-center gap-4 flex-1 p-10">
          <span className="text-sm border-b mb-4">#MYROVMOMENT</span>
          <p className="text-lg font-semibold max-w-lg text-center mb-8">
            Riders On Video is the ultimate digital hub for motorcycle
            enthusiasts worldwide. Whether you're into track racing, street
            cruising, touring, or part of a motorcycle club, ROV empowers you to
            plan adventures, connect with fellow riders, and share your stories
            with the world. Join us at the intersection of every road, ride, and
            remarkable journey. ROV is the crossroads for every kind of axle,
            asphalt, and adventure.
          </p>
          <Link to="/about-us">
            <CTA className="bg-blue-500 text-white">About ROV</CTA>
          </Link>
        </div>
        {/* <div className="flex-1 relative overflow-hidden aspect-square rounded-3xl">
          <img
            src="/images/landing_page/5.png"
            alt="About img"
            className="object-center"
          />
        </div> */}
      </div>
    </div>
  );
};

export default About;
