import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import {
  AiOutlineCheckSquare,
  AiOutlineQuestion,
  AiOutlineClose,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import moment from "moment";

import Slider from "react-slick";

import PageLayout from "components/shared/layouts/PageLayout";
import useBounds from "hooks/useBounds";
import { eventsList } from "store/data";

import { connect } from "react-redux";
import {
  getEventData,
  updateEventAction,
  deleteEvent,
} from "../../redux/actions/eventActions";
import useWindowDimensions from "../../hooks/useMobile";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SingleEvent = (props) => {
  const params = useParams();
  const { isMobile } = useWindowDimensions();
  const [eventData, setEventData] = useState(() => eventsList[0] || {});
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [event, setEvent] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "relative",
  };

  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const id = query.split("/").pop();
      props
        .getEventData(id)
        .then((data) => {
          setEvent(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  console.log(event);

  const toggleDropDownOpen = () => setDropDownOpen(!dropDownOpen);

  const renderIconButton = (icon, value, action) => {
    return (
      <div
        className='relative flex flex-col items-center justify-center'
        onClick={() => action()}
      >
        <span className='relative text-blue-600 font-bold text-xs md:text-base'>
          {icon}
        </span>
        <span className='relative text-xs md:text-base capitalize'>
          {value}
        </span>
      </div>
    );
  };

  const onDelete = (eventId) => {
    props.deleteEvent(eventId, navigate).then(() => {
      console.log("Event  delete successfully");
    });
  };

  const onAttending = (eventId) => {
    console.log("onAttending : " + eventId);
    props.updateEventAction(eventId, "attending").then(() => {
      console.log("Updated successfully");
    });
  };

  const onMaybeAttending = (eventId) => {
    console.log("onMaybeAttending : " + eventId);
    props.updateEventAction(eventId, "maybeAttending").then(() => {
      console.log("Updated successfully");
    });
  };

  const onNotAttending = (eventId) => {
    console.log("onNotAttending : " + eventId);
    props.updateEventAction(eventId, "notAttending").then(() => {
      console.log("Updated successfully");
    });
  };
  const renderDataBlock = (icon, line1, line2) => {
    return (
      <div className='relative text-black w-full flex items-center py-4 md:py-8'>
        <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
        <div className='relative flex flex-col items-start justify-center'>
          <span className='text-base font-semibold'>{line1}</span>
          <span className='text-gray-600 text-xs md:text-sm'>{line2}</span>
        </div>
      </div>
    );
  };

  const renderLocationDataBlock = (icon, line1) => {
    return (
      // <Link to={`/navigation?dst=${line1}`}>
      <div
        className='relative text-black w-full flex items-center py-4 md:py-8 map_hover'
        onClick={() => showInMapClicked(line1)}
      >
        <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
        <span className='text-base font-semibold'>{line1}</span>
      </div>
      // </Link>
    );
  };

  const showInMapClicked = (des) => {
    window.open(
      `https://www.google.lk/maps/dir/${props.user.credentials.latitude},${props.user.credentials.longitude}/${des}`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full h-60 md:h-72 border-b-2 border-blue-600'>
        <Slider {...sliderSettings} className='relative w-full h-60 md:h-72'>
        {event?.imageUrls.map((url) => {
          return  (<img
           src={url}
           alt='header'
           className='relative object-bottom object-contain w-full h-full z-0 pb-16'
         />)
        })}       
          
        </Slider>
        {/* <img
          src={event?.uploadImageUrl}
          alt='header'
          className='absolute inset-0 object-bottom object-cover w-full h-full bg-black opacity-50 z-0 pb-16'
          style={{ zIndex: -1 }}
        /> */}
        <div className='absolute bottom-0 w-full h-16 px-4 md:px-72 bg-black bg-opacity-60 flex items-center justify-between'>
          <div className='relative flex items-center'>
            <div className='relative mr-2 md:mr-4 md:mb-4 w-11 md:w-20 h-11 md:h-20 rounded-md bg-white text-blue-600 text-center flex flex-col items-center justify-center'>
              <span className='relative text-xl md:text-3xl leading-5 '>
                {event?.startTime ? new Date(event?.startTime).getDate() : ""}
              </span>
              <span className='relative text-sm md:text-xl'>
                {event?.startTime
                  ? months[new Date(event?.startTime).getMonth()]
                  : ""}
              </span>
            </div>
            <div className='relative '>
              <span className='relative text-white text-base md:text-2xl font-bold uppercase'>
                {event?.title}
              </span>
            </div>
          </div>
          {props.user?.credentials?.handle == event?.userHandle ? (
            <div className='relative text-black w-3 md:w-4 bg-white flex items-center justify-center p-1 rounded-full'>
              <span
                className='relative text-xs md:text-2xl'
                onClick={toggleDropDownOpen}
              >
                <BsThreeDotsVertical />
              </span>
              {dropDownOpen && (
                <div className='absolute top-full z-50 shadow-md bg-white p-2 flex flex-col items-center justify-center'>
                  <span className='relative capitalize text-sm text-black'>
                    edit
                  </span>
                  <span
                    className='relative capitalize text-sm text-black'
                    onClick={() => onDelete(event?.eventId)}
                  >
                    delete
                  </span>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <div className='relative py-4 px-4 md:px-72 w-full flex items-center justify-between border-b border-blue-500 bg-gray-100'>
        <div className='relative'>
          <span className='relative text-gray-700 text-sm md:text-lg capitalize'>
            hosted by: <br />
            <span className='text-gray-800 font-semibold'>
              {event?.fullName}
            </span>
          </span>
        </div>
        {props.user?.credentials?.handle != event?.userHandle ? (
          <div className='relative flex flex-row items-center justify-center space-x-2 md:space-x-4'>
            <div
              className='relative flex flex-col items-center justify-center'
              onClick={() => onAttending(event?.eventId)}
            >
              <span className='relative text-blue-600 font-bold text-xs md:text-base'>
                <AiOutlineCheckSquare />
              </span>
              <span className='relative text-xs md:text-base capitalize'>
                attending
              </span>
            </div>
            <div
              className='relative flex flex-col items-center justify-center'
              onClick={() => onMaybeAttending(event?.eventId)}
            >
              <span className='relative text-blue-600 font-bold text-xs md:text-base'>
                <AiOutlineQuestion />
              </span>
              <span className='relative text-xs md:text-base capitalize'>
                may be
              </span>
            </div>
            <div
              className='relative flex flex-col items-center justify-center'
              onClick={() => onNotAttending(event?.eventId)}
            >
              <span className='relative text-blue-600 font-bold text-xs md:text-base'>
                <AiOutlineClose />
              </span>
              <span className='relative text-xs md:text-base capitalize'>
                not attending
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <div className='relative md:left-1/2 transform md:-translate-x-1/2 w-full md:w-5/6 flex flex-col items-start justify-center px-4 border-r border-l border-blue-500'>
        <div className='relative flex flex-col items-center justify-center md:w-full md:p-8 p-4'>
          <div className='relative flex flex-col md:flex-row w-full py-2 pr-8 md:pr-0 items-start justify-between'>
            <div className='relative w-full flex flex-col items-start justify-between'>
              {renderDataBlock(
                <AiOutlineClockCircle />,
                event?.createdAt
                  ? moment
                      .utc(event?.createdAt)
                      .format("MMMM Do YYYY, h:mm:ss a")
                  : "",
                moment
                  .utc(event?.createdAt)
                  .local()
                  .startOf("seconds")
                  .fromNow()
              )}
              {renderLocationDataBlock(<IoLocationOutline />, event?.location)}
            </div>
            <div
              className='relative flex flex-col items-start justify-start md:px-8'
              style={{ width: 400 }}
            >
              <div className='relative text-lg md:text-3xl pt-4 md:pt-8 uppercase md:tracking-widest'>
                invited
              </div>
              <div className='relative w-full text-base flex flex-row md:flex-col items-start justify-start uppercase md:tracking-widest mt-2 md:mt-8'>
                <div className='relative'>
                  <span className='capitalize text-sm'>
                    {event?.attending && event?.attending.length
                      ? event?.attending.length
                      : 0}{" "}
                    attending
                  </span>
                </div>
                <div className='relative'>
                  <span className='capitalize text-sm'>
                    {event?.maybeAttending && event?.maybeAttending.length
                      ? event?.maybeAttending.length
                      : 0}{" "}
                    maybe
                  </span>
                </div>
                {event?.sharedCount > 0 ? (
                  <div className='relative'>
                    <span className='capitalize text-sm'>
                      {event?.sharedCount} shared with
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {event?.description !== "" ? (
            <div className='relative w-full flex flex-col items-start justify-start pr-4'>
              <div className='relative mt-4 pb-2 w-full'>
                <span className='relative text-lg pt-2 md:pt-4 uppercase md:tracking-widest text-black font-semibold'>
                  details
                </span>
              </div>
              <div className='relative mt-4 w-full md:w-1/2 whitespace-pre'>
                <span className='text-sm md:text-base'>
                  {event?.description}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getEventData,
  updateEventAction,
  deleteEvent,
};

export default connect(mapStateToProps, mapActionsToProps)(SingleEvent);
