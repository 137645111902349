import React from 'react';
import Section from './components/shared/Section';
import Hero from './Views/Hero';
import Features from './Views/Features';
import About from './Views/About';
import Footer from './Views/Footer';
import Services from './Views/Services';

const LandingPage = () => {
  return (
    <div className="w-full h-full min-h-screen">
      <Section>
        <Hero />
      </Section>
      <Section>
        <About />
      </Section>
      <Section>
        <Features />
      </Section>
      {/* <Section>
        <Services />
      </Section> */}
      <Section>
        <Footer />
      </Section>
    </div>
  );
};

export default LandingPage;
