import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export const convertTitleToSlug = (title) => title.replace(/\W+/g, '-');

export const convertDate = (date) =>
  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

export const getTime = (date) =>
  date.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  });
