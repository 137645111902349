import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";
import { countries } from "store/countries";
import { states } from "store/states";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Popover } from "react-tiny-popover";
import { IconContext } from "react-icons";

// Redux stuff
import { connect } from "react-redux";
import { signUpUser } from "../../redux/actions/userActions";

import "react-datepicker/dist/react-datepicker.css";

const popOverTriggerStyles = {
  position: "absolute",
  height: "100%",
  display: "flex",
  alignItems: "center",
  right: -65,
};

const popOverStyles = {
  padding: "0.5rem",
  borderRadius: "0.5rem",
  fontSize: 12,
};

const popOverTextColor = "rgba(255, 0, 70, 1)";

const SignUp = (props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [countryCode, setCountryCode] = useState("US");
  const [errorList, setErrorList] = useState({});
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    hasUpperCase: popOverTextColor,
    hasLowerCase: popOverTextColor,
    hasNumeric: popOverTextColor,
    hasSpecialCharacter: popOverTextColor,
    hasCorrectLength: popOverTextColor,
    isSuccess: false,
  });
  const navigate = useNavigate();

  const onsubmit = (data) => {
    if (validate(data)) {
      if (data.password.length >= 6) {
        props.signUpUser(data, navigate);
      } else {
        showError("Password should contain atleast 6 characters");
      }
    }
  };

  const validate = (data) => {
    if (!data.riderType || data.riderType == "") {
      showError("Please select rider type");
      return false;
    } else if (!data.fName || data.fName == "") {
      showError("Please enter full name");
      return false;
    } else if (!data.email || data.email == "") {
      showError("Please enter email");
      return false;
    } else if (!data.password || data.password == "") {
      showError("Please enter password");
      return false;
    } else if (!data.country || data.country == "") {
      showError("Please select country");
      return false;
    }

    return true;
  };

  const showError = (error) => {
    toast.error(error, {
      position: "top-right",
      //autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (props.UI.errors) {
      toast.error(props.UI.errors, {
        position: "top-right",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }, [props.UI.errors]);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const _css =
    "relative w-full appearance-none p-1 md:p-4 rounded-md border border-gray-200 text-sm md:text-base";

  const renderPopOver = () => {
    return (
      <div style={popOverStyles} className='bg-white border border-gray-400'>
        <div className='relative flex flex-col items-start justify-center'>
          {/* <span
            style={{
              color: passwordValidation.hasUpperCase,
            }}
          >
            should include at least one upper case character
          </span>
          <span
            style={{
              color: passwordValidation.hasLowerCase,
            }}
          >
            should include at least one lower case character
          </span>
          <span
            style={{
              color: passwordValidation.hasNumeric,
            }}
          >
            should include at least one number
          </span>
          <span
            style={{
              color: passwordValidation.hasSpecialCharacter,
            }}
          >
            should include at least one special character
          </span> */}
          <span
            style={{
              color: passwordValidation.hasCorrectLength,
            }}
          >
            should include {6} characters
          </span>
        </div>
      </div>
    );
  };
  const togglePopOver = () => {
    setIsPopOverOpen(!isPopOverOpen);
  };

  return (
    <PageLayout>
      <div className='relative w-full flex flex-col items-center justify-start'>
        <ToastContainer position='bottom-right' autoClose={5000} />
        <div className='relative my-2 md:my-8 text-center'>
          <span className='text-base md:text-xl tracking-wide text-blue-500 uppercase font-semibold'>
            please complete the registration form
          </span>
        </div>
        {/* {props.UI && props.UI.errors && <span class="alert alert-warning" role="alert">{props.UI.errors}</span>} */}
        <form
          onSubmit={handleSubmit(onsubmit)}
          className='relative w-full md:w-1/3 px-8 md:px-0 flex flex-col items-center justify-start'
          style={{ marginBottom: "100px" }}
        >
          <InputWrapper label='Rider Type' name='riderType' required fullWidth>
            <select
              name='riderType'
              id='riderType'
              className={_css}
              {...register("riderType", { required: true })}
            >
              <option value='adventure'>Adventure</option>
              <option value='track'>Track</option>
              <option value='street'>Street</option>
              <option value='tour'>Tour</option>
              <option value='other'>Other</option>
              <option value='Motorcycle Club'>Motorcycle Club</option>
            </select>
          </InputWrapper>
          <InputWrapper label='Full Name' name='fName' fullWidth required>
            <InputAlt
              label='fName'
              name='fName'
              placeHolder='Full Name'
              type='text'
              css={_css}
              {...register("fName", { required: true })}
            />
          </InputWrapper>
          {/* <InputWrapper label="User Name" name="username" fullWidth required>
            <InputAlt
              label="username"
              name="username"
              placeHolder="User Name"
              value=""
              type="text"
              css={_css}
              {...register("username", { required: true })}
            />
          </InputWrapper> */}
          <InputWrapper label='Email' name='email' fullWidth required>
            <InputAlt
              label='email'
              name='email'
              placeHolder='Email'
              type='email'
              css={_css}
              {...register("email", { required: true })}
            />
          </InputWrapper>
          <InputWrapper label='Password' name='password' fullWidth required>
            <InputAlt
              label='password'
              name='password'
              placeHolder='Password'
              type={passwordShown ? "text" : "password"}
              css={_css}
              {...register("password", { required: true })}
            />
            <span
              className='absolute top-0 right-0 h-full flex items-center mr-2 md:-right-8 text-blue-500'
              onClick={togglePassword}
            >
              {passwordShown ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
            <Popover
              isOpen={isPopOverOpen}
              positions={["top", "bottom", "left", "right"]} // preferred positions by priority
              content={renderPopOver}
            >
              <div style={popOverTriggerStyles} onClick={togglePopOver}>
                <IconContext.Provider
                  value={{ color: "rgba(255, 0, 80, 1)", size: "1.3em" }}
                >
                  <BsFillInfoCircleFill />
                </IconContext.Provider>
              </div>
            </Popover>
          </InputWrapper>
          {/* <InputWrapper label='Birthday' name='birthday' fullWidth required>
            <Controller
              control={control}
              name={"birthday"}
              render={({ field }) => (
                <DatePicker
                  selected={startDate}
                  placeholderText='Select date'
                  onChange={(date) => {
                    field.onChange(date);
                    setStartDate(date);
                  }}
                  className={_css}
                />
              )}
            />
          </InputWrapper> */}
          <InputWrapper label='Country' name='country' required fullWidth>
            <select
              name='country'
              id='country'
              className={_css}
              {...register("country", { required: true })}
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
              value={countryCode}
            >
              {/* <option value="United States of America">
                United States of America
              </option>
              <option value="Canada">Canada</option> */}
              {countries.map((c, i) => (
                <option value={c.code} key={c.name + i}>
                  {c.name}
                </option>
              ))}
            </select>
          </InputWrapper>
           {/*<div className='relative flex items-center justify-evenly space-x-2'>
            <InputWrapper label='City' name='city' wrapperCls={"flex-1"}>
              <InputAlt
                label='city'
                name='city'
                placeHolder='City'
                type='text'
                css={_css}
                {...register("city")}
              />
            </InputWrapper>
            <InputWrapper label='State' name='state' wrapperCls={"flex-1"}>
              <select
                name='state'
                id='state'
                className={_css}
                {...register("state")}
              > */}
                {/* <option value="United States of America">
                United States of America
              </option>
              <option value="Canada">Canada</option> */}
                {/* {states
                  .filter((item) => item.countryCode === countryCode)
                  .map((c, i) => (
                    <option value={c.code} key={c.name + i}>
                      {c.name}
                    </option>
                  ))}
              </select>
            </InputWrapper>
          </div> */}
          <InputWrapper label='Zip (USA only)' name='zip' wrapperCls={"w-full"}>
            <InputAlt
              label='zip'
              name='zip'
              placeHolder='Zip'
              type='text'
              css={_css}
              {...register("zip")}
            />
          </InputWrapper>

          <Button
            type='submit'
            cls='bg-blue-600 text-white my-1 md:my-3 py-1 md:py-2 px-2 w-5/6 md:w-2/3'
            value='Click here to sign up &rarr;'
            //   onClick={onsubmit}
          />
          <span className='relative text-xs text-gray-700'>
            By signing up, you agree to the{" "}
            <Link to='/terms'>
              <span className='underline'>Terms of Service</span>
            </Link>{" "}
            and
            <Link to='/privacy'>
              <span className='underline'> Privacy Policy.</span>
            </Link>{" "}
          </span>
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  signUpUser,
};

export default connect(mapStateToProps, mapActionsToProps)(SignUp);
