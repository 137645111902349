import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
  getRedirectResult
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc
} from "firebase/firestore";
import { uuid } from 'uuidv4';
import { getStorage } from "firebase/storage";
/// DEV--------------------------
const firebaseConfig = {
    apiKey: "AIzaSyB24DmTvyv63shET_KYBb1VJEQVSUVhJo4",
    authDomain: "riders-on-video-7d3c8.firebaseapp.com",
    projectId: "riders-on-video-7d3c8",
    storageBucket: "riders-on-video-7d3c8.appspot.com",
    messagingSenderId: "102091959594",
    appId: "1:102091959594:web:5a566c5842ba9b67e10253",
    measurementId: "G-9PJ31194D7"
};
////// ---------------------------

/// QA----------------------------

// const firebaseConfig = {
//   apiKey: "AIzaSyA_dRl3TAXLAfiQHvBfClQ38K0MXnf7gIQ",
//   authDomain: "rov-qa-8eb7e.firebaseapp.com",
//   projectId: "rov-qa-8eb7e",
//   storageBucket: "rov-qa-8eb7e.appspot.com",
//   messagingSenderId: "651043234276",
//   appId: "1:651043234276:web:5781214cd49b7a7cda67f1"
// };

////// ---------------------------
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
//   try {
//     getRedirectResult().then(function(result) {
//         if (result.credential) {
//           // This gives you a Google Access Token.
//           var token = result.credential.accessToken;
//         }
//         var user = result.user;
//       });
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("userId", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         userId: user.uid,
//         fullName: user.displayName,
//         authProvider: "google",
//         email: user.email,
//         handle: uuid(),
//         createdAt: new Date().toISOString(),
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     await signInWithEmailAndPassword(auth, email, password);
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  storage,
 // signInWithGoogle,
  sendPasswordReset,
  logout,
};