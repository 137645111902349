import React from "react";
import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";
import ParagraphWrapper from "components/shared/ParagraphWrapper";

const Privacy = (props) => {
  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude} isAuthenicated={props.user?.authenticated}>
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-48 md:pb-32 space-y-2'>
        <Title text='privacy policy' size='lg' wrapperCls='my-8' />
        <ParagraphWrapper innerCls='text-start'>
          Privacy Policy Last updated: (add date) My Company (change this)
          ("us", "we", or "our") operates http://www.mysite.com (change this)
          (the "Site"). This page informs you of our policies regarding the
          collection, use and disclosure of Personal Information we receive from
          users of the Site. We use your Personal Information only for providing
          and improving the Site.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          By using the site, you agree to the collection and use of information
          in accordance with this policy. Information Collection And Use While
          using our site, we may ask you to provide us with certain personally
          identifiable information that can be used to contact or identify you.
          Personally identifiable information may include, but is not limited to
          your name ("Personal Information').
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          Log Data Like many site operators, we collect information that your
          browser sends whenever you visit our Site ("Log Data"). This Log Data
          may include information such as your computer's Internet Protocol (IP)
          address, browser type, browser version, the pages of our Site that you
          visit the time and date of your visit, the time spent on those pages
          and other statistics. In addition, we may use third party services
          such as Google Analytics that collect, monitor and analyze this ...
          The Log Data section is for businesses that use analytics or tracking
          services in websites or apps, like Google Analytics.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          For the full disclosure section, create your own Privacy Policy.
          Communications We may use your Personal Information to contact you
          with newsletters, marketing or promotional materials and other
          information that ... The Communications section is for businesses that
          may contact users via email (email newsletters) or other methods. For
          the full disclosure section, create your own Privacy Policy. Cookies
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          Cookies are sent to your browser from a web site and stored on your
          computer's hard drive. Like many sites, we use 'cookies' to collect
          information. You can instruct your browser to refuse all
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          cookies or to indicate when a cookie is being sent. However, if you do
          not accept cookies, you may not be able to use some portions of our
          Site. Security The security of your Personal Information is important
          to us, but remember that no method of transmission over the Internet,
          or method of electronic storage, is 100% secure.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          While we strive to use commercially acceptable means to protect your
          Personal Information, we cannot guarantee its absolute security.
          Changes To This Privacy Policy This Privacy Policy is effective as of
          (add date) and will remain in effect except with respect to any
          changes in its provisions in the future, which will be in effect
          immediately after being posted on this page.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          We reserve the right to update or change our Privacy Policy at any
          time and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy. If we make any material changes to this Privacy
          Policy, we will notify you either through the email address you have
          provided us, or by placing a prominent notice on our website.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start'>
          Contact Us If you have any questions about this Privacy Policy, please
          contact us.
        </ParagraphWrapper>
      </div>
    </PageLayout>
  );
};

export default Privacy;
