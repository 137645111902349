import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { GiFullMotorcycleHelmet } from 'react-icons/gi';
import { RiMotorbikeFill } from 'react-icons/ri';
import { MdSupportAgent } from 'react-icons/md';
import { FaMapMarked } from 'react-icons/fa';

export const navData = [
  {
    name: 'home',
    path: '/',
  },
  {
    name: 'events',
    path: '/events',
  },
  {
    name: 'alerts',
    path: '/alerts',
  },
  {
    name: 'inbox',
    path: '/inbox',
  },
  {
    name: 'profile',
    submenu: [
      {
        name: 'my profile',
        path: '/my-profile',
      },
      {
        name: 'my uploads',
        path: '/my-uploads',
      },
      {
        name: 'my events',
        path: '/events/my-events',
      },
      {
        name: 'my alerts',
        path: '/alerts/my-alerts',
      },
    ],
  },
];

export const sideBarNavData = [
  {
    name: 'add an event',
    path: '/events/add',
    icon: <BiEdit />,
  },
  {
    name: 'riders nearby',
    path: '/riders-nearby',
    icon: <GiFullMotorcycleHelmet />,
  },
  {
    name: 'stolen bikes',
    path: '/stolen-bikes/add',
    icon: <RiMotorbikeFill />,
  },
  {
    name: 'need help',
    path: '/help',
    icon: <MdSupportAgent />,
  },
  {
    name: 'navigation',
    path: '/navigation',
    icon: <FaMapMarked />,
  },
];

export const CURRENT_USER = {
  name: 'walt',
  avatar: '/images/profile.png',
  coverImg: '/images/signup.jpg',
  location: 'Orlando, FL, USA',
  birthday: 'August 13, 1992',
};
export const OTHER_USER = {
  avatar: '/images/profile.png',
  coverImg: '/images/signup.jpg',
  location: 'Orlando, FL, USA',
  birthday: 'August 13, 1992',
};

export const CONTACT_DATA = {
  tele: [
    {
      name: 'tow',
      number: 218777,
    },
    {
      name: 'insurance',
      number: 218777,
    },
    {
      name: 'legal',
      number: 218777,
    },
  ],
};

export const UPLOAD_DATA = [
  {
    name: 'upload video',
    path: '/upload-video',
  },
  {
    name: 'upload image',
    path: '/upload-image',
  },
];

export const NOTIFICATION_DATA = [
  {
    name: 'events',
    count: 2,
    path: '/events',
  },
  {
    name: 'alerts',
    count: 3,
    path: '/alerts',
  },
  {
    name: 'inbox',
    count: 5,
    path: '/inbox',
  },
];

export const PROFILE_HEADER_BUTTONS = [
  'edit profile',
  'account settings',
  'friends',
];
export const OTHER_PROFILE_HEADER_BUTTONS = ['message', 'location', 'unfriend'];

export const AUTH_ROUTES = [
  'login',
  'signup',
  'change-password',
  'forgot-password',
  'confirm-email',
];

export const UPLOAD_ROUTES = ['/upload-video', '/upload-image'];

export const SIDEBAR_ROUTES = [
  '/alerts',
  '/my-alerts',
  '/events',
  '/my-events',
  '/',
  '/my-uploads',
  '/riders-nearby',
  '/friend/',
  '/friends',
  '/my-location',
  '/my-profile',
  '/landing',
];
