import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import PageLayout from "components/shared/layouts/PageLayout";
import SideBar from "components/SideBar";
import VideoList from "components/VideoList";
import ProfileHeader from "components/ProfileHeader";

import { OTHER_USER } from "store";

import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import { getUploadsByUserHandleData } from "../../redux/actions/uploadActions";
import {
  getUserByHandleData,
  addFriend,
  unfriend,
  updateFriendRequest,
} from "../../redux/actions/userActions";

const FriendProfile = (props) => {
  const params = useParams();
  const [currentFriend, setCurrentFriend] = useState(OTHER_USER);
  const [videoList, setVideoList] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [user, setUser] = useState(undefined);
  const [userId, setUserId] = useState(undefined);
  const location = useLocation();
  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const id = query.split("/").pop();
      setUserId(id);
      props
        .getUploadsByUserHandleData(id)
        .then((data) => {
          setVideoList(data);
        })
        .catch((err) => {
          console.log(err);
        });
        
      
    }    
    // let friend = friendsList.find(
    //   (f) => f.nickname.toLowerCase() === params.name.toLowerCase()
    // );
    // setCurrentFriend({ ...friend, coverImg: friend.avatar });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() =>{
    getUserByHandleData();
  }, [userId])

  useEffect(() =>{
    getUserByHandleData();
  }, [props.user])

  const getUserByHandleData = () => {
    props.getUserByHandleData(userId).then((data) => {
      setUser(data);
      const selectedFriends = data.friends?.filter(
        (user) => user.id === props.user.credentials?.id
      );
      const selectedFriendRequests = data?.friendRequests?.filter(
        (user) =>
          user.receiverUserId === data.credentials?.id &&
          user.senderUserId === props.user.credentials?.id
      );
      if(data?.credentials?.privacy == 'private'){
        if (selectedFriends && selectedFriends.length > 0) {
          setButtonList(["message",  "unfriend"]);
        } else if (
          selectedFriendRequests &&
          selectedFriendRequests.length > 0
        ) {
          setButtonList(["message", "cancelrequest"]);
        } else {
          setButtonList(["message", "addfriend"]);
        }
      } else {
        if (selectedFriends && selectedFriends.length > 0) {
          setButtonList(["message", "location", "unfriend"]);
        } else if (
          selectedFriendRequests &&
          selectedFriendRequests.length > 0
        ) {
          setButtonList(["message", "location", "cancelrequest"]);
        } else {
          setButtonList(["message", "location", "addfriend"]);
        }

      }

      
    });
  }

  useEffect(() => {
    if (user) {
      const selectedFriends = user.friends?.filter(
        (user) => user.id === props.user.credentials?.id
      );
      const selectedFriendRequests = user.friendRequests?.filter(
        (friend) =>
          friend.receiverUserId === user.credentials?.id &&
          friend.senderUserId === props.user?.credentials?.id
      );
      // const selectedFriendRequestsPending = props.user.friendRequests?.filter(user => user.receiverUserId === props.user.credentials.id && user.senderUserId === userId);
      
      if(user.credentials?.privacy == 'private'){
        if (selectedFriends && selectedFriends.length > 0) {
          setButtonList(["message", "unfriend"]);
        } else if (selectedFriendRequests && selectedFriendRequests.length > 0) {
          setButtonList(["message", "cancelrequest"]);
          // }else if (selectedFriendRequestsPending && selectedFriendRequestsPending.length > 0){
          //   setButtonList(["message", "location", "confirmrequest"])
        } else {
          setButtonList(["message", "addfriend"]);
        }
      } else {
        if (selectedFriends && selectedFriends.length > 0) {
          setButtonList(["message", "location", "unfriend"]);
        } else if (selectedFriendRequests && selectedFriendRequests.length > 0) {
          setButtonList(["message", "location", "cancelrequest"]);
          // }else if (selectedFriendRequestsPending && selectedFriendRequestsPending.length > 0){
          //   setButtonList(["message", "location", "confirmrequest"])
        } else {
          setButtonList(["message", "location", "addfriend"]);
        }

      }
        
    }
  }, [props.user, user]);

  const addFriendClick = () => {
    console.log("Add Friend");
    const friendData = {
      receiverUserId: user.credentials.id,
      receiverUserHandle: user.credentials.handle,
      senderImageUrl: props.user.credentials.imageUrl,
      senderName: props.user.credentials.fullName,
      senderCity: props.user.credentials.city,
      senderCountry: props.user.credentials.country,
      senderState: props.user.credentials.state,
      senderUserId: props.user.credentials.id,
      senderUserHandle: props.user.credentials.handle,
    };
    props.addFriend(friendData).then(() => {
      getUserByHandleData();
    })
  };

  const unfriendClick = () => {
    console.log("Un Friend");
    const friendData = {
      friendHandle: userId,
      userId: props.user.credentials.id,
    };
    props.unfriend(friendData);
  };

  const addCancelRequestClick = () => {
    console.log("Cancel friend request");
    const selectedFriendRequests = user.friendRequests?.filter(
      (friend) =>
        friend.receiverUserId === user.credentials.id &&
        friend.senderUserId === props.user.credentials.id
    );
    if (selectedFriendRequests && selectedFriendRequests.length > 0) {
      const friendRequestData = {
        frId: selectedFriendRequests[0].frId,
        status: "canceled",
      };
      props.updateFriendRequest(friendRequestData).then(() => {
        getUserByHandleData();
      })
    }
  };

  const loadFriendLocationClick = () =>{
    window.open(`https://www.google.lk/maps/dir/${props.user?.credentials?.latitude},${props.user?.credentials?.longitude}/${user?.credentials?.latitude},${user?.credentials?.longitude}`, '_blank', 'noreferrer' );
  };

  const confirmRequestClick = () => {
    console.log("Confirm friend request");
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={buttonList}
        currentUser={user?.credentials}
        isAuthenicated={props.user.authenticated}
        addFriendClick={addFriendClick}
        unfriendClick={unfriendClick}
        addCancelRequestClick={addCancelRequestClick}
        confirmRequestClick={confirmRequestClick}
        loadFriendLocationClick={loadFriendLocationClick}
      />
      <div
        className='relative w-full flex items-start justify-start'
        style={{ height: 800 }}
      >
               <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <VideoList data={videoList} currentUser={props.user.credentials} />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getUploadsByUserHandleData,
  getUserByHandleData,
  addFriend,
  unfriend,
  updateFriendRequest,
};

export default connect(mapStateToProps, mapActionsToProps)(FriendProfile);
