import React from "react";
import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";
import ParagraphWrapper from "components/shared/ParagraphWrapper";

const Terms = (props) => {
  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-48 md:pb-32 space-y-2'>
        <Title text='terms & conditions' size='lg' wrapperCls='my-8' />
        <ParagraphWrapper innerCls='text-start' header='1. introduction'>
          These Website Standard Terms And Conditions (these "Terms" or these
          "Website Standard Terms And Conditions") contained herein on this
          webpage, shall govern your use of this website, including all pages
          within this website (collectively referred to herein below as this
          "Website"). These Terms apply in full force and effect to your use of
          this Website and by using this website, you expressly accept all terms
          and conditions contained herein in full. You must not use this
          website, if you have any objection to any of these Website Standard
          Terms And Conditions. This Website is not for use by any minors
          (defined as those who are not at least 18 years of age), and you must
          not use this website if you a minor.
        </ParagraphWrapper>
        <ParagraphWrapper
          innerCls='text-start'
          header='2. Intellectual Property Rights.'
        >
          Other than content you own, which you may have opted to include on
          this website, under these Terms, (COMPANY.Companyl and/or its
          licensors own all rights to the intellectual property and material
          contained in this website, and all such rights are reserved. You are
          granted a limited license only, subject to the restrictions provided
          in these Terms, for purposes of viewing the material contained on this
          website,
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='3. Restrictions.'>
          You are expressly and emphatically restricted from all of the
          following: 1. publishing any Website material in any media: 2.
          selling, sublicensing and/or otherwise commercializing any Website
          material: 3. publicly performing and/or showing any Website material;
          4. using this Website in any way that is, or may be, damaging to this
          website; 5. using this Website in any way that impacts user access to
          this Website: 6. using this website contrary to applicable laws and
          regulations, or in a way that causes, or may cause, harm to the
          Website, or to any person or business entity: 7. engaging in any data
          mining, data harvesting, data extracting or any other similar activity
          in relation to this Website, or while using this Website: 8. using
          this Website to engage in any advertising or marketing: Certain areas
          of this Website are restricted from access by you and (COMPANY.
          Company) may further restrict access by you to any areas of this
          Website, at any time, in its sole and absolute discretion. Any user ID
          and password you may have for this website are confidential and you
          must maintain confidentiality of such information.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='4. Your Content.'>
          In these Website Standard Terms And Conditions, "Your Content" shall
          mean any audio, video, text, images or other material you choose to
          display on this website. With respect to Your Content, by displaying
          it, you grant (COMPANY.Companyl a non-exclusive, worldwide,
          irrevocable, royalty-free, sublicensable license to use, reproduce,
          adapt, publish, translate and distribute it in any and all media. Your
          Content must be your own and must not be infringing on any third
          party's rights.(COMPANY.Company reserves the right to remove any of
          Your Content from this website at any time, and for any reason,
          without notice.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='5. No warranties.'>
          This Website is provided "as is," with all faults,
          and[COMPANY.Companyl makes no express or implied representations or
          warranties, of any kind related to this Website or the materials
          contained on this website. Additionally, nothing contained on this
          website shall be construed as providing consult or advice to you.
        </ParagraphWrapper>
        <ParagraphWrapper
          innerCls='text-start'
          header='6. Limitation of liability.'
        >
          In no event shall (COMPANY.Companyl, nor any of its officers,
          directors and employees, be liable to you for anything arising out of
          or in any way connected with your use of this website, whether such
          liability is under contract, tort or otherwise, and [COMPANY.Companyl,
          including its officers, directors and employees shall not be liable
          for any indirect, consequential or special liability arising out of or
          in any way related to your use of this website.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='7. Indemnification.'>
          You hereby indemnify to the fullest extent[COMPANY.Company) from and
          against any and all liabilities, costs, demands, causes of action,
          damages and expenses (including reasonable attorney's fees) arising
          out of or in any way related to your breach of any of the provisions
          of these Terms.
        </ParagraphWrapper>

        <ParagraphWrapper innerCls='text-start' header='8. Severability.'>
          If any provision of these Terms is found to be unenforceable or
          invalid under any applicable law, such unenforceability or invalidity
          shall not render these Terms unenforceable or invalid as a whole, and
          such provisions shall be deleted without affecting the remaining
          provisions herein.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='9. Variation of Terms.'>
          (COMPANY.Company) is permitted to revise these Terms at any time as it
          sees fit, and by using this Website you are expected to review such
          Terms on a regular basis to ensure you understand all terms and
          conditions governing use of this website.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='10. Assignment.'>
          (COMPANY.Companyl shall be permitted to assign, transfer, and
          subcontract its rights and/or obligations under these Terms without
          any notification or consent required. However, you shall not be
          permitted to assign, transfer, or subcontract any of your rights
          and/or obligations under these Terms.
        </ParagraphWrapper>
        <ParagraphWrapper innerCls='text-start' header='11. Entire Agreement.'>
          These Terms, including any legal notices and disclaimers contained on
          this website, constitute the entire agreement between
          [COMPANY.Companyl and you in relation to your use of this website, and
          supersede all prior agreements and understandings with respect to the
          same.
        </ParagraphWrapper>
        <ParagraphWrapper
          innerCls='text-start'
          header='12. Governing Law & Jurisdiction.'
        >
          These Terms will be governed by and construed in accordance with the
          laws of the State of (STATE), and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in (STATE) for
          the resolution of any disputes.
        </ParagraphWrapper>
      </div>
    </PageLayout>
  );
};

export default Terms;
