import React, { useState, useEffect } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useLocation } from "react-router-dom";

import Footer from "components/Footer";
import FooterNavBar from "components/SideBar/FooterNavBar";
import { SIDEBAR_ROUTES, UPLOAD_ROUTES } from "store";
import useWindowDimensions from "hooks/useMobile";

const PageLayout = ({ wrapperCls, children, bg, lat, lan, isAuthenicated }) => {
  const { pathname } = useLocation();
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const isMobile = useWindowDimensions();

  useEffect(() => {
    const sidebar = document.getElementById("sideBarContainer");
    if (!sidebar) return;
    sideNavOpen
      ? sidebar.classList.add("active")
      : sidebar.classList.remove("active");
  }, [sideNavOpen]);

  useEffect(() => {
    console.log(isMobile);
  }, [isMobile]);

  const toggleSideBar = () => setSideNavOpen(!sideNavOpen);

  const hasSidebar = SIDEBAR_ROUTES.find((el) => pathname === el);
  const isUploadPage = UPLOAD_ROUTES.includes(pathname);

  return (
    <>
      <div
        className={[
          "relative w-full max-w-7xl mx-auto h-full",
          wrapperCls,
        ].join(" ")}
      >
        {/* {hasSidebar && (
          <div
            className='fixed bottom-0 left-0 block md:hidden p-4 m-8 w-max text-gray-200 bg-blue-600 shadow-3xl text-2xl rounded-full z-top'
            onClick={toggleSideBar}
          >
            {sideNavOpen ? (
              <AiOutlineCloseCircle size={20} />
            ) : (
              <BiMenuAltLeft size={20} />
            )}
          </div>
        )} */}
        {isAuthenicated ? <FooterNavBar lat={lat} lan={lan}/> : null}
        
        {children}
      </div>
      {isUploadPage ? (
        <div
          className='fixed inset-0 h-screen w-full bg-cover bg-right'
          style={{ backgroundImage: `url('/images/signup.jpg')`, zIndex: -1 }}
        >
          <div className='absolute inset-0 bg-black bg-opacity-30' />
        </div>
      ) : null}
      {/* <Footer /> */}
    </>
  );
};

export default PageLayout;
