import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";

import useComponentVisible from "hooks/useComponentVisible";
import NavItemWrap from "./NavItemWrap";

const NavItemAlt = ({
  name,
  path,
  subtitle,
  hasSubMenu,
  hasCount = false,
  subMenu,
  ...navItemProps
}) => {
  // const navigate = useNavigate();
  const { ref, isComponentVisible } = useComponentVisible(false);

  const sum = subMenu
    .map((item) => item.count)
    .reduce((prev, curr) => prev + curr, 0);

  // const logOut = () => {
  //   navigate("/login");
  // };
  return (
    <div
      className='relative flex flex-row items-center justify-center p-1 cursor-pointer'
      {...navItemProps}
    >
      {hasSubMenu ? (
        // <div className='relative flex items-center justify-center' ref={ref}>
        //   <div className='relative flex items-center justify-center text-2xl text-white p-1 rounded-full'>
        //     {name}
        //     {hasCount ? (
        //       <span className='absolute top-1 right-1 p-2 w-2 h-2 flex items-center justify-center bg-red-500 rounded-full text-xs'>
        //         {sum || 0}
        //       </span>
        //     ) : null}
        //     {/*red dot in notification icon*/}
        //   </div>
        //   {isComponentVisible && (
        //     <div
        //       className='absolute flex flex-col bg-white shadow-md whitespace-nowrap rounded-sm'
        //       style={{ top: "120%", zIndex: 999 }}
        //     >
        //       {subMenu.map((item, i) => {
        //         return (
        //           <NavItemWrap key={i.toString()}>
        //             <Link to={item.path}>
        //               <span className='relative w-full capitalize text-sm'>
        //                 {`${item.name}${
        //                   hasCount ? "(" + item.count + ")" : ""
        //                 }`}
        //               </span>
        //             </Link>
        //           </NavItemWrap>
        //         );
        //       })}
        //     </div>
        //   )}
        // </div>
        <Menu>
          <Menu.Button className='flex items-center justify-start'>
            <span className='relative capitalize text-2xl mr-2 text-white'>
              {name}
              {hasCount ? (
              <span className='absolute top-1 right-1 p-2 w-2 h-2 flex items-center justify-center bg-red-500 rounded-full text-xs'>
                {sum || 0}
              </span>
            ) : null}
            </span>
          </Menu.Button>
          <Menu.Items
            as='div'
            className='absolute flex flex-col items-start justify-center bg-white rounded-sm overflow-hiddenm'
            style={{ top: "120%", zIndex: 999 }}
          >
            {subMenu.map((item, i) => {
              return (
                <Menu.Item key={i.toString()}>
                  {/* <NavItemWrap>
                  <Link to={item.path}>
                    <span className='relative w-full capitalize text-sm'>
                      {item.name}
                    </span>
                  </Link>
                </NavItemWrap> */}
                  {({ active, close }) => (
                    <span
                      className={`${
                        active ? "bg-blue-500" : "text-gray-900"
                      } relative w-full flex items-center justify-start px-6 py-2 text-black capitalize text-sm whitespace-nowrap`}
                    >
                      <Link to={item.path} onClick={close}>
                        {item.name}
                      </Link>
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Menu>
      ) : (
        <Link to={path}>
          <span className='relative uppercase text-lg'>{name}</span>
        </Link>
      )}
    </div>
  );
};

export default NavItemAlt;
