import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCheck, AiFillDelete } from "react-icons/ai";

import PageLayout from "components/shared/layouts/PageLayout";
import Sidebar from "components/SideBar";
import Button from "components/shared/Button";
import useBounds from "hooks/useBounds";
import ProfileHeader from "components/ProfileHeader";
import Title from "components/shared/Title";

import { PROFILE_HEADER_BUTTONS } from "store";
import { eventsList } from "store/data";
import { CURRENT_USER } from "store";

import { connect } from "react-redux";
import { getMyEventsData, deleteEvent } from "../../redux/actions/eventActions";

const MyEvents = (props) => {
  const { height } = useBounds(560);
  const navigate = useNavigate();

  const [eventsList, setEventsList] = useState([]);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = () => {
    props
      .getMyEventsData()
      .then((data) => {
        setEventsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDelete = (eventId) => {
    props.deleteEvent(eventId, navigate).then(() => {
      getEvents();
      console.log("Event  delete successfully");
    });
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        currentUser={props.user.credentials}
        buttonNames={PROFILE_HEADER_BUTTONS}
        isAuthenicated={props.user.authenticated}
      />
      <div className='relative w-full flex items-start justify-start md:pr-8'>
        <Sidebar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative w-full md:w-3/4 mt-8 md:mr-4'>
          <div className='relative py-4 px-8 md:px-0 flex w-full md:w-3/4 items-center justify-between border-b'>
            <Title text='my events' size='lg' />
            <Button
              type='button'
              cls='bg-blue-600 text-white w-96'
              inputCls='capitalize text-xs'
              value='add an event'
              onClick={() => navigate("/events/add")}
            />
          </div>
          <div className='relative w-full md:w-3/4 flex flex-col items-start pb-8 px-4 md:px-0'>
            {eventsList.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <div className='relative py-8 flex flex-row items-center justify-start border-b w-full'>
                  <div className='relative'>
                    <img
                      src={item.uploadImageUrl}
                      alt=''
                      className='relative object-contain object-fit w-36 md:max-w-48 max-h-48 overflow-hidden'
                    />
                  </div>
                  <div className='relative flex-1 px-2 md:px-4 flex flex-col items-start justify-start'>
                    <div className='relative w-full flex flex-row flex-nowrap items-center justify-between'>
                      <Link to={`/event/${item.eventId}`}>
                        <span className='relative text-lg md:text-2xl uppercase'>
                          {item.title}
                        </span>
                      </Link>
                      <div className='relative flex-1 flex items-start justify-end'>
                        <button
                          type='button'
                          tabIndex={0}
                          className='text-xl text-blue-600 p-2 rounded-full hover:bg-blue-500 hover:text-white cursor-pointer'
                          onClick={() => onDelete(item?.eventId)}

                          // onClick={() => typeof onDelete === "function" && onDelete()}
                        >
                          <AiFillDelete />
                        </button>
                      </div>
                    </div>
                    <div className='relative'>
                      <span className='relative text-gray-600 text-sm md:text-base'>
                        {item.description}
                      </span>
                    </div>
                    <div className='relative flex flex-col  md:flex-row items-start md:items-center justify-between w-full mt-4'>
                      <div className='relative flex items-center justify-start flex-row w-2/3 space-x-3'>
                        <span className='relative text-black md:mr-2 font-normal text-xs md:text-sm whitespace-pre'>
                          Posted by :
                        </span>
                        <div className='relative w-6 md:w-10 h-6 md:h-10 rounded-full overflow-hidden'>
                          <img
                            src={item.userImage}
                            alt='header'
                            className='absolute object-center object-contain w-full h-full z-0'
                          />
                        </div>
                        <div className='relative flex flex-col items-start justify-center ml-4'>
                          <span className='relative text-black font-semibold text-xs md:text-sm uppercase whitespace-nowrap md:whitespace-pre-wrap'>
                            {item.fullName}
                          </span>
                        </div>
                      </div>
                      <div className='relative flex flex-col items-start justify-center ml-0 md:ml-4'>
                        <span className='relative text-black font-normal text-xs md:text-sm'>
                          {item.location}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getMyEventsData,
  deleteEvent,
};

export default connect(mapStateToProps, mapActionsToProps)(MyEvents);
