import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'utils';

const leftMenu = ['©2022', 'riders on video', 'All Rights Reserved'];
const rightMenu = [
  { name: 'about us', path: '/about-us' },
  { name: 'terms and conditions', path: '/terms' },
  { name: 'privacy', path: '/privacy' },
  { name: 'disclaimer', path: '/disclaimer' },
  { name: 'contact us', path: '/contact' },
  { name: 'suggestions', path: '/suggestions' },
];

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="p-6 container mx-auto flex flex-col items-start justify-start">
        <div className="flex w-full items-center justify-between">
          <div className="aspect-square">
            <img className="h-48 w-48" src="/images/logo.png" alt="logo" />
          </div>
          <div className="flex flex-col items-start justify-start">
            {rightMenu.map((menuItem, i) => {
              return (
                <React.Fragment key={i.toString()}>
                  <Link to={menuItem.path}>
                    <span
                      className={cn(
                        'text-white flex-1 hover:underline w-full hover:text-gray-200 text-xs capitalize',
                        i % 2 ? '' : 'text-end'
                      )}
                      tabIndex={-1}
                    >
                      {menuItem.name}
                    </span>
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="px-6 py-3 border-t-purple-50 flex items-center justify-between container mx-auto">
        <span className="">
          Copyright © {new Date().getFullYear()}. ROV—Riders On Video.
        </span>
        <span className="">All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
