import React from "react";
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { Menu } from "@headlessui/react";

import NavItemWrap from "./NavItemWrap";

import useComponentVisible from "hooks/useComponentVisible";

const NavItem = ({ name, path, hasSubMenu, subMenu, ...navItemProps }) => {
  // const navigate = useNavigate();
  const { ref, isComponentVisible } = useComponentVisible(false);

  // const logOut = () => {
  //   navigate("/login");
  // };

  return (
    <div
      className='nav-item relative flex w-full md:w-auto flex-row items-center justify-start px-2 py-2 cursor-pointer'
      {...navItemProps}
    >
      {hasSubMenu ? (
        // <div className='relative flex items-center justify-center' ref={ref}>
        //   <span className='relative capitalize text-base mr-2 text-white'>
        //     {name}
        //   </span>
        //   <BiChevronDown className='text-white' />
        //   {isComponentVisible && (
        //     <div
        //       className='absolute flex flex-col bg-white shadow-md whitespace-nowrap rounded-sm'
        //       style={{ top: "120%", zIndex: 999 }}
        //     >
        //       {subMenu.map((item, i) => {
        //         return (
        //           <NavItemWrap key={i.toString()}>
        //             <Link to={item.path}>
        //               <span className='relative w-full capitalize text-sm'>
        //                 {item.name}
        //               </span>
        //             </Link>
        //           </NavItemWrap>
        //         );
        //       })}
        //     </div>
        //   )}
        // </div>
        <Menu>
          <Menu.Button className='flex items-center justify-start'>
            <span className='relative capitalize text-base mr-2 text-white'>
              {name}
            </span>
            <BiChevronDown className='text-white' />
          </Menu.Button>
          <Menu.Items
            as='div'
            className='absolute flex flex-col items-start justify-center bg-white rounded-sm overflow-hiddenm'
            style={{ top: "120%", zIndex: 999 }}
          >
            {subMenu.map((item, i) => {
              return (
                <Menu.Item key={i.toString()}>
                  {/* <NavItemWrap>
                    <Link to={item.path}>
                      <span className='relative w-full capitalize text-sm'>
                        {item.name}
                      </span>
                    </Link>
                  </NavItemWrap> */}
                  {({ active, close }) => (
                    <span
                      className={`${
                        active ? "bg-blue-500" : "text-gray-900"
                      } relative w-full flex items-center justify-start px-6 py-2 text-black capitalize text-sm whitespace-nowrap`}
                    >
                      <Link to={item.path} onClick={close}>
                        {item.name}
                      </Link>
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Menu>
      ) : (
        <Link to={path}>
          <span className='relative capitalize text-base text-white hover:text-gray-300'>
            {name}
          </span>
        </Link>
      )}
    </div>
  );
};

export default NavItem;
