import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ,
  STOP_LOADING_UI,
  SET_NOTIFICATIONS,
  SET_FRIEND_REQUESTS,
  SET_USERS,
} from "../types";
import axios from "axios";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  sendPasswordResetEmail,
  signOut,
  getRedirectResult,
  FacebookAuthProvider,
  signInWithPopup,
  TwitterAuthProvider
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import { uuid } from "uuidv4";

export const loginUser = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/signin", userData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      navigate("/home");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.general,
      });
    });
};

export const changePassword = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/change-password", userData)
    .then((res) => {
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      navigate("/home");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resetPassword = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/reset-password", userData)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
      navigate("/login");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const signUpUser = (userData, navigate) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  dispatch({ type: LOADING_UI });
  const newUser = {
    email: userData.email,
    confirmPassword: userData.password,
    password: userData.password,
    handle: uuid(),
    fullName: userData.fName,
    group: userData.riderType,
    // birthDate: userData.birthday,
    country: userData.country,
    // city: userData.city,
    // state: userData.state,
    zip: userData.zip,
  };

  axios
    .post("/signup", newUser)
    .then((res) => {
      dispatch({ type: CLEAR_ERRORS });
      navigate("/login");
    })
    .catch((err) => {
      const errorObj = err.response.data;
      const vals = Object.keys(errorObj).map((key) => errorObj[key]);
      dispatch({
        type: SET_ERRORS,
        payload: vals[0],
      });
    });
};

// export const signInWithGoogle = (navigate) => (dispatch) => {
//   try {
//     const provider = new GoogleAuthProvider();
//     provider.addScope("profile");
//     provider.addScope("email");
//     signInWithRedirect(auth, provider).then(() => {
//       getRedirectResult(auth).then((result) => {
//         if (result && result.credential) {
//           console.log("pass");
//           // This gives you a Google Access Token.
//           setAuthorizationHeader(result.credential.accessToken);
//         }
//         var user = result.user;
//         const q = query(collection(db, "users"), where("userId", "==", user.uid));
//         getDocs(q).then((docs) => {
//           if (docs.docs.length === 0) {
//             addDoc(collection(db, "users"), {
//               userId: user.uid,
//               fullName: user.displayName,
//               authProvider: "google",
//               email: user.email,
//               handle: uuid(),
//               createdAt: new Date().toISOString(),
//             });
//           } else {
//             dispatch(getUserData());
//             dispatch({ type: CLEAR_ERRORS });
//           }
//           navigate("/");
//         });
//       }); 
//     })
       
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle =  (navigate) =>  (dispatch) => {
  try {
    signInWithPopup(auth, googleProvider).then ((res) => {
      if (res && res.user) {
        setAuthorizationHeader(res.user.accessToken);
        const user = res.user;
          const q = query(collection(db, "users"), where("userId", "==", user.uid));
          getDocs(q).then((docs) => {
            if (docs.docs.length === 0) {
              addDoc(collection(db, "users"), {
                userId: user.uid,
                fullName: user.displayName,
                authProvider: "google",
                email: user.email,
                handle: uuid(),
                createdAt: new Date().toISOString(),
              });
            } else {
              dispatch(getUserData());
              dispatch({ type: CLEAR_ERRORS });
            }
            navigate("/home");
          });
      }
    }).catch(error => {
      console.error(error);
    })
    
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const signInWithTwitter = (navigate) => (dispatch) => {
  try {
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider).then ((res) => {
      if (res && res.user) {
        setAuthorizationHeader(res.user.accessToken);
        const user = res.user;
          const q = query(collection(db, "users"), where("userId", "==", user.uid));
          getDocs(q).then((docs) => {
            if (docs.docs.length === 0) {
              addDoc(collection(db, "users"), {
                userId: user.uid,
                fullName: user.displayName,
                authProvider: "twitter",
                email: user.email,
                handle: uuid(),
                createdAt: new Date().toISOString(),
              });
            } else {
              dispatch(getUserData());
              dispatch({ type: CLEAR_ERRORS });
            }
            navigate("/home");
          });
      }
    }).catch(error => {
      console.error(error);
    })
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const signInWithFacebook = (navigate) => (dispatch) => {
  try {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider).then ((res) => {
      if (res && res.user) {
        setAuthorizationHeader(res.user.accessToken);
        const user = res.user;
          const q = query(collection(db, "users"), where("userId", "==", user.uid));
          getDocs(q).then((docs) => {
            if (docs.docs.length === 0) {
              addDoc(collection(db, "users"), {
                userId: user.uid,
                fullName: user.displayName,
                authProvider: "facebook",
                email: user.email,
                handle: uuid(),
                createdAt: new Date().toISOString(),
              });
            } else {
              dispatch(getUserData());
              dispatch({ type: CLEAR_ERRORS });
            }
            navigate("/home");
          });
      }
    }).catch(error => {
      console.error(error);
    })
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const signupUser = (newUserData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/signup", newUserData)
    .then((res) => {
      setAuthorizationHeader(res.data.token);
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      navigate("/home");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/user")
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  return new Promise((resolve, reject) => {
    axios
      .get("/users")
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUserNotificationData = () => (dispatch) => {
  dispatch({ type: LOADING_USER });
  return new Promise((resolve, reject) => {
    axios
      .get("/user/notifications")
      .then((res) => {
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: res.data,
        });
      })
      .catch((err) => reject(err));
  });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const addFriend = (data) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/${data.receiverUserId}/friend`, data)
      .then(() => {
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: STOP_LOADING_UI });
        dispatch(getUserData());
        resolve();
      })
      .catch((err) => {
        dispatch({ type: STOP_LOADING_UI });
        console.log(err);
        reject(err);
      });
  });
};

export const unfriend = (data) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/user/${data.userId}/friend/${data.friendId}`, data)
    .then(() => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: STOP_LOADING_UI });
      dispatch(getUserData());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      console.log(err);
    });
};

export const updateFriendRequest = (data) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  return new Promise((resolve, reject) => {
    axios
      .patch(`/user/friend-request/${data.frId}`, data)
      .then(() => {
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: STOP_LOADING_UI });
        dispatch(getMyFriendRequestsData());
        resolve();
      })
      .catch((err) => {
        dispatch({ type: STOP_LOADING_UI });
        console.log(err);
        reject(err);
      });
  });
};

export const confirmFriendRequest = (id) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post(`/user/confirm-friend-request/${id}`, { frId: id })
    .then(() => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: STOP_LOADING_UI });
      dispatch(getMyFriendRequestsData());
      dispatch(getUserData());
      dispatch(getUserNotificationData());
    })
    .catch((err) => {
      dispatch({ type: STOP_LOADING_UI });
      console.log(err);
    });
};

export const editUserDetails = (userDetails) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user", userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const updateUser = (userData, navigate) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .patch(`/user/${userData.id}`, userData)
    .then((res) => {
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
      navigate("/my-profile");
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateProfileImage = (imageData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .patch(`/user/image/${imageData.id}`, imageData)
    .then((res) => {
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const markNotificationsRead = (notificationIds) => (dispatch) => {
  axios
    .post("/notifications", notificationIds)
    .then((res) => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ,
      });
    })
    .catch((err) => console.log(err));
};

export const getUserByHandleData = (handle) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/friend/user/${handle}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};

export const getMyFriendRequestsData = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/my-friend-requests")
      .then((res) => {
        dispatch({
          type: SET_FRIEND_REQUESTS,
          payload: res.data,
        });
      })
      .catch((err) => reject(err));
  });
};
