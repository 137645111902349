import React from 'react';

import { featuresData } from './features-data';
import EmblaCarousel from 'components/shared/Slider';

const OPTIONS = { axis: 'x' };
const SLIDES = Array.from(featuresData);

const Features = () => {
  return (
    <div className="max-w-7xl mx-auto h-full mt-8 md:mt-16 p-4 md:p-0">
      <EmblaCarousel slides={SLIDES} options={OPTIONS} />
    </div>
  );
};

export default Features;
