import React, { useEffect, useState } from "react";
import PageLayout from "components/shared/layouts/PageLayout";
import { useLocation } from "react-router-dom";
import SideBar from "components/SideBar";
import VideoList from "components/VideoList";

// Redux stuff
import { connect } from "react-redux";
import {
  getAllUploadData,
  getSearchData,
} from "../redux/actions/uploadActions";

const Home = (props) => {
  const [videoList, setVideoList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const search = location.search;
    const searchText = new URLSearchParams(search).get("searchText");
    const type = new URLSearchParams(search).get("type");
    if (searchText) {
      let queryParam = "";
      switch (type) {
        case "title":
          queryParam = `?searchText=${searchText}&isTitle=true`;
          break;
        case "location":
          queryParam = `?searchText=${searchText}&isLocation=true`;
          break;
        case "session":
          queryParam = `?searchText=${searchText}&isSession=true`;
          break;
        case "date":
          queryParam = `?searchText=${searchText}&isDate=true`;
          break;
        case "group":
          queryParam = `?searchText=${searchText}&isGroup=true`;
          break;
        case "track":
          queryParam = `?searchText=${searchText}&isTrack=true`;
          break;
        default:
          queryParam = `?searchText=${searchText.toUpperCase()}&isUser=true`;
          break;
      }
      loadSearch(queryParam);
    } else {
      loadUploads();
    }
  }, [location.search]);

  const loadSearch = (queryParam) => {
    props
      .getSearchData(queryParam)
      .then((data) => {
        setVideoList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadUploads = () => {
    props
      .getAllUploadData()
      .then((data) => {
        setVideoList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />

        <VideoList data={videoList} currentUser={props.user.credentials} />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getAllUploadData,
  getSearchData,
};

export default connect(mapStateToProps, mapActionsToProps)(Home);
