import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import PageLayout from "components/shared/layouts/PageLayout";

import SideBar from "components/SideBar";
import Title from "components/shared/Title";

let center = { lat: 48.8584, lng: 2.2945 };

const Navigation = (props) => {
  const [dest, setDest] = useState("");
  const [origin, setOrigin] = useState(null);

  useEffect(() => {
    let search = window.location.search;
    const dst = new URLSearchParams(search).get("dst");
    console.log(dst);
    if (dst) {
      setDest(dst);
    }
  }, []);

  useEffect(() => {
    center = {
      lat: props.user?.credentials?.latitude,
      lng: props.user?.credentials?.longitude,
    };
    if(!props.user?.credentials?.latitude || !props.user?.credentials?.longitude) {
      setOrigin(props.user?.credentials?.city)
    } else {
    setOrigin({
      lat: props.user?.credentials?.latitude,
      lng: props.user?.credentials?.longitude,
    });
  }
  }, [props.user]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded) calculateRoute();
  }, [dest, origin, isLoaded]);
    
    
      const [map, setMap] = useState(/** @type google.maps.Map */ (null))
      const [directionsResponse, setDirectionsResponse] = useState(null)
      const [distance, setDistance] = useState('')
      const [duration, setDuration] = useState('')
    
   
    
      if (!isLoaded) {
        return <span>Loading ...</span>
      }

  async function calculateRoute() {
    if (dest === "" || !origin) {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    directionsService
      .route({
        origin: origin,
        destination: dest,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((results) => {
        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      });
  }

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div
        className='relative w-full flex items-start justify-start'
        style={{ height: 900 }}
      >
               <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <div className='relative w-full h-3/4 m-8 border rounded-sm'>
          <div className='relative py-4 px-8 md:px-0 flex w-full items-center justify-center border-b '>
            <Title text='map' size='lg' />
          </div>
          <GoogleMap
            center={center}
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
            onLoad={(map) => setMap(map)}
          >
            <Marker position={center} />
            {directionsResponse && (
              <DirectionsRenderer directions={directionsResponse} />
            )}
          </GoogleMap>
          {dest ? (
            <div className='relative flex flex-col items-center justify-center w-full py-4'>
              <span>Distance: {distance} </span>
              <span>Duration: {duration} </span>
            </div>
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Navigation);
