import React, { useRef, useEffect } from "react";
import Moment from "react-moment";
import { AiOutlineDelete } from "react-icons/ai";

const Message = ({ msg, user1, onDeleteMsg }) => {
  const scrollRef = useRef();

  // useEffect(() => {
  //   scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [msg]);

  // const onDeleteMsg = () => {
  //   console.log(user1, msg);
  // };

  return (
    <>
      <div
        className={[
          "relative group w-full flex flex-row items-center px-2 py-1",
          msg?.from === user1 ? "justify-end" : "justify-start",
        ].join(" ")}
        tabIndex={0}
      >
        {user1 === msg?.from ? (
          <span
            className='opacity-0 group-hover:opacity-100 mr-4 pb-4 text-2xl text-red-300 cursor-pointer'
            aria-hidden
            onClick={() => onDeleteMsg(user1, msg)}
          >
            <AiOutlineDelete />
          </span>
        ) : null}
        <div className='relative flex flex-col items-end justify-end'>
          <div
            className={[
              "relative flex flex-col p-4 max-w-sm rounded-md",
              msg?.from === user1 ? "own bg-blue-300" : "bg-green-300",
            ].join(" ")}
            ref={scrollRef}
          >
            <div className='relative flex items-center justify-start'>
              {msg?.media ? <img src={msg?.media} alt={msg?.msg} /> : null}
              <p
                className={[
                  "relative w-64 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm xl:text-base",
                  msg?.from === user1 ? "me" : "friend",
                ].join(" ")}
              >
                {msg?.msg || ""}
              </p>
            </div>
          </div>
          <span
            className='capitalize flex items-center justify-end'
            style={{ fontSize: 11 }}
          >
            <Moment fromNow>{msg?.createdAt.toDate()}</Moment>
          </span>
        </div>
      </div>
    </>
  );
};

export default Message;
