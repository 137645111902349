import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from './firebase';
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

import RoutesTree from 'components/RoutesTree';
import NavbarAlt from 'components/NavBar/NavbarAlt';
import LoadingScreen from 'components/LoadingScreen';
import Footer from 'components/Footer';

// Redux
import store from './redux/store';
import { SET_AUTHENTICATED } from './redux/types';
import {
  logoutUser,
  getUserData,
  getUserNotificationData,
} from './redux/actions/userActions';

import { AUTH_ROUTES } from 'store';

import './index.css';

const geofire = require('geofire-common');

axios.defaults.baseURL =
  //"https://us-central1-rov-qa-8eb7e.cloudfunctions.net/api";  // QA
  'https://us-central1-riders-on-video-7d3c8.cloudfunctions.net/api'; //DEV
// 'http://localhost:5000/riders-on-video-7d3c8/us-central1/api';

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

function App(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [user, setUser] = useState(null);
  const [isDisplayLocationError, setIsDisplayLocationError] = useState(false);

  useEffect(() => {
    // console.log("loaded");
    listenToUserNotifications();
  }, [props.user.credentials]);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(position) {
    // console.log("Latitude is :", position.coords.latitude);
    // console.log("Longitude is :", position.coords.longitude);
    if (
      lat !== position.coords.latitude ||
      long !== position.coords.longitude
    ) {
      if (user?.authenticated && user?.credentials) {
        const hash = geofire.geohashForLocation([
          position.coords.latitude,
          position.coords.longitude,
        ]);
        const data = {
          geohash: hash,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setDoc(doc(db, 'users', user?.credentials?.id), data, {
          merge: true,
        })
          .then(() => {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            // console.log("Document successfully updated!");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted') {
            // console.log(result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.watchPosition(success);
          } else if (result.state === 'prompt') {
            navigator.geolocation.watchPosition(success, errors, options);
          } else if (result.state === 'denied') {
            if (!isDisplayLocationError) {
              alert(
                'Please enable location tracking on your browser for better user experience.'
              );
              setIsDisplayLocationError(true);
            }
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
      // console.log("supported");
      // navigator.geolocation.watchPosition(function (position) {
      //   console.log("Latitude is :", position.coords.latitude);
      //   console.log("Longitude is :", position.coords.longitude);
      //   if (
      //     lat !== position.coords.latitude ||
      //     long !== position.coords.longitude
      //   ) {
      //     if (user?.authenticated && user?.credentials) {
      //       const hash = geofire.geohashForLocation([
      //         position.coords.latitude,
      //         position.coords.longitude,
      //       ]);
      //       const data = {
      //         geohash: hash,
      //         latitude: position.coords.latitude,
      //         longitude: position.coords.longitude,
      //       };
      //       setDoc(doc(db, "users", user?.credentials?.id), data, {
      //         merge: true,
      //       })
      //         .then(() => {
      //           setLat(position.coords.latitude);
      //           setLong(position.coords.longitude);
      //           console.log("Document successfully updated!");
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //   }
      // });
    } else if (navigator.geolocation) {
      navigator.geolocation.watchPosition(success);
    }
  });

  useEffect(() => {
    setUser(props.user);
    // console.log(props.user);
  }, [props.user]);

  const listenToUserNotifications = () => {
    if (props.user.credentials) {
      const q = query(
        collection(db, 'notifications'),
        where('recipient', '==', props.user.credentials.handle)
      );
      onSnapshot(q, (querySnapshot) => {
        props.getUserNotificationData();
      });
    }
  };

  const logout = () => {
    props.logoutUser();
    navigate('/');
  };

  const search = (searchtext, type) => {
    window.location.href = `/search/?searchText=${searchtext}&type=${type}`;
  };

  const isAuthRoute =
    (AUTH_ROUTES.includes(pathname.replace('/', '')) &&
      pathname.replace('/', '') !== 'signup') ||
    pathname.includes('inbox');

  const isInbox = pathname.includes('inbox');
  const isLanding = pathname.includes('landing');

  return (
    <>
      <NavbarAlt
        isAuthenicated={props.user.authenticated}
        logout={logout}
        search={search}
        imageUrl={
          props.user.credentials?.imageUrl
            ? props.user.credentials?.imageUrl
            : '/images/no-img.jpg'
        }
        notifiicationData={props.notifications}
      />

      {/* <Provider store={store}> */}
      <main
        className={[
          'relative w-full mx-auto h-auto overflow-x-hidden',
          isAuthRoute ? 'h-screen' : 'min-h-screen',
          isInbox ? 'overflow-hidden' : '',
        ].join(' ')}
        style={{
          height: isInbox ? 'calc(100vh - 65px)' : '',
        }}
      >
        <RoutesTree />
      </main>
      {/* {props.UI.loading && <LoadingScreen />} */}
      {/* <LoadingScreen /> */}
      {/* </Provider> */}
      {!isAuthRoute && !isLanding ? <Footer /> : null}
      <Toaster
        toastOptions={{
          className: 'toaster font-normal text-xl',
          style: {
            borderRadius: 4,
            // color: "rgba(59, 130, 246 ,0)",
          },
          position: 'bottom-left', // bottom-center, top-center, etc.
          duration: 3000,
        }}
      />
    </>
  );
}

const mapActionsToProps = {
  logoutUser,
  getUserNotificationData,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  notifications: state.common.notifications,
});

export default connect(mapStateToProps, mapActionsToProps)(App);
