import React, { useCallback } from 'react';
import { DotButton, useDotButton } from './DotButtons';
import useEmblaCarousel from 'embla-carousel-react';

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  return (
    <section className="slider">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="slider__container">
          {slides.map((slide, i) => (
            <div
              key={i}
              className="relative h-full overflow-hidden flex items-center justify-center slider__slide"
            >
              <div className="relative h-full w-full aspect-square rounded-3xl overflow-hidden">
                <img
                  src={slide.img}
                  alt={slide.title}
                  className="absolute inset-0 w-full h-full object-cover object-center"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-end gap-2 mt-4">
        <div className="flex flex-nowrap justify-end items-center gap-4">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'w-5 h-5 rounded-full'.concat(
                index === selectedIndex ? ' bg-gray-400' : ' bg-gray-200'
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
