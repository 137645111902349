import React, { useState, useEffect } from "react";
import { ImSearch } from "react-icons/im";

import NavItemWrap from "./NavItemWrap";
import useComponentVisible from "hooks/useComponentVisible";

const _checkBoxes = [
  {
    value: "title",
    checked: false,
  },
  {
    value: "location",
    checked: false,
  },
  {
    value: "session",
    checked: false,
  },
  {
    value: "date",
    checked: false,
  },
  {
    value: "group",
    checked: false,
  },
  {
    value: "track",
    checked: false,
  },
];

const SearchFilter = ({ search }) => {
  const [checkBoxes, setCheckBoxes] = useState(_checkBoxes);
  const [checked, setChecked] = useState(
    new Array(_checkBoxes.length).fill(false)
  );
  const [activeCheckBoxIndex, setActiveCheckBoxIndex] = useState(0);
  const [activeCheckBox, setActiveCheckBox] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isDateSelected, setIsDateSelected] = useState(false);


  const { ref, isComponentVisible } = useComponentVisible(true);

  useEffect(() => {
    const updatedCheckBoxes = [...checkBoxes].map((box, i) =>
      box.value === activeCheckBox.value
        ? { ...box, checked: checked[activeCheckBoxIndex] }
        : { ...box, checked: false }
    );
    const selected = updatedCheckBoxes.filter(item => item.checked == true );
    if(selected && selected.length > 0){
      if(selected[0].value == 'date'){
        setIsDateSelected(true);
      } else {
        setIsDateSelected(false);
      }
    }
    setCheckBoxes(updatedCheckBoxes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, activeCheckBoxIndex, activeCheckBox]);

  // useEffect(() => {
  //   console.log(checkBoxes);
  // }, [checkBoxes]);

  const updateCheckBoxes = (index, val) => {
    const updatedCheckedState = checked.map((item, i) =>
      i === index ? !item : false
    );

    setChecked(updatedCheckedState);
    setActiveCheckBoxIndex(index);
    setActiveCheckBox(val);
  };

  const updateTextInput = (e) => {
    setSearchInput(e.target.value);
  };

  const onSearchClick = () => {
    if (searchInput === "") return;
    const filter = checkBoxes.find((el) => el.checked);
    // if (!filter) {
    //   return;
    // }
    //console.log("searching", filter.value);
    search(searchInput, filter ? filter.value : "");
  };

  return (
    <div className='text-sm' ref={ref}>
      <div className='relative flex items-center justify-between bg-white px-4 py-1 md:py-2 rounded-full'>
        <input
          type='text'
          name='search'
          id='search'
          className='relative appearance-none border-none outline-none bg-transparent w-full font-semibold text-blue-500'
          placeholder={isDateSelected ? 'yyyy-MM-dd':'Search'}
          onChange={updateTextInput}
          autoComplete='off'
        />
        <span
          className='relative text-blue-500 font-bold'
          onClick={onSearchClick}
        >
          <ImSearch />
        </span>
      </div>
      {isComponentVisible && (
        <div
          className='absolute grid grid-cols-2 flex-wrap bg-white shadow-md whitespace-nowrap rounded-sm'
          style={{ top: "120%", zIndex: 9999 }}
        >
          {checkBoxes.map((item, i) => {
            return (
              <NavItemWrap
                key={i.toString()}
                onClick={() => updateCheckBoxes(i, item)}
              >
                <input
                  type='radio'
                  name={item.value}
                  id={item.value}
                  checked={checked[i]}
                  onChange={() => updateCheckBoxes(i, item)}
                  className='relative mr-2'
                />
                <span className='relative w-full capitalize text-sm'>
                  {item.value}
                </span>
              </NavItemWrap>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
