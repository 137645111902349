const Arrow = () => {
  return (
    <svg
      width="21"
      height="9"
      viewBox="0 0 21 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.354 4.854a.5.5 0 0 0 0-.708L17.172.964a.5.5 0 1 0-.707.708L19.293 4.5l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182ZM0 5h20V4H0v1Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default Arrow;
