import React, { useState, useEffect } from 'react';
import { BsCloudUploadFill, BsFillBellFill } from 'react-icons/bs';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import NavItem from './NavItem';
import NavItemAlt from './NavItemAlt';
import SearchFilter from './SearchFilter';
import Button from 'components/shared/Button';

import { navData, UPLOAD_DATA, AUTH_ROUTES } from 'store';

const NavbarAlt = ({
  isAuthenicated,
  logout,
  imageUrl,
  search,
  notifiicationData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const showNav =
    AUTH_ROUTES.includes(location.pathname.replace('/', '')) &&
    location.pathname.replace('/', '') !== 'signup';

  const isLanding = location.pathname.includes('/');

  return (
    <div>
      {!showNav ? (
        <nav
          className={[
            'z-top',
            isLanding ? 'fixed top-0 left-0 bg-white w-full' : 'bg-blue-600',
          ].join(' ')}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b border-blue-500">
            <div className="flex items-center justify-between h-16">
              <div
                className={['flex items-center', isLanding ? '' : ''].join(' ')}
              >
                <div
                  className={[
                    'flex-shrink-0',
                    isLanding ? 'p-4 bg-blue-500' : '',
                  ].join(' ')}
                >
                  <Link to="/home">
                    <img
                      className="h-8 w-8"
                      src="/images/logo-small.png"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {isAuthenicated ? (
                      navData.map((item, i) => {
                        return (
                          <NavItem
                            name={item.name}
                            path={item.path}
                            key={i.toString()}
                            hasSubMenu={item.submenu ? true : false}
                            subMenu={item.submenu}
                          />
                        );
                      })
                    ) : (
                      <NavItem
                        name={'Home'}
                        path={'/home'}
                        hasSubMenu={false}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-1 hidden md:block">
                <div className="relative space-x-2 w-auto flex flex-row items-center justify-end">
                  {isAuthenicated ? (
                    <>
                      <SearchFilter search={search} />
                      <Button
                        type="button"
                        cls="bg-white text-blue-600"
                        inputCls="font-semibold capitalize text-sm"
                        value="Log Out"
                        onClick={logout}
                      />
                      <NavItemAlt
                        name={<BsCloudUploadFill />}
                        path={''}
                        hasSubMenu={true}
                        subMenu={UPLOAD_DATA}
                      />
                      <NavItemAlt
                        name={<BsFillBellFill />}
                        path={''}
                        hasSubMenu={true}
                        hasCount={notifiicationData.length}
                        subMenu={notifiicationData}
                      />
                      <Link to="/my-profile">
                        <div className="relative w-6 h-6 rounded-full overflow-hidden">
                          <img
                            src={imageUrl}
                            alt="header"
                            className="absolute object-center md:object-bottom object-cover w-full h-full z-0"
                          />
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        cls={
                          isLanding
                            ? 'text-white bg-blue-600'
                            : 'bg-white text-blue-600'
                        }
                        inputCls="font-semibold capitalize text-sm"
                        value="Log In"
                        onClick={() => navigate('/login')}
                      />
                      <Button
                        type="button"
                        cls={
                          isLanding
                            ? 'text-white bg-blue-600'
                            : 'bg-white text-blue-600'
                        }
                        inputCls="font-semibold capitalize text-sm"
                        value="Register"
                        onClick={() => navigate('/signup')}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {isAuthenicated && (
                  <>
                    <div className="relative w-6 h-6 rounded-full overflow-hidden m-2">
                      <Link to="/my-profile">
                        <img
                          src={imageUrl}
                          alt="header"
                          className="absolute object-center md:object-bottom object-cover w-full h-full z-0"
                        />
                      </Link>
                    </div>
                    <NavItemAlt
                      name={<BsFillBellFill />}
                      path={''}
                      hasSubMenu={true}
                      hasCount={true}
                      subMenu={notifiicationData}
                    />
                  </>
                )}
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {isOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
                </button>
              </div>
            </div>
          </div>

          <div
            className={['md:hidden', isOpen ? 'opacity-100' : 'opacity-0'].join(
              ' '
            )}
            id="mobile-menu"
          >
            {isOpen ? (
              <div className="relative w-full flex flex-col items-start">
                <div className={['px-2 pt-2 pb-3 space-y-1 sm:px-3'].join(' ')}>
                  {isAuthenicated ? (
                    navData.map((item, i) => {
                      return (
                        <NavItem
                          name={item.name}
                          path={item.path}
                          key={i.toString()}
                          hasSubMenu={item.submenu ? true : false}
                          subMenu={item.submenu}
                        />
                      );
                    })
                  ) : (
                    <NavItem name={'Home'} path={'/home'} hasSubMenu={false} />
                  )}
                </div>
                <div className="relative w-full flex items-center justify-center px-2 space-x-2 border-t border-blue-500">
                  {isAuthenicated ? (
                    <>
                      <SearchFilter search={search} />
                      <Button
                        type="button"
                        cls="bg-white text-blue-600"
                        inputCls="font-semibold capitalize text-sm"
                        value="Log Out"
                        onClick={logout}
                      />
                      <NavItemAlt
                        name={<BsCloudUploadFill />}
                        path={''}
                        hasSubMenu={true}
                        subMenu={UPLOAD_DATA}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        cls="bg-white text-blue-600"
                        inputCls="font-semibold capitalize text-sm"
                        value="Log In"
                        onClick={() => navigate('/login')}
                      />
                      <Button
                        type="button"
                        cls="bg-white text-blue-600"
                        inputCls="font-semibold capitalize text-sm"
                        value="Register"
                        onClick={() => navigate('/signup')}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </nav>
      ) : null}
    </div>
  );
};

export default NavbarAlt;
