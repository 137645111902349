import React from "react";
import PageLayout from "components/shared/layouts/PageLayout";
import Title from "components/shared/Title";
import ParagraphWrapper from "components/shared/ParagraphWrapper";

const About = (props) => {
  return (
    <PageLayout 
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude} isAuthenicated={props.user?.authenticated}>
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-48 md:pb-32 space-y-2'>
        <Title text='about us' size='xl' wrapperCls='my-8' />
        <ParagraphWrapper innerCls='text-start text-normal' wrapperCls='mt-8'>
          Riders On Video is the premiere digital community for motorcycle
          enthusiast everywhere. Though there are many lifestyles, everyone can
          use this powerful tool to make the most of theirs. Track riders,
          Street Riders, Tour riders, and Motorcycle Clubs alike can plan trips
          and events while sharing their journey with the world. ROV is the
          crossroads for every kind of axle, asphalt, and adventure.
        </ParagraphWrapper>

        <div className='relative w-full h-96 border border-blue-500'>
          <img
            src='/images/mountain.jpg'
            alt=''
            className='absolute w-full h-full object-cover'
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default About;
