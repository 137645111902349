import React from "react";

const ParagraphWrapper = ({
  children,
  header,
  wrapperCls,
  headerCls,
  innerCls,
  ...paragraphProps
}) => {
  return (
    <div className={["relative", wrapperCls].join(" ")} {...paragraphProps}>
      {header && (
        <span
          className={["relative font-semibold capitalize", headerCls].join(" ")}
        >
          {header}
        </span>
      )}
      <p className={["relative", innerCls].join(" ")}>{children}</p>
    </div>
  );
};

export default ParagraphWrapper;
