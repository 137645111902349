import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import SideBar from "components/SideBar";
import VideoList from "components/VideoList";
import ProfileHeader from "components/ProfileHeader";
import Title from "components/shared/Title";

import { PROFILE_HEADER_BUTTONS } from "store";

import { connect } from "react-redux";
import { getMyUploadData } from "../redux/actions/uploadActions";

const MyUploads = (props) => {
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    props
      .getMyUploadData()
      .then((data) => {
        setVideoList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const customHeader = () => {
    return (
      <div className='relative py-4 md:pr-8 mb-4 flex w-full items-center justify-between'>
        <Title text='my uploads' size='lg' />
        <div className='relative px-4 flex flex-col md:flex-row  md:space-x-4 items-center justify-end'>
          <Button
            type='button'
            cls='bg-blue-600 text-white w-96 tracking-widest uppercase my-1 md:my-0'
            inputCls='uppercase text-xs'
            value='upload image'
            onClick={() => navigate("/upload-image")}
          />
          <Button
            type='button'
            cls='bg-blue-600 text-white w-96 tracking-widest uppercase my-1 md:my-0'
            inputCls='uppercase text-xs'
            value='upload video'
            onClick={() => navigate("/upload-video")}
          />
        </div>
      </div>
    );
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        currentUser={props.user.credentials}
        buttonNames={PROFILE_HEADER_BUTTONS}
        isAuthenicated={props.user.authenticated}
      />
      <div
        className='relative w-full flex items-start justify-start'
        style={{ height: 800 }}
      >
               <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <VideoList
          data={videoList}
          customHeader={customHeader}
          currentUser={props.user.credentials}
        />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getMyUploadData,
};

export default connect(mapStateToProps, mapActionsToProps)(MyUploads);
