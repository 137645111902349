import React from "react";
import { Link } from "react-router-dom";

const UsersList = ({ users, currentUser }) => {
  console.log(users, currentUser);
  return (
    <div className='relative w-full md:w-3/4 mt-8 pb-16 md:pb-8  px-4 md:px-0 md:mr-4'>
      <div className='flex flex-col items-start pb-32 md:pb-16'>
        {users?.map((user, i) => {
          return (
            <React.Fragment key={i.toString()}>
              <div className='relative my-2 p-4 w-full flex flex-row items-center justify-start border-b '>
                <div className='relative w-6 md:w-12 h-6 md:h-12 rounded-full overflow-hidden'>
                  <img
                    src={user.imageUrl}
                    alt=''
                    className='relative w-full h-full object-center object-cover'
                  />
                </div>
                <div className='relative flex-1 px-2 md:px-4 flex flex-col items-start justify-start'>
                  <Link to={`/friend/${user.handle}`}>
                    <div className='relative'>
                      <span className='relative text-sm md:text-base font-semibold capitalize'>
                        {user.fullName}
                      </span>
                    </div>
                  </Link>

                  <div className='relative flex flex-row items-center justify-between w-full mt-1 md:mt-3'>
                    <span className='relative text-gray-600 mr-2 font-normal text-xs'>
                      {`${user.city},${user.state},${user.country}`}
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default UsersList;
