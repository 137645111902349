import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";

import PageLayout from "components/shared/layouts/PageLayout";
import ProfileHeader from "components/ProfileHeader";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import { IoLocationOutline } from "react-icons/io5";

import { PROFILE_HEADER_BUTTONS } from "store";
import { getStolenBikeData } from "../../redux/actions/commonActions";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StolenBikeSingle = (props) => {
  const [bike, setBike] = useState([]);
  const location = useLocation();
  const isAuthenticated = props.user.authenticated;
  const curUser = props.user.credentials;

  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const id = query.split("/").pop();
      props
        .getStolenBikeData(id)
        .then((data) => {
          setBike(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "relative",
  };

  const renderLocationDataBlock = (icon, line1) => {
    return (
      // <Link to={`/navigation?dst=${line1}`}>
      <div
        className='relative text-black w-full flex items-center py-4 md:py-8 map_hover'
        onClick={() => showInMapClicked(line1)}
      >
        <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
        <span className='text-base font-semibold'>{line1}</span>
      </div>
      // </Link>
    );
  };

  const showInMapClicked = (des) => {
    window.open(
      `https://www.google.lk/maps/dir/${props.user.credentials.latitude},${props.user.credentials.longitude}/${des}`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={curUser}
        isAuthenicated={isAuthenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative flex-1 my-4 p-4 h-auto overflow-hidden'>
          <Title
            text={bike?.manufacturer?.concat(" ", bike?.model, " ", bike?.year)}
            size='xl'
            wrapperCls='pb-4'
          />
          <div className='relative w-full h-auto overflow-y-scroll border-t'>
            <div className='relative w-full h-auto'>
              {bike ? (
                <div className='relative w-full flex flex-col items-start justify-between'>
                  <div className='relative p-2 flex flex-col items-start w-32'>
                    <img
                      src={bike?.userImage}
                      alt='user'
                      className='w-8 lg:w-16 h-8 lg:h-16 object-fill rounded-full'
                    />
                    <span className='text-sm py-2 capitalize text-gray-400 font-semibold'>
                      {bike?.fullName}
                    </span>
                  </div>
                  <div className='relative w-full mt-8 flex flex-row items-center justify-start'>
                    {renderLocationDataBlock(
                      <IoLocationOutline />,
                      bike?.location
                    )}
                  </div>
                  <div className='relative flex-1 py-2'>{bike.date}</div>
                  <div className='relative w-full flex flex-row items-center justify-center space-x-1'>
                    {/* {alertData?.images?.map((img, i) => ( */}
                    <div className='relative w-1/2 lg:w-3/12 h-52 rounded-sm overflow-hidden'>
                    <Slider {...sliderSettings} >
                    {bike?.imageUrls?.map((url) => {
                      return  (<img
                      src={url}
                      alt='header'
                      className='relative object-bottom object-contain w-full h-full z-0 pb-16'
                    />)
                    })}       
                    </Slider>
                    </div>
                  </div>
                </div>
              ) : (
                <span className='text-sm py-2 capitalize text-gray-400 font-semibold'>
                  no details found
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getStolenBikeData,
};

export default connect(mapStateToProps, mapActionsToProps)(StolenBikeSingle);
