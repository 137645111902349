import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FormWrapper from "components/shared/containers/FormWrapper";
import Button from "../Button";

const AuthLayout = ({ children, ...authLayoutProps }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <div
        className='absolute inset-0 flex flex-col items-center justify-start
        '
        style={{ zIndex: 1 }}
        {...authLayoutProps}
      >
        {pathname !== "/login" && (
          <div className='absolute top-8 left-4 flex items-center justify-start'>
            <Button
              type='button'
              cls='bg-transparent text-white shadow-none'
              inputCls='font-normal uppercase text-xs'
              value='&larr; back'
              onClick={() => navigate(-1)}
            />
          </div>
        )}
        <img
          src='/images/logo.png'
          alt='logo'
          className='relative w-32 md:w-auto h-32 md:h-auto'
        />
        <FormWrapper>{children}</FormWrapper>
      </div>
      <div className='fixed inset-0 z-0'>
        <img
          src='/images/bg.jpg'
          alt='bg'
          className='absolute inset-0 w-full h-full object-cover opacity-40 transform scale-150'
        />
      </div>
      <div className='fixed inset-0 z-0 bg-blue-800 bg-opacity-90'></div>
    </>
  );
};

export default AuthLayout;
