import React, { useEffect, useState } from "react";
import {
  onSnapshot,
  doc,
  deleteDoc,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import { AiOutlineDelete } from "react-icons/ai";

import { db } from "../../firebase";

const User = ({ user1, user, selectUser, chat, closeChatList }) => {
  const user2 = user?.handle;
  const [data, setData] = useState("");

  useEffect(() => {
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    let unsub = onSnapshot(doc(db, "lastMsg", id), (doc) => {
      setData(doc.data());
    });

    return () => unsub();
  }, []);

  const onChatItemClick = (user) => {
    selectUser(user);
    closeChatList();
  };

  const onDeleteMsgGroup = () => {
    console.log(user1, user, selectUser, chat, closeChatList);
    const id = user1 > user.id ? `${user1 + user.id}` : `${user.id + user1}`;
    const msgsRef = collection(db, "messages", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
      });
      unsubscribe();
    });
  };

  return (
    <>
      <div
        className={[
          "relative w-full p-4 rounded-xl md:rounded-none cursor-pointer  hover:bg-gray-50 md:border-b",
          chat.fullName === user.fullName ? "bg-gray-200" : "bg-white",
        ].join(" ")}
        onClick={() => onChatItemClick(user)}
        tabIndex={0}
      >
        <div className='flex items-center justify-between'>
          <div className='user_detail'>
            <div className='relative w-6 md:w-12 h-6 md:h-12 rounded-full overflow-hidden'>
              <img
                src={user.imageUrl}
                alt=''
                className='relative w-full h-full object-center object-cover'
              />
            </div>
            <div className='relative flex flex-col items-start justify-center pl-4'>
              <h4
                className={[
                  "text-md",
                  chat.fullName === user.fullName
                    ? "font-semibold"
                    : "font-normal",
                ].join(" ")}
              >
                {user.fullName}
              </h4>
              {data ? (
                <p className='flex items-center justify-start'>
                  <span className='text-gray-700 text-xs mr-1'>
                    {data.from === user1 ? "Me:" : null}
                  </span>
                  <span className='text-gray-400 text-xs'> {data.text}</span>
                </p>
              ) : null}
            </div>
            {data?.from !== user1 && data?.unread && (
              <small className='ml-2 text-white text-xs rounded-full px-2 bg-green-400'>
                New
              </small>
            )}
          </div>
          <div className='relative flex items-center justify-end'>
            <span
              className='text-xl text-red-300 cursor-pointer'
              aria-hidden
              onClick={() => onDeleteMsgGroup()}
            >
              <AiOutlineDelete />
            </span>
            <div
              className={[
                "w-2 h-2 rounded-full ml-2",
                user.isOnline ? "bg-green-500" : "bg-gray-400",
              ].join(" ")}
            ></div>
          </div>
        </div>
      </div>
      {/* <div
        onClick={() => selectUser(user)}
        className={`sm_container ${
          chat.fullName === user.fullName ? "selected_user" : ""
        }`}
      >
        <img src={user.imageUrl} alt='avatar' className='avatar sm_screen' />
      </div> */}
    </>
  );
};

export default User;
