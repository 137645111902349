import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  AiOutlineLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Popover } from "react-tiny-popover";
import { IconContext } from "react-icons";

import AuthLayout from "components/shared/layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { Input } from "components/shared/Input";
import Button from "components/shared/Button";
import { ToastContainer, toast } from "react-toastify";

import { connect } from "react-redux";
import { changePassword } from "../../redux/actions/userActions";

const popOverTriggerStyles = {
  position: "absolute",
  height: "100%",
  display: "flex",
  alignItems: "center",
  right: 0,
};

const popOverStyles = {
  padding: "0.5rem",
  borderRadius: "0.5rem",
  fontSize: 12,
  zIndex: 12,
};

const popOverTextColor = "rgba(255, 0, 70, 1)";

const ChangePassword = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    hasUpperCase: popOverTextColor,
    hasLowerCase: popOverTextColor,
    hasNumeric: popOverTextColor,
    hasSpecialCharacter: popOverTextColor,
    hasCorrectLength: popOverTextColor,
    isSuccess: false,
  });

  const onsubmit = (data) => {
    console.log(data);
    if(data.newPassword.length >= 6){
      props.changePassword(data, navigate);
    } else {
      toast.error("Password should contain atleast 6 characters", {
        position: "top-right",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    
  };

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const renderPopOver = () => {
    return (
      <div style={popOverStyles} className='bg-white border border-gray-400'>
        <div className='relative flex flex-col items-start justify-center'>
          {/* <span
            style={{
              color: passwordValidation.hasUpperCase,
            }}
          >
            should include at least one upper case character
          </span>
          <span
            style={{
              color: passwordValidation.hasLowerCase,
            }}
          >
            should include at least one lower case character
          </span>
          <span
            style={{
              color: passwordValidation.hasNumeric,
            }}
          >
            should include at least one number
          </span>
          <span
            style={{
              color: passwordValidation.hasSpecialCharacter,
            }}
          >
            should include at least one special character
          </span> */}
          <span
            style={{
              color: passwordValidation.hasCorrectLength,
            }}
          >
            should include {6} characters
          </span>
        </div>
      </div>
    );
  };
  const togglePopOver = () => {
    setIsPopOverOpen(!isPopOverOpen);
  };

  const css = {
    input: "border-b border-gray-300 bg-transparent text-white flex-1 p-2 my-2",
  };

  return (
    <AuthLayout>
       <ToastContainer position='bottom-right' autoClose={5000} />
      <form onSubmit={handleSubmit(onsubmit)} className='relative w-full'>
        {/* <Input
          label="oldPassword"
          name="oldPassword"
          placeHolder="Old Password"
          value=""
          type="password"
          {...register("oldPassword", { required: true })}
          css={css}
          errors={errors.oldPassword}
          icon={<AiOutlineLock />}
        /> */}
        <div className='relative flex items-center justify-start'>
          <Input
            label='newPassword'
            name='newPassword'
            placeHolder='New Password'
            type={passwordShown ? "text" : "password"}
            {...register("newPassword", { required: true })}
            css={css}
            errors={errors.newPassword}
            icon={<AiOutlineLock />}
          />
          <span
            className='absolute -right-5 md:-right-8 text-white'
            onClick={togglePassword}
          >
            {passwordShown ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </span>
          <Popover
            isOpen={isPopOverOpen}
            positions={["top", "bottom", "left", "right"]} // preferred positions by priority
            content={renderPopOver}
          >
            <div style={popOverTriggerStyles} onClick={togglePopOver}>
              <IconContext.Provider
                value={{ color: "rgba(255, 0, 80, 1)", size: "1.3em" }}
              >
                <BsFillInfoCircleFill />
              </IconContext.Provider>
            </div>
          </Popover>
        </div>
        <Input
          label='confirmPassword'
          name='confirmPassword'
          placeHolder='Confirm NEW Password'
          type='password'
          {...register("confirmPassword", { required: true })}
          css={css}
          errors={errors.confirmPassword}
          icon={<AiOutlineLock />}
        />

        <Button
          type='submit'
          cls='bg-white text-blue-600 mt-8 w-full'
          value='Change password'
        />
      </form>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  changePassword,
};

export default connect(mapStateToProps, mapActionsToProps)(ChangePassword);
