import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import Button from 'components/shared/Button';
import PageLayout from 'components/shared/layouts/PageLayout';
import { InputAlt } from 'components/shared/Input';
import InputWrapper from 'components/shared/InputWrapper';
import Title from 'components/shared/Title';
import Progress from 'components/shared/Progress';

import { connect } from 'react-redux';
import { createStolenBike } from '../redux/actions/commonActions';
import { useNavigate } from 'react-router-dom';

import { storage } from '../firebase';

import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

const StolenBikesForm = (props) => {
  const [stolenDate, setStolenDate] = useState(new Date());
  const { control, register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploadState, setUploadState] = useState('uploading');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [perc, setPerc] = useState(0);

  const css =
    'relative w-full appearance-none p-4 rounded-md border border-gray-200';

  async function uploadImage(image) {
    const storageRef = ref(storage, `/events/${Date.now()}-${image.name}`);

    const response = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(response.ref);
    return url;
  }

  async function uploadImages(images) {
    const imagePromises = Array.from(images, (image) => uploadImage(image));

    const imageRes = await Promise.all(imagePromises);
    return imageRes; // list of url like ["https://..", ...]
  }

  const onsubmit = async (data) => {
    if (selectedFiles && selectedFiles.length > 0) {
      setDisableSubmit(true);
      data.imageUrls = await uploadImages(selectedFiles);
      data.uploadItemUrl = data.imageUrls[0];
      data.date = stolenDate;
      console.log(data);
      props.createStolenBike(data, navigate);
      setDisableSubmit(false);
    }
  };

  const onDeleteClick = () => {
    setSelectedFile(null);
    setPercentUploaded(0);
  };

  const onFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    let progress = 0;

    reader.addEventListener('progress', (e, filename) => {
      if (e.lengthComputable) {
        var percentage = Math.round((e.loaded * 100) / e.total);
        setPerc(percentage);
      }
    });

    reader.readAsDataURL(file);
    setSelectedFile(e.target.files[0]);
  };

  const onChangeFileInput = (e) => {
    const files = [...e.target.files];
    const uploaded = selectedFiles ? [...selectedFiles] : [];
    files.some((file) => {
      uploaded.push(file);
    });
    setSelectedFiles(uploaded);
    console.log(uploaded);
  };

  return (
    <PageLayout
      lat={props.user?.credentials?.latitude}
      lan={props.user?.credentials?.longitude}
      isAuthenicated={props.user?.authenticated}
    >
      <div className="relative w-full flex flex-col items-center justify-start">
        <Title text="stolen bikes" size="lg" wrapperCls="my-8" />
        <form
          onSubmit={handleSubmit(onsubmit)}
          className="relative w-full md:w-1/3 px-8 md:px-0"
        >
          <InputWrapper name="date" fullWidth>
            <Controller
              control={control}
              name={'date'}
              render={({ field }) => (
                <DatePicker
                  selected={stolenDate}
                  placeholderText="Stolen date"
                  onChange={(date) => {
                    field.onChange(date);
                    setStolenDate(date);
                  }}
                  className={css}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper name="manufacturer" fullWidth>
            <InputAlt
              label="manufacturer"
              name="manufacturer"
              placeHolder="Manufacturer"
              type="text"
              css={css}
              {...register('manufacturer')}
            />
          </InputWrapper>
          <InputWrapper name="model" fullWidth>
            <InputAlt
              label="model"
              name="model"
              placeHolder="Model"
              type="text"
              css={css}
              {...register('model')}
            />
          </InputWrapper>
          <InputWrapper name="year" fullWidth>
            <InputAlt
              label="year"
              name="year"
              placeHolder="Year"
              type="text"
              css={css}
              {...register('year')}
            />
          </InputWrapper>
          <InputWrapper name="color" fullWidth>
            <InputAlt
              label="color"
              name="color"
              placeHolder="Color"
              type="text"
              css={css}
              {...register('color')}
            />
          </InputWrapper>
          <InputWrapper name="location" fullWidth>
            <InputAlt
              label="location"
              name="location"
              placeHolder="Location Stolen"
              type="text"
              css={css}
              {...register('location')}
            />
          </InputWrapper>

          {/* <div
            tabIndex={0}
            className=' relative w-full my-3 text-white bg-gray-600 rounded-full px-4 py-2 flex items-center justify-center'
          >
            <label htmlFor='uploadPicture' className='capitalize text-sm'>
              upload bike picture
            </label>
            <input
              type='file'
              name='file'
              id='file'
              accept='image/*'
              className='opacity-0 absolute appearance-none w-full'
              {...register("stolenBikePicture")}
              onChange={onFileInputChange}
            />
          </div>
          {selectedFile ? (
            <Progress
              percentage={perc}
              filename={selectedFile && selectedFile.name}
              uploadState={uploadState}
              onDelete={onDeleteClick}
            />
          ) : null} */}
          <InputWrapper
            label={`${selectedFile ? selectedFile.name : 'Attach Files'}`}
            name="file"
            labelCls={css + ' py-5 cursor-pointer hover:bg-gray-200'}
            fullWidth
          >
            <input
              type="file"
              name="file"
              id="file"
              multiple
              className="input-file"
              accept="image/*"
              {...register('attachments')}
              onChange={onChangeFileInput}
            />
            <div
              className="w-full flex flex-col items-start overflow-y-scroll"
              style={{ maxHeight: 100 }}
            >
              {selectedFiles &&
                selectedFiles.map((file, i) => (
                  <span className="text-xs py-0.5 px-1" key={i}>
                    {i + 1}. {file.name}
                  </span>
                ))}
            </div>
          </InputWrapper>
          <Button
            type="submit"
            cls="bg-blue-500 text-white my-3 w-full tracking-widest uppercase"
            value="Post stolen bike"
            // disabled={disableSubmit}
            isLoading={disableSubmit}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  createStolenBike,
};

export default connect(mapStateToProps, mapActionsToProps)(StolenBikesForm);
