import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
//import { auth, signInWithGoogle } from "../../firebase";
import {
  FaFacebookF,
  FaTwitter,
  FaGooglePlusG,
  FaGoogle,
  // FaInstagram,
  // FaEye,
} from "react-icons/fa";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Popover } from "react-tiny-popover";
import { IconContext } from "react-icons";

import AuthLayout from "components/shared/layouts/AuthLayout";
import { Input } from "components/shared/Input";
import Button from "components/shared/Button";
import { ToastContainer, toast } from "react-toastify";
import { clearErrors } from "../../redux/actions/uiActions";

// Redux stuff
import { connect } from "react-redux";
import {
  loginUser,
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter
} from "../../redux/actions/userActions";
import { CLEAR_ERRORS } from "redux/types";

const popOverTriggerStyles = {
  position: "absolute",
  height: "100%",
  display: "flex",
  alignItems: "center",
  right: 0,
};

const popOverStyles = {
  padding: "0.5rem",
  borderRadius: "0.5rem",
  fontSize: 12,
  zIndex: 12,
};

const popOverTextColor = "rgba(255, 0, 70, 1)";

const LogIn = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    hasUpperCase: popOverTextColor,
    hasLowerCase: popOverTextColor,
    hasNumeric: popOverTextColor,
    hasSpecialCharacter: popOverTextColor,
    hasCorrectLength: popOverTextColor,
    isSuccess: false,
  });

  useEffect(() => {
    if (props.UI.errors) {
      toast.error(props.UI.errors, {
        position: "top-right",
        //autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      props.clearErrors();
    }
  }, [props.UI.errors]);

  const onsubmit = (event) => {
    event.preventDefault();

    const userData = {
      email: username,
      password: password,
    };
    // console.log(userData);
    props.loginUser(userData, navigate);
  };

  const onGoogleButtonClick = async (event) => {
    event.preventDefault();
    await props.signInWithGoogle(navigate);
  };

  const onFacebookButtonClick = (event) => {
    event.preventDefault();
    props.signInWithFacebook(navigate);
  };

  const onTwitterButtonClick = (event) => {
    event.preventDefault();
    props.signInWithTwitter(navigate);
  };

  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const css = {
    input: "border-b border-gray-300 bg-transparent text-white flex-1 p-2 my-2",
  };

  const renderPopOver = () => {
    return (
      <div style={popOverStyles} className='bg-white border border-gray-400'>
        <div className='relative flex flex-col items-start justify-center'>
          <span
            style={{
              color: passwordValidation.hasUpperCase,
            }}
          >
            should include at least one upper case character
          </span>
          <span
            style={{
              color: passwordValidation.hasLowerCase,
            }}
          >
            should include at least one lower case character
          </span>
          <span
            style={{
              color: passwordValidation.hasNumeric,
            }}
          >
            should include at least one number
          </span>
          <span
            style={{
              color: passwordValidation.hasSpecialCharacter,
            }}
          >
            should include at least one special character
          </span>
          <span
            style={{
              color: passwordValidation.hasCorrectLength,
            }}
          >
            should include {6} characters
          </span>
        </div>
      </div>
    );
  };
  const togglePopOver = () => {
    setIsPopOverOpen(!isPopOverOpen);
  };

  return (
    <AuthLayout>
      <ToastContainer position='bottom-right' autoClose={5000} />
      <form onSubmit={handleSubmit(onsubmit)} className='relative w-full'>
        <Input
          label='username'
          name='username'
          placeHolder='E-Mail'
          value={username}
          type='text'
          {...register("username", { required: true })}
          css={css}
          errors={errors.username}
          icon={<AiOutlineUser />}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <div className='relative flex items-center justify-start'>
          <Input
            label='password'
            name='password'
            placeHolder='Password'
            value={password}
            type={passwordShown ? "text" : "password"}
            {...register("password", { required: true })}
            css={css}
            wrapperCls='flex-1'
            errors={errors.password}
            icon={<AiOutlineLock />}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <span
            className='absolute -right-5 md:-right-8 text-white'
            onClick={togglePassword}
          >
            {passwordShown ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </span>
          {/* <Popover
            isOpen={isPopOverOpen}
            positions={["top", "bottom", "left", "right"]} // preferred positions by priority
            content={renderPopOver}
          >
            <div style={popOverTriggerStyles} onClick={togglePopOver}>
              <IconContext.Provider
                value={{ color: "rgba(255, 0, 80, 1)", size: "1.3em" }}
              >
                <BsFillInfoCircleFill />
              </IconContext.Provider>
            </div>
          </Popover> */}
        </div>
        <div className='relative w-full text-right text-white flex flex-col items-end mt-2'>
         <div className="relative w-full flex items-center justify-between">
         <Link to='/'>
            <span className='relative capitalize text-xs md:text-base'>
              Back to home
            </span>
          </Link>
          <Link to='/forgot-password'>
            <span className='relative capitalize text-xs md:text-base'>
              forgot password
            </span>
          </Link>
         </div>
        </div>
        <Button
          type='submit'
          cls='bg-white text-blue-600 mt-2 md:mt-4 w-full'
          value='Log In'
          onClick={onsubmit}
        />
      </form>
      <div className='relative w-full text-right text-white text-base flex flex-col items-center mt-2 md:mt-4'>
        <span className='relative capitalize text-xs md:text-base'>
          don't have an account?{" "}
          <Link to='/signup'>
            <span className='underline'>sign up</span>
          </Link>
        </span>
      </div>
      <div className='relative w-full text-right text-white text-base flex flex-col items-center mt-2'>
        {/* <Button
          type='button'
          cls='bg-white text-blue-600 mt-2 w-full'
          value='Log In with Facebook'
          icon={<FaFacebookF />}
          onClick={onFacebookButtonClick}
        /> */}
        <Button
          type='button'
          cls='bg-white text-blue-600 mt-2 w-full'
          value='Log In with Twitter'
          icon={<FaTwitter />}
          onClick={onTwitterButtonClick}
        />
        <Button
          type='button'
          cls='bg-white text-blue-600 mt-2 w-full'
          value='Log In with Google'
          icon={<FaGoogle />}
          onClick={onGoogleButtonClick}
        />
        {/* <Button
          type="button"
          cls="bg-white text-blue-600 mt-4 w-full"
          value="Log In with instagram"
          icon={<FaInstagram />}
        /> */}
      </div>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  signInWithGoogle,
  signInWithFacebook,
  signInWithTwitter,
  clearErrors,
};

export default connect(mapStateToProps, mapActionsToProps)(LogIn);
