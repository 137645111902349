import React from "react";

const NavItemWrap = ({ children, ...navItemWrapProps }) => (
  <div
    className="relative flex items-center justify-start px-6 py-2 text-black hover:bg-gray-100"
    {...navItemWrapProps}
  >
    {children}
  </div>
);

export default NavItemWrap;
