import React from "react";
import { useForm } from "react-hook-form";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";
import Title from "components/shared/Title";

import { connect } from 'react-redux';
import { createContactUs } from "../../redux/actions/commonActions";
import { useNavigate } from "react-router-dom";

const ContactUs = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const css =
    "relative w-full appearance-none p-4 rounded-md border border-gray-200";

  const onsubmit = (data) => {
    console.log(data);
    props.createContactUs(data, navigate);
  };
  return (
    <PageLayout 
      lat={props.user?.credentials?.latitude}
      lan={props.user?.credentials?.longitude}
      isAuthenicated={props.user?.authenticated}
    >
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-48 md:pb-32'>
        <Title text='contact us' size='sm' wrapperCls='my-8' />
        <Title text='how can we help you?' size='xl' wrapperCls='my-4' />
        <form onSubmit={handleSubmit(onsubmit)} className='relative w-2/3 mt-8'>
          <InputWrapper label='Name' name='name' fullWidth required>
            <InputAlt
              label='name'
              name='name'
              placeHolder='Name'
              type='text'
              css={css}
              {...register("name", { required: true })}
            />
          </InputWrapper>
          <InputWrapper label='Email' name='email' fullWidth required>
            <InputAlt
              label='email'
              name='email'
              placeHolder='Email'
              type='email'
              css={css}
              {...register("email", { required: true })}
            />
          </InputWrapper>
          <InputWrapper label='Company Name' name='company' fullWidth>
            <InputAlt
              label='company'
              name='company'
              placeHolder='Company Name'
              type='text'
              css={css}
              {...register("company")}
            />
          </InputWrapper>
          <InputWrapper label='Subject' name='subject' fullWidth required>
            <InputAlt
              label='subject'
              name='subject'
              placeHolder='Subject'
              type='text'
              css={css}
              {...register("subject", { required: true })}
            />
          </InputWrapper>
          <InputWrapper label='Message' name='message' fullWidth required>
            <textarea
              label='message'
              name='message'
              placeholder='Message'
              type='textarea'
              className={css}
              rows={4}
              cols={22}
              {...register("message", { required: true })}
            />
          </InputWrapper>
          <Button
            type='submit'
            cls='bg-blue-500 text-white my-3 mx-auto w-1/3'
            inputCls='capitalize'
            value='submit'
            //   onClick={onsubmit}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  createContactUs
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ContactUs);
