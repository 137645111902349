import React from "react";
import { useForm } from "react-hook-form";
import { BsFillTelephoneFill } from "react-icons/bs";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import Title from "components/shared/Title";
import InputWrapper from "components/shared/InputWrapper";

import { CONTACT_DATA } from "store";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { createHelp } from "../../redux/actions/commonActions";
import { InputAlt } from "components/shared/Input";

const css =
  "relative w-full appearance-none p-4 rounded-md border border-gray-200";

const Help = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onsubmit = (data) => {
    console.log(data);
    props.createHelp(data, navigate);
  };

  console.log(props.user);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}
    >
      <div className='relative w-full h-auto flex flex-col items-center justify-start'>
        <div className='relative my-4 flex items-center justify-center'>
          <Title text='need help?' size='lg' />
        </div>
        <div className='relative w-full md:w-1/3  flex items-center justify-center'>
          <form
            onSubmit={handleSubmit(onsubmit)}
            className='relative mt-8 w-full px-8 md:px-0 flex flex-col items-center'
          >
            <InputWrapper label='Select Issue' name='issue' fullWidth>
              <InputAlt
                label='issue'
                name='issue'
                placeHolder='Issue'
                type='text'
                css={css}
                {...register("issue", { required: true })}
              />
            </InputWrapper>
            <InputWrapper label='Location' name='location' fullWidth>
              <InputAlt
                label='location'
                name='location'
                placeHolder='Location'
                type='text'
                css={css}
                {...register("location", { required: true })}
              />
            </InputWrapper>
            <InputWrapper
              label='Brief Description'
              name='description'
              fullWidth
            >
              <textarea
                label='description'
                name='description'
                placeHolder='Brief Description'
                type='textarea'
                className={css}
                rows={4}
                cols={22}
                {...register("description")}
              />
            </InputWrapper>
            <Button
              type='submit'
              cls='bg-blue-500 text-white w-full my-3'
              value='Send to Nearby Bikes'
              //   onClick={onsubmit}
            />
          </form>
        </div>
        <div className='relative w-full md:w-1/3 mt-8 flex flex-col items-center justify-center'>
          <div
            className='relative w-full flex flex-row items-center justify-between px-4 my-1 md:my-2 border-b'
            key={"tow"}
          >
            <span className='relative text-base  text-blue-500 uppercase'>
              {"tow"}
            </span>
            <div className='relative text-base flex items-center justify-start space-x-2 text-gray-500 uppercase ml-4 p-2'>
              <BsFillTelephoneFill />
              <a href={`tel:${props.user?.credentials?.tow}`}>
                {props.user?.credentials?.tow}
              </a>
            </div>
          </div>
          <div
            className='relative w-full flex flex-row items-center justify-between px-4 my-1 md:my-2 border-b'
            key={"insurance"}
          >
            <span className='relative text-base  text-blue-500 uppercase'>
              {"insurance"}
            </span>
            <div className='relative text-base flex items-center justify-start space-x-2 text-gray-500 uppercase ml-4 p-2'>
              <BsFillTelephoneFill />
              <a href={`tel:${props.user?.credentials?.insurance}`}>
                {props.user?.credentials?.insurance}
              </a>
            </div>
          </div>
          <div
            className='relative w-full flex flex-row items-center justify-between px-4 my-1 md:my-2 border-b'
            key={"legal"}
          >
            <span className='relative text-base  text-blue-500 uppercase'>
              {"legal"}
            </span>
            <div className='relative text-base flex items-center justify-start space-x-2 text-gray-500 uppercase ml-4 p-2'>
              <BsFillTelephoneFill />
              <a href={`tel:${props.user?.credentials?.legal}`}>
                {props.user?.credentials?.legal}
              </a>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  createHelp,
};

export default connect(mapStateToProps, mapActionsToProps)(Help);
