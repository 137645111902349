import React, { useEffect, useState, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import PageLayout from 'components/shared/layouts/PageLayout';
import Button from 'components/shared/Button';
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';

import { db, auth, storage } from '../../firebase';
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';

import User from '../../components/User/User';
import MessageForm from '../../components/MessageForm/MessageForm';
import Message from '../../components/Message/Message';
import Title from 'components/shared/Title';

import { connect } from 'react-redux';

const Inbox = (props) => {
  const [step, setStep] = useState(0);

  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState('');
  const [message, setMessage] = useState('');
  const [img, setImg] = useState('');
  const [msgs, setMsgs] = useState([]);
  const [isChatlistOpen, setIsChatlistOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [friends, setFriends] = useState(() => props.user?.friends || []);
  const [searchResults, setSearchResults] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const user1 = props.user?.credentials?.id;

  // useEffect(() => {
  //   if (!params || params === null) return;
  //   let messageData = messagesList.find((item) => item.userName === params);
  //   setMeta({ name: messageData.userName, avatar: messageData.avatar });
  //   setChat(messageData.chat);
  // }, [params]);

  useEffect(() => {
    console.log(props.user);
  }, [props.user]);

  useEffect(() => {
    let search = window.location.search;
    const id = new URLSearchParams(search).get('id');
    console.log(id);
    if (id) {
      setSelectedUserId(id);
    }
  }, []);

  useEffect(() => {
    if (
      selectedUserId &&
      props.user?.friends &&
      props.user?.friends.length > 0
    ) {
      const selectedUser = props.user?.friends.filter(
        (friend) => friend.id == selectedUserId
      );
      if (selectedUser && selectedUser.length > 0) selectUser(selectedUser[0]);
    }
    let friends = props.user?.friends?.map((item) => {
      return item.handle;
    });
    if (friends && friends.length > 0) {
      const usersRef = collection(db, 'users');
      // create query object
      const q = query(usersRef, where('handle', 'in', friends));
      // execute query
      const unsub = onSnapshot(q, (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((doc) => {
          users.push({ id: doc.id, ...doc.data() });
        });
        setUsers(users);
      });
      return () => unsub();
    }
  }, [props.user]);

  const onDeleteMsg = (userid, message) => {
    console.log(userid, message);
    const id = user1 > chat.id ? `${user1 + chat.id}` : `${chat.id + user1}`;
    const msgsRef = doc(db, 'messages', id, 'chat', message.id);
    deleteDoc(msgsRef);
  };

  const selectUser = async (user) => {
    setChat(user);

    const user2 = user.id;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;

    const msgsRef = collection(db, 'messages', id, 'chat');
    const q = query(msgsRef, orderBy('createdAt', 'asc'));

    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push({ ...doc.data(), id: doc.id });
      });
      setMsgs(msgs);
    });

    // get last message b/w logged in user and selected user
    const docSnap = await getDoc(doc(db, 'lastMsg', id));
    // if last message exists and message is from selected user
    if (docSnap.data() && docSnap.data().from !== user1) {
      // update last message doc, set unread to false
      await updateDoc(doc(db, 'lastMsg', id), { unread: false });
    }
  };

  useEffect(() => {
    if (
      selectedUserId &&
      props.user?.friends &&
      props.user?.friends.length > 0
    ) {
      const selectedUser = props.user?.friends.filter(
        (friend) => friend.id == selectedUserId
      );
      if (selectedUser && selectedUser.length > 0) selectUser(selectedUser[0]);
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (props.user?.friends && props.user?.friends.length > 0) {
      if (searchTerm && searchTerm != '') {
        const searchResults = props.user?.friends.filter((f) =>
          f.fullName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(searchResults);
      } else {
        setSearchResults(props.user?.friends);
      }
    }
  }, [searchTerm, props.user]);

  const handleNewMessageSubmit = async (e) => {
    e.preventDefault();

    //const user2 = chat.id;

    const id = user1 > chat.id ? `${user1 + chat.id}` : `${chat.id + user1}`;

    let url;
    if (img) {
      const imgRef = ref(
        storage,
        `images/${new Date().getTime()} - ${img.name}`
      );
      const snap = await uploadBytes(imgRef, img);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
    }

    try {
      if (img || message) {
        var msg = message ? message : '';
        await addDoc(collection(db, 'messages', id, 'chat'), {
          msg,
          from: user1,
          to: chat.id,
          createdAt: Timestamp.fromDate(new Date()),
          media: url || '',
        });

        await setDoc(doc(db, 'lastMsg', id), {
          msg,
          from: user1,
          to: chat.id,
          createdAt: Timestamp.fromDate(new Date()),
          media: url || '',
          unread: true,
        });
      }
    } catch (ex) {
      console.log(ex);
    }

    setMessage('');
    setImg('');
  };

  // const handleItemClick = (name, index) => {
  //   setParams(name);
  //   setActiveMessage(index);
  //   isMobile && handleNext();
  // };

  // const handleMessageChange = (e) => {
  //   let m = e.target.value;
  //   setMessage(m);
  // };

  // const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

  // const handleEmojiClick = (event, emojiObject) => {
  //   setSelectedEmojis([...selectedEmojis, emojiObject.emoji]);
  //   setMessage((m) => m.concat(emojiObject.emoji));
  // };

  const handleNext = () => {
    setStep(1);
  };

  const closeChatList = () => {
    setIsChatlistOpen(false);
  };

  return (
    <PageLayout wrapperCls="border-l border-r">
      <div
        className="relative grid grid-cols-1 md:grid-cols-6 w-full h-full"
        // style={{ height: "calc(100vh - 70px)" }}
      >
        <div className="border-r overflow-y-auto col-span-2 hidden md:block">
          <Popover>
            <div className="relative w-full border-b p-4 flex flex-col items-center justify-between">
              <Title text="messages" size="lg" />
              <Popover.Button className="relative px-4 py-1 my-2 bg-blue-500 text-white flex items-center justify-center rounded-full whitespace-pre">
                <span className="mr-12">Search</span> <AiOutlineSearch />
              </Popover.Button>
              <Popover.Overlay className="fixed inset-0 z-top2 p-8 flex items-center justify-center bg-white/40" />
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="fixed z-top2 inset-[15rem] mx-auto max-w-md max-h-96 bg-blue-500 border-r rounded-xl overflow-y-auto p-2">
                  <div className="flex items-center justify-between">
                    {/* <Button
                      type="button"
                      cls="bg-red-400 text-white w-max my-2 mr-2"
                      inputCls="font-normal uppercase text-xs"
                      value="close"
                      onClick={() => closeChatList()}
                    /> */}
                    <input
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Search friends"
                      className="flex-1 appearance-none py-1 px-2 rounded-md text-black"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  {searchResults?.map((user) => (
                    <div
                      className="my-2 rounded-lg overflow-hidden"
                      key={user.handle}
                    >
                      <User
                        key={user.handle}
                        user={user}
                        selectUser={selectUser}
                        user1={user1}
                        chat={chat}
                        closeChatList={closeChatList}
                      />
                    </div>
                  ))}
                </Popover.Panel>
              </Transition>
              {/* {isChatlistOpen ? (
                
              ) : null} */}
            </div>
          </Popover>
          {searchResults?.map((user) => (
            <User
              key={user.handle}
              user={user}
              selectUser={selectUser}
              user1={user1}
              chat={chat}
              closeChatList={closeChatList}
            />
          ))}
        </div>
        <Popover className="md:hidden relative">
          <Popover.Button className="fixed top-20 z-50 left-4 md:left-1/3 flex items-center justify-start visible md:invisible">
            <Button
              type="button"
              cls="bg-blue-600 text-white"
              inputCls="font-normal uppercase text-xs"
              value="chatlist"
              // onClick={() => setIsChatlistOpen(true)}
            />
          </Popover.Button>

          <Popover.Overlay className="fixed inset-0 z-top2 p-8 flex items-center justify-center bg-white/40" />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div className="relative w-full z-top2 flex justify-center">
              <Popover.Panel className="absolute mx-auto max-w-full bg-blue-500 border-r rounded-xl overflow-y-auto p-2">
                <div className="flex items-center justify-between">
                  {/* <Button
                      type="button"
                      cls="bg-red-400 text-white w-max my-2 mr-2"
                      inputCls="font-normal uppercase text-xs"
                      value="close"
                      onClick={() => closeChatList()}
                    /> */}
                  <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search friends"
                    className="flex-1 appearance-none py-1 px-2 rounded-md text-black"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {searchResults?.map((user) => (
                  <div
                    className="my-2 rounded-lg overflow-hidden"
                    key={user.handle}
                  >
                    <User
                      key={user.handle}
                      user={user}
                      selectUser={selectUser}
                      user1={user1}
                      chat={chat}
                      closeChatList={closeChatList}
                    />
                  </div>
                ))}
              </Popover.Panel>
            </div>
          </Transition>
        </Popover>
        {/* <div className="fixed top-20 z-50 left-4 md:left-1/3 flex items-center justify-start visible md:invisible">
          <Button
            type="button"
            cls="bg-blue-600 text-white"
            inputCls="font-normal uppercase text-xs"
            value="chatlist"
            onClick={() => setIsChatlistOpen(true)}
          />
        </div> */}
        {/* {isChatlistOpen ? (
                
              ) : null} */}
        <div className="col-span-1 md:col-span-4 flex flex-col">
          {chat ? (
            <>
              <div className="relative w-full p-2 border-b flex items-center justify-center">
                <h3 className="text-lg font-semibold">{chat.fullName}</h3>
                <span
                  className={[
                    'w-2 h-2 rounded-full bg-gray-400 ml-2',
                    // isOnline? "bg-green-500" : "bg-gray-400"
                  ].join(' ')}
                />
              </div>
              <div className="messages flex-1">
                {msgs.length
                  ? msgs.map((msg, i) => (
                      <Message
                        key={i}
                        msg={msg}
                        user1={user1}
                        onDeleteMsg={() => onDeleteMsg(user1, msg)}
                      />
                    ))
                  : null}
              </div>
              <MessageForm
                handleSubmit={handleNewMessageSubmit}
                text={message}
                setText={setMessage}
                setImg={setImg}
              />
            </>
          ) : (
            <h3 className="relative w-full h-96 flex items-center justify-center">
              Select a user to start conversation
            </h3>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Inbox);
