// import { US_STATE_LIST, CANADA_STATE_LIST } from "../Data/state";

// data
export const videoList = [
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/frank.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/iu.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/mountain.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/rocky.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/frank.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/iu.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/mountain.jpg",
  },
  {
    name: "nyc adventure ride",
    comments: 5,
    stars: 3,
    user: { name: "Matt", avatar: "/images/profile.png" },
    location: "orlando, USA",
    thumbnail: "/images/rocky.jpg",
  },
];
export const notificationsList = [
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
  {
    user: { name: "Matt", avatar: "/images/profile.png" },
    headline: "alert headline",
    location: "157 Bleecker St New York 10012",
    thumbnail: "/images/rocky.jpg",
    content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi veniam sapiente, asperiores quisquam reiciendis modi fugiat similique neque! Corrupti earum aperiam, dolores rem pariatur`,
    date: `October 10 at 10:30 PM`,
    images: ["/images/rocky.jpg", "/images/rocky.jpg", "/images/rocky.jpg"],
  },
];
export const eventsList = [
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/ium.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iul.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iu.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/rocky.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/ium.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iul.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iu.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/rocky.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/ium.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iul.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/iu.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
  {
    title: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    hostedBy: "JJ Sansaverino",
    dateLong: "Thursday, November 3 at 8:30 PM - 11:30 PM EDT",
    dateShort: "03 Nov",
    venueLine1: "Carrol Place",
    venueLine2: "157 Bleeker St. New York 10012",
    isInvited: true,
    meta: {
      attending: 29,
      maybe: 17,
      sharedWith: 4,
    },
    detailLine1: `An intimate night with one of today's most electrifying artists\nNational Recording guitarist\nJJ Sansaverino`,
    detailLine2: `live at the exclusive Carroll place\nNY magazine voted "Best Greenwich Village Wine Bar"`,
    detailLine3: `One Show Only \n Thursday 11/33/16 8"30PM`,
    image: "/images/rocky.jpg",
    posted: "5 hours ago",
    externalLinks: "http://www.carrollplacenyc.com/event/jj-sansaverino",
  },
];
export const sampleChat = [
  {
    body: "it goes a little something like this",
    isSender: true,
    date: "1/3/2022",
    time: "6:50 PM",
    attachments: [],
  },
  {
    body: "Hey dude, are you joining on the NYC Adventr. Ride?",
    isSender: true,
    date: "1/3/2022",
    time: "1:36 AM",
    attachments: [],
  },
  {
    body: "did you check out John's latest video?",
    isSender: false,
    date: "1/6/2022",
    time: "6:50 PM",
    attachments: [],
  },
  {
    body: "Yes I did! Every saturday! Rap Attack, Mr.Magic, Marley marl. I even let my tape rock 'til my tape popped. did you see the new Harley Davidson?",
    isSender: true,
    date: "1/6/2022",
    time: "6:50 PM",
    attachments: [],
  },
  {
    body: "Haha awesome, i think I know the album u say",
    isSender: false,
    date: "1/8/2022",
    time: "9:50 PM",
    attachments: [],
  },
];
export const messagesList = [
  {
    userName: "matt doe",
    avatar: "/images/iu.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "1 hour ago",
    chat: sampleChat,
  },
  {
    userName: "russell george",
    avatar: "/images/ium.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "8 hour ago",
    chat: sampleChat,
  },
  {
    userName: "nyc rider",
    avatar: "/images/rocky.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "sunday",
    chat: sampleChat,
  },
  {
    userName: "matt doe",
    avatar: "/images/iu.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "1 hour ago",
    chat: sampleChat,
  },
  {
    userName: "russell george",
    avatar: "/images/ium.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "8 hour ago",
    chat: sampleChat,
  },
  {
    userName: "nyc rider",
    avatar: "/images/rocky.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "sunday",
    chat: sampleChat,
  },
  {
    userName: "matt doe",
    avatar: "/images/iu.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "1 hour ago",
    chat: sampleChat,
  },
  {
    userName: "russell george",
    avatar: "/images/ium.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "8 hour ago",
    chat: sampleChat,
  },
  {
    userName: "nyc rider",
    avatar: "/images/rocky.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "sunday",
    chat: sampleChat,
  },
  {
    userName: "matt doe",
    avatar: "/images/iu.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "1 hour ago",
    chat: sampleChat,
  },
  {
    userName: "russell george",
    avatar: "/images/ium.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "8 hour ago",
    chat: sampleChat,
  },
  {
    userName: "nyc rider",
    avatar: "/images/rocky.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "sunday",
    chat: sampleChat,
  },
  {
    userName: "matt doe",
    avatar: "/images/iu.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "1 hour ago",
    chat: sampleChat,
  },
  {
    userName: "russell george",
    avatar: "/images/ium.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "8 hour ago",
    chat: sampleChat,
  },
  {
    userName: "nyc rider",
    avatar: "/images/rocky.jpg",
    shortContent: "Lorem ipsum dolor sit amet",
    timeAgo: "sunday",
    chat: sampleChat,
  },
];

export const friendsList = [
  {
    fullName: "aerond sigue",
    nickname: "aero",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/rocky.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "jameson jones",
    nickname: "james",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/mountain.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "blake griffin",
    nickname: "blake32",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/signup.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "arnold heins",
    nickname: "arnold",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/bg.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "jhomar montoya",
    nickname: "cj",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/iul.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "emman gerald",
    nickname: "jeman",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/frank.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "aerond sigue",
    nickname: "aero",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/rocky.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
  {
    fullName: "jameson jones",
    nickname: "james",
    country: "Orlando FL USA",
    state: "FL USA",
    imageUrl: "/images/mountain.jpg",
    birthDay: "August 12, 1992",
    videosList: videoList,
  },
];
