import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ImSearch } from "react-icons/im";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";

import useBounds from "hooks/useBounds";

import { PROFILE_HEADER_BUTTONS } from "store";
import { friendsList } from "store/data";
import ProfileHeader from "components/ProfileHeader";

import { connect } from "react-redux";
import { unfriend, getUsers, addFriend } from "../../redux/actions/userActions";

const FriendsPage = (props) => {
  const navigate = useNavigate();
  const { height } = useBounds(400);
  const [users, setUsers] = useState([]);
  const [filteredFriendsList, setFilteredFriendsList] = useState([
    () => props.user?.friends,
  ]);
  const [filteringKeyword, setFilteringKeyword] = useState("");

  useEffect(() => {
    props
      .getUsers()
      .then((data) => {
        setUsers(data);
        setFilteredFriendsList(props.user?.friends);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(props.user?.friends);
  }, [props.user]);

  useEffect(() => {
    if (filteringKeyword === "") {
      setFilteredFriendsList(props.user?.friends);
      return;
    }
    let _filteredList = users.filter(
      (f) =>
        f?.fullName.toLowerCase().indexOf(filteringKeyword.toLowerCase()) >= 0
    );
    setFilteredFriendsList(_filteredList);
  }, [filteringKeyword, props.user, users]);

  const onSearchInputChange = (e) => {
    setFilteringKeyword(e.target.value);
  };

  const unfriendUser = (userId, friendId) => {
    console.log("Un Friend");
    const friendData = {
      friendId: friendId,
      userId: userId,
    };
    props.unfriend(friendData);
  };

  const addFriend = (friend) => {
    console.log("Add Friend");
    const friendData = {
      receiverUserId: friend.id,
      receiverUserHandle: friend.handle,
      senderImageUrl: props.user.credentials.imageUrl,
      senderName: props.user.credentials.fullName,
      senderCity: props.user.credentials.city,
      senderCountry: props.user.credentials.country,
      senderState: props.user.credentials.state,
      senderUserId: props.user.credentials.id,
      senderUserHandle: props.user.credentials.handle,
    };
    props.addFriend(friendData);
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={props.user.credentials}
        isAuthenicated={props.user.authenticated}
      />

      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <div className='relative w-full md:w-3/4 mt-8 md:mr-4'>
          <div className='relative py-4 px-8 md:px-0 flex w-full md:w-3/4 items-center justify-between border-b border-gray-800'>
            <div className='relative w-full flex flex-col md:flex-row items-center justify-between'>
              <Title
                text={
                  <span>
                    friends{" "}
                    <span className='text-gray-400 text-xl md:text-4xl'>
                      ({props.user?.friends?.length})
                    </span>
                  </span>
                }
                size='xl'
              />
              <div
                className='relative flex items-center justify-between bg-white px-2 md:px-4 py-1 md:py-2 rounded-full border'
                // onClick={toggleSearchFilter}
              >
                <input
                  type='text'
                  name='search'
                  id='search'
                  className='relative appearance-none border-none text-sm md:text-base outline-none w-full bg-white font-semibold text-black'
                  placeholder='SEARCH'
                  onChange={onSearchInputChange}
                />
                <span className='relative text-white text-sm md:text-base font-bold'>
                  <ImSearch />
                </span>
              </div>
            </div>
          </div>
          <div className='relative w-full md:w-3/4 flex flex-col items-start px-4 md:px-0 pb-8'>
            {filteredFriendsList?.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <div className='relative my-2 p-4 w-full flex flex-row items-center justify-start border-b '>
                  <div className='relative w-6 md:w-12 h-6 md:h-12 rounded-full overflow-hidden'>
                    <img
                      src={item.imageUrl}
                      alt=''
                      className='relative w-full h-full object-center object-cover'
                    />
                  </div>
                  <div className='relative flex-1 px-2 md:px-4 flex flex-col items-start justify-start'>
                    <Link to={`/friend/${item.handle}`}>
                      <div className='relative'>
                        <span className='relative text-sm md:text-base font-semibold capitalize'>
                          {item.fullName}
                        </span>
                      </div>
                    </Link>

                    <div className='relative flex flex-row items-center justify-between w-full mt-1 md:mt-3'>
                      <span className='relative text-gray-600 mr-2 font-normal text-xs'>
                        {item?.city?.concat(
                          ", ",
                          item?.state,
                          ", ",
                          item?.country
                        )}
                      </span>
                    </div>
                  </div>
                  <div className='relative flex flex-col md:flex-row items-start justify-center mr-6 md:mr-0 ml-1 md:ml-4  md:space-x-2'>
                    <Button
                      type='button'
                      cls='bg-blue-500 text-white tracking-widest uppercase my-1 md:my-0'
                      inputCls={"text-xs md:text-base"}
                      value='Message'
                      onClick={() => navigate(`/inbox?id=${item.id}`)}
                    />
                    {item.isFriend ? (
                      <Button
                        type='button'
                        cls='bg-blue-500 text-white  tracking-widest uppercase my-1 md:my-0'
                        inputCls={"text-xs md:text-base"}
                        value='Unfriend'
                        onClick={() =>
                          unfriendUser(
                            props.user?.credentials?.id,
                            item.friendId
                          )
                        }
                      />
                    ) : (
                      <Button
                        type='button'
                        cls='bg-blue-500 text-white  tracking-widest uppercase my-1 md:my-0'
                        inputCls={"text-xs md:text-base"}
                        value='View'
                        onClick={() => navigate(`/friend/${item.handle}`)}
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  unfriend,
  getUsers,
  addFriend,
};

export default connect(mapStateToProps, mapActionsToProps)(FriendsPage);
