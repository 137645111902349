import React from 'react';
import { cn } from 'utils';

const Section = ({ children, className, ...props }) => {
  return (
    <section
      className={cn('relative w-full h-full min-h-max', className)}
      {...props}
    >
      {children}
    </section>
  );
};

export default Section;
