import React from "react";
import { AiOutlineCheck, AiFillDelete } from "react-icons/ai";
import { BiError } from "react-icons/bi";

const Progress = ({ percentage, filename, uploadState, onDelete }) => {
  const renderState = (state) => {
    let el = (
      <div className='relative w-8 text-xl text-gray-900 font-semibold' />
    );

    switch (state) {
      case "uploading":
        el = (
          <div className='relative w-8 text-sm text-gray-900 font-semibold'>
            {`${percentage}%`}
          </div>
        );
        break;
      case "done":
        el = (
          <div className='relative w-8 text-sm text-green-600 font-semibold'>
            <AiOutlineCheck />
          </div>
        );
        break;
      case "error":
        el = (
          <div className='relative w-8 text-sm text-red-600 font-semibold'>
            <BiError />
          </div>
        );
        break;
      default:
        break;
    }

    return el;
  };

  return (
    <div
      tabIndex={-1}
      className='relative my-1 px-4 py-2 bg-gray-200 w-full rounded-md flex flex-row items-center justify-evenly'
    >
      <div className='relative flex flex-col items-start justify-center w-2/3'>
        <div className='relative w-full overflow-hidden whitespace-nowrap truncate'>
          <span className='text-gray-700 text-xs truncate'>{filename}</span>
        </div>
        <div className='relative w-full h-1 my-1 rounded-md bg-gray-300 overflow-hidden'>
          <div
            className='h-full bg-blue-500'
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
      {renderState(uploadState)}

      <span
        aria-hidden
        onClick={() => typeof onDelete === "function" && onDelete()}
        className='relative w-8 text-sm text-gray-600 font-semibold'
      >
        <AiFillDelete />
      </span>
    </div>
  );
};

Progress.defaultProps = {
  percentage: "0",
  filename: "fileName",
  uploadState: "uploading",
};

export default Progress;
