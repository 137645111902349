import Arrow from 'components/svg/Arrow';
import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { cn } from 'utils';

const Button = ({
  cls,
  inputCls,
  icon,
  onClick,
  disabled,
  isLoading = false,
  ...btnProps
}) => {
  return (
    <button
      className={[
        'relative px-2 md:px-4 py-1 md:py-2 shadow-md border-gray-500 rounded-full w-auto text-center cursor-pointer capitalize flex flex-row flex-nowrap items-center justify-center text-sm',
        cls || 'bg-black text-white',
        disabled || isLoading
          ? 'pointer-events-none opacity-60'
          : 'pointer-events-all',
      ].join(' ')}
      onClick={onClick}
      tabIndex={0}
      type="button"
    >
      {icon && <span className="absolute left-0 ml-3">{icon}</span>}
      {isLoading ? (
        <span className="text-inherit animate-spin inline-block mx-2">
          <AiOutlineLoading />
        </span>
      ) : null}
      <input
        className={[
          'relative appearance-none bg-transparent cursor-pointer',
          inputCls,
          disabled || isLoading
            ? 'pointer-events-none opacity-60'
            : 'pointer-events-all',
        ].join(' ')}
        {...btnProps}
        disabled={disabled}
        tabIndex={-1}
      />
    </button>
  );
};

const CTA = ({ className, children, ...props }) => {
  return (
    <button
      className={cn(
        'relative w-max px-5 py-3 rounded-full font-semibold transition-all duration-300 ease-in-out hover:ps-7 hover:pe-14 inline-flex items-center justify-center gap-2 group',
        className
      )}
      {...props}
    >
      <span className="text-center inline-block whitespace-nowrap">
        {children}
      </span>
      <span className="absolute right-4 w-[22px] opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
        <Arrow />
      </span>
    </button>
  );
};

export { CTA };
export default Button;
