import React from "react";
import { IoChatbubbles, IoStar } from "react-icons/io5";
import { AiOutlineDelete } from "react-icons/ai";

import { Link } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import moment from "moment";

import Title from "components/shared/Title";
import LightBox from "./LightBox";

const VideoListItem = ({ item, isLoginUser }) => {
  const {
    uploadItemUrl,
    title,
    commentCount,
    likeCount,
    userImage,
    fullName,
    location,
    type,
    createdAt,
    userHandle,
    description,
  } = item;
  return (
    <div
      className='relative w-full flex flex-col pb-4 items-start justify-between overflow-hidden rounded-md mb-4 hover:shadow-2xl transition-shadow duration-300 border mx-auto'
      style={{ maxWidth: 350 }}
    >
      <div
        className={[
          "relative w-full overflow-hidden h-36",
          type !== "image" ? "player-wrapper" : "",
        ].join(" ")}
      >
        {type === "image" ? (
          <LightBox imgUrl={uploadItemUrl} />
        ) : (
          <ReactPlayer
            url={uploadItemUrl}
            controls={true}
            className='react-player'
            width='100%'
            height='100%'
          />
        )}
      </div>
      <div className='relative flex items-center justify-between w-full mt-2 px-2'>
        <Title text={title} size='sm' wrapperCls='' />
        <div className='relative flex items-center '>
          <span className='flex items-center mr-2 space-x-2'>
            <IoChatbubbles color='#78716C' />
            <span className='text-xs'>{commentCount}</span>
          </span>
          <span className='flex items-center space-x-2'>
            <IoStar color='#EAB308' />
            <span className='text-xs'>{likeCount}</span>
          </span>
        </div>
      </div>
      <div className='relative px-2 pt-2 flex items-center justify-between w-full'>
        <div className='relative flex items-center justify-start'>
          <Link to={isLoginUser ? `/my-profile` : `/friend/${userHandle}`}>
            <div className='relative w-8 h-8 overflow-hidden rounded-full'>
              <img
                src={userImage ? userImage : "/images/profile.png"}
                alt='profile-img'
                className='relative w-full h-full'
              />
            </div>
          </Link>
          <div className='relative flex flex-col items-start justify-center ml-2 text-gray-600'>
            <Link to={isLoginUser ? `/my-profile` : `/friend/${userHandle}`}>
              <span className='relative text-sm uppercase font-semibold tracking-normal'>
                {fullName}
              </span>
            </Link>
            <span className='relative capitalize font-normal text-xs'>
              {moment.utc(createdAt).local().startOf("seconds").fromNow()} -{" "}
              {location}
            </span>
          </div>
        </div>
        <div className='relative flex-1 flex items-center justify-end'>
          {isLoginUser ? (
            <span
              className='text-xl text-red-300 cursor-pointer'
              aria-hidden
              // onClick={() => onDeleteMsgGroup()}
            >
              <AiOutlineDelete />
            </span>
          ) : null}
        </div>
      </div>
      <div className='relative pl-4 pt-4 flex items-center'>
        <span className='relative  capitalize font-normal text-xs'>
          {description}
        </span>
      </div>
    </div>
  );
};

export default VideoListItem;
