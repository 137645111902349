import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import PageLayout from "components/shared/layouts/PageLayout";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";
import Button from "components/shared/Button";

import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { uploadVideo } from "../redux/actions/uploadActions";
import { useNavigate } from "react-router-dom";
import Progress from "components/shared/Progress";

import { storage } from "../firebase";

import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

const css =
  "relative w-full appearance-none p-2 md:p-4 rounded-md border border-gray-200";

const UploadVideo = (props) => {
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();

  //const watchFields = watch("session");

  //console.log(watchFields);

  const [uploadDate, setUploadDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const navigate = useNavigate();
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploadState, setUploadState] = useState("uploading");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isTrackVideo, setIsTrackVideo] = useState(true);

  const [perc, setPerc] = useState(0);

  async function uploadImage(image) {
    const storageRef = ref(storage, `/events/${Date.now()}-${image.name}`);
  
    const response = await uploadBytes(storageRef, image);
    const url = await getDownloadURL(response.ref);
    return url;
  }
  
  async function uploadImages(images) {
    const imagePromises = Array.from(images, (image) => uploadImage(image));
  
    const imageRes = await Promise.all(imagePromises);
    return imageRes; // list of url like ["https://..", ...]
  }

  const onsubmit = async (data) => {
    if (selectedFiles && selectedFiles.length > 0) {
      setDisableSubmit(true);
      data.imageUrls = await uploadImages(selectedFiles);  
      data.uploadItemUrl = data.imageUrls[0];
      console.log(data);
      props.uploadVideo(data, navigate);
      setDisableSubmit(false);
    }
  };

  const onDeleteClick = () => {
    setSelectedFile(null);
    setPercentUploaded(0);
  };

  const onFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    let progress = 0;

    reader.addEventListener("progress", (e, filename) => {
      if (e.lengthComputable) {
        var percentage = Math.round((e.loaded * 100) / e.total);
        setPerc(percentage);
      }
    });

    reader.readAsDataURL(file);
    setSelectedFile(e.target.files[0]);
  };
  const handleChange = (e) => {
    setIsTrackVideo(e.target.checked);
    //setChecked(!checked);
  };

  const onChangeImageInput = (e) => {
    const files = [...e.target.files];
    const uploaded = selectedFiles ? [...selectedFiles] : [];
    files.some((file) => {
      uploaded.push(file);
    });
    setSelectedFiles(uploaded);
    console.log(uploaded);
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full flex flex-col items-center  justify-center p-0'>
        <div className='relative w-1/3 my-4 md:my-8 text-center'>
          <span className='text-3xl tracking-widest text-white uppercase font-semibold'>
            upload video
          </span>
        </div>
        <form
          onSubmit={handleSubmit(onsubmit)}
          className='relative w-full md:w-1/3 px-8 md:px-0 flex flex-col items-center'
        >
          <label className='relative capitalize text-white mr-2'>
            <input
              type='checkbox'
              checked={isTrackVideo}
              onChange={handleChange}
            />
            Track Video
          </label>
          <InputWrapper name='title' fullWidth>
            <InputAlt
              label='title'
              name='title'
              placeHolder='Title'
              type='text'
              css={css}
              {...register("title")}
            />
          </InputWrapper>

          <InputWrapper name='description' fullWidth>
            <textarea
              label='description'
              name='description'
              placeholder='Brief Description'
              type='textarea'
              className={css}
              rows={4}
              cols={22}
              {...register("description")}
            />
          </InputWrapper>
          <InputWrapper name='uploadDate' fullWidth>
            <Controller
              control={control}
              name={"uploadDate"}
              render={({ field }) => (
                <DatePicker
                  selected={uploadDate}
                  placeholderText='Select date'
                  onChange={(date) => {
                    field.onChange(date);
                    setUploadDate(date);
                  }}
                  className={css}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper name='location' fullWidth>
            <InputAlt
              label='location'
              name='location'
              placeHolder='Location'
              type='text'
              css={css}
              {...register("location")}
            />
          </InputWrapper>
          <InputWrapper name='company' fullWidth>
            <InputAlt
              label='company'
              name='company'
              placeHolder='Track Company'
              type='text'
              css={css}
              disabled={!isTrackVideo}
              {...register("company")}
            />
            {/* <select
              name='company'
              id='company'
              className={css}
              placeholder='Track Company'
              {...register("company")}
            >
              <option value=''>None</option>
              <option value='Company1'>Company 1</option>
              <option value='Company2'>Company 2</option>
              <option value='Company3'>Company 3</option>
              <option value='Company4'>Company 4</option>
              <option value='Company5'>Company 5</option>
            </select> */}
          </InputWrapper>
          <InputWrapper name='session' fullWidth>
            <select
              name='session'
              id='session'
              className={css}
              placeholder='Session'
              {...register("session")}
              disabled={!isTrackVideo}
            >
              <option value=''>None</option>
              <option value='Session1'>Session 1</option>
              <option value='Session2'>Session 2</option>
              <option value='Session3'>Session 3</option>
              <option value='Session4'>Session 4</option>
              <option value='Session5'>Session 5</option>
              <option value='Session6'>Session 6</option>
              <option value='Session7'>Session 7</option>
              <option value='Session8'>Session 8</option>
            </select>
          </InputWrapper>
          <InputWrapper name='group' fullWidth>
            <select
              name='group'
              id='group'
              placeholder='Group'
              className={css}
              {...register("group")}
              disabled={!isTrackVideo}
            >
              <option value='Introduction/Group1'>Introduction/Group1</option>
              <option value='Group2'>Group2</option>
              <option value='Group3'>Group3</option>
              <option value='Expert/Group4'>Expert/Group4</option>
            </select>
          </InputWrapper>
          <InputWrapper name='city' fullWidth>
            <InputAlt
              label='city'
              name='city'
              placeHolder='City / State'
              type='text'
              css={css}
              {...register("city")}
            />
          </InputWrapper>

          {/* <div
            tabIndex={0}
            className='relative w-full my-1 text-white bg-gray-600 hover:bg-gray-500 rounded-md p-4 flex items-center justify-center cursor-pointer'
          >
            <label htmlFor='file' className='capitalize'>
              choose video
            </label>
            <input
              type='file'
              name='file'
              id='file'
              accept='video/mp4,video/x-m4v,video/*'
              className='opacity-0 absolute appearance-none w-full cursor-pointer'
              {...register("file")}
              onChange={onFileInputChange}
              tabIndex={-1}
            />
          </div>
          {selectedFile ? (
            <Progress
              percentage={perc}
              filename={selectedFile && selectedFile.name}
              uploadState={uploadState}
              onDelete={onDeleteClick}
            />
          ) : null} */}

          <InputWrapper
            label={`${selectedFile ? selectedFile.name : "Attach Files"}`}
            name='file'
            labelCls={
              "relative bg-white text-sm text-blue-500 text-left w-full font-semibold relative w-full appearance-none p-2 md:p-4 rounded-md border border-gray-200 py-5 cursor-pointer hover:bg-gray-200"
            }
            fullWidth
          >
            <input
              type='file'
              name='file'
              id='file'
              multiple
              className='input-file'
              accept='video/mp4,video/x-m4v,video/*'
              {...register("attachments")}
              onChange={onChangeImageInput}
            />
            <div
              className='w-full bg-white flex flex-col items-start overflow-y-scroll'
              style={{ maxHeight: 100 }}
            >
              {selectedFiles &&
                selectedFiles.map((file, i) => (
                  <span className='text-xs py-0.5 px-1' key={i}>
                    {i + 1}. {file.name}
                  </span>
                ))}
            </div>
          </InputWrapper>
          <Button
            type='submit'
            cls='bg-blue-600 hover:bg-blue-400 text-white w-full md:w-1/3 my-6 uppercase'
            value='Submit Video'
            disabled={disableSubmit}
            //   onClick={onsubmit}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  uploadVideo,
};

export default connect(mapStateToProps, mapActionsToProps)(UploadVideo);
