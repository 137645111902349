import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import PageLayout from "components/shared/layouts/PageLayout";
import ProfileHeader from "components/ProfileHeader";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import Button from "components/shared/Button";

import { PROFILE_HEADER_BUTTONS } from "store";
import { Fragment } from "react";

import { getMyHelpsData,  updateNotifications } from "../../redux/actions/commonActions";

const Helps = (props) => {
  const [helps, setHelps] = useState([]);
  const navigate = useNavigate();

  const [helpsList, setHelpsList] = useState([]);

  useEffect(() => {
    props.updateNotifications("help");
    props
      .getMyHelpsData()
      .then((data) => {
        setHelpsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}
    >
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={props.user.credentials}
        isAuthenicated={props.user.authenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
      <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <div className='relative flex-1 my-4 p-4 max-h-96 overflow-hidden'>
          <Title
            text={
              <span>
                helps{" "}
                <span className='text-gray-400 text-xl md:text-4xl'>
                  ({helpsList.length})
                </span>
              </span>
            }
            size='xl'
            wrapperCls='pb-4'
          />
          <div className='relative w-full max-h-80 overflow-y-scroll border-t'>
            <div className='relative w-full h-auto'>
              {helpsList.map((help, i) => {
                return (
                  <Fragment key={i.toString()}>
                    <div className='relative w-full flex items-center justify-between border-b'>
                      <div className='relative p-2 flex flex-col items-start w-32'>
                        <img
                          src={help.userImage}
                          alt='user'
                          className='w-8 lg:w-16 h-8 lg:h-16 object-fill rounded-full'
                        />
                        <span className='text-sm py-2 capitalize text-gray-400 font-semibold'>
                          {help.fullName}
                        </span>
                      </div>
                      <div className='relative flex-1 px-8 py-2'>
                        {help.description}
                      </div>
                      <div className='relative flex flex-row items-center justify-end px-4'>
                        <Button
                          type='button'
                          cls='bg-blue-500 text-white  tracking-widest uppercase my-1 md:my-0'
                          inputCls={"text-xs md:text-base"}
                          value='View'
                          onClick={() => navigate(`/helps/${help.helpId}`)}
                        />
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapActionsToProps = {
  getMyHelpsData,
  updateNotifications
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(Helps);
