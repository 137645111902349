import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import { InputAlt, Select } from "components/shared/InputElements";
import InputWrapper from "components/shared/InputWrapper";
import Title from "components/shared/Title";
import { privacy } from "store/privacy";

import { CURRENT_USER } from "store";

import { connect } from "react-redux";
import { updateUser } from "../../redux/actions/userActions";

const EditAccount = (props) => {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({});
  useEffect(() => {
    if (props.user.credentials && props.user.credentials.userId) {
      setUser(props.user.credentials);
    }
  }, [props.user.credentials]);

  const css = {
    input:
      "relative w-full appearance-none p-4 rounded-md border border-gray-200",
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    setUser((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function handleDropDownChange(e, key) {
    setUser((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  const onsubmit = () => {
    console.log(user);
    props.updateUser(user, navigate);
  };
  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full md:w-1/2 mx-auto h-full flex flex-col items-center justify-start'>
        <Title text='account settings' size='lg' wrapperCls='my-8' />
        <form
          onSubmit={handleSubmit(onsubmit)}
          className='relative w-full px-8 h-full'
        >
          <div className='relative flex flex-col items-center justify-center'>
            <div
              className=' relative w-32 h-32 mt-4 mb-8 text-white bg-gray-600 rounded-full px-4 py-2 flex items-center justify-center bg-cover border'
              style={{
                backgroundImage: `url(${
                  user && user.imageUrl !== ""
                    ? user.imageUrl
                    : "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
                })`,
              }}
            />
            <InputWrapper label='Screen Name' name='fullName' fullWidth>
              <InputAlt
                label='fullName'
                name='fullName'
                //placeHolder={user?.fullName?.toUpperCase()}
                type='text'
                css={css.input}
                value={user?.fullName?.toUpperCase()}
                {...register("fullName")}
                onChange={(e) => handleChange(e)}
              />
            </InputWrapper>

            {/* <InputWrapper label='Password' name='sName' fullWidth> */}
            <span className='relative w-full flex-1 flex flex-col items-start'>
              {/* <InputAlt
                  label={"password"}
                  name={"password"}
                  placeHolder={"PASSWORD"}
                  value=''
                  type={showPassword ? "text" : "password"}
                  css={css.input}
                  {...register("password")}
                /> */}
              <span className='my-2 w-full flex items-center justify-between'>
                {/* <span>
                    <input
                      type='checkbox'
                      name='showPw'
                      id='showPw'
                      onClick={toggleShowPassword}
                    />
                    <label htmlFor='showPw' className='ml-1 text-xs'>
                      Show
                    </label>
                  </span> */}
                <span
                  className='relative text-xs text-blue-600 cursor-pointer capitalize'
                  onClick={() => navigate("/change-password")}
                >
                  change password &rarr;
                </span>
              </span>
            </span>
            {/* </InputWrapper> */}
            <InputWrapper label='Privacy' name='privacy' fullWidth>
              <select
                label={"privacy"}
                name={"privacy"}
                className={[css.input, "bg-transparent"].join(" ")}
                {...register("privacy")}
                value={user?.privacy}
                onChange={(e) => handleDropDownChange(e, "privacy")}
              >
                {privacy.map((c, i) => (
                  <option value={c.code} key={c.name + i}>
                    {c.name}
                  </option>
                ))}
              </select>
            </InputWrapper>
            <hr style={{height:"2px",borderWidth:0,color:"gray",backgroundColor:"gray"}}></hr>
            <InputWrapper label='TOW' name='tow' fullWidth>
              <InputAlt
                label='tow'
                name='tow'
                //placeHolder={user?.tow?.toUpperCase()}
                type='number'
                css={css.input}
                value={user?.tow?.toUpperCase()}
                {...register("tow")}
                onChange={(e) => handleChange(e)}
              />
            </InputWrapper>
            <InputWrapper label='Insurance' name='insurance' fullWidth>
              <InputAlt
                label='insurance'
                name='insurance'
                //placeHolder={user?.insurance?.toUpperCase()}
                type='number'
                css={css.input}
                value={user?.insurance?.toUpperCase()}
                {...register("insurance")}
                onChange={(e) => handleChange(e)}
              />
            </InputWrapper>
            <InputWrapper label='Legal' name='legal' fullWidth>
              <InputAlt
                label='legal'
                name='legal'
                //placeHolder={user?.legal?.toUpperCase()}
                type='number'
                css={css.input}
                value={user?.legal?.toUpperCase()}
                {...register("legal")}
                onChange={(e) => handleChange(e)}
              />
            </InputWrapper>

          </div>
          <div className='relative w-full flex items-center justify-evenly'>
            <Button
              type='button'
              cls='bg-gray-700 text-white my-3 w-1/3'
              value='Cancel'
              onClick={() => navigate(-1)}
            />
            <Button
              type='submit'
              cls='bg-blue-600 text-white my-3 w-1/3'
              value='Save'
              //   onClick={onsubmit}
            />
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(EditAccount);
