const InputWrapper = ({
  children,
  label,
  name,
  fullWidth,
  wrapperCls,
  labelCls,
  required,
}) => {
  return name === 'file' ? (
    <div
      className={[
        'relative my-1 flex flex-col items-center justify-start test-sm',
        fullWidth ? 'w-full' : 'w-2/3',
        wrapperCls,
      ].join(' ')}
    >
      <label
        htmlFor={name}
        className={[
          'relative text-sm text-blue-600 text-left w-full font-semibold',
          labelCls,
        ].join(' ')}
        tabIndex={0}
      >
        {label}
        {required && '*'}
        {children}
      </label>
    </div>
  ) : (
    <div
      className={[
        'relative my-1 flex flex-col items-center justify-start test-sm',
        fullWidth ? 'w-full' : 'w-2/3',
        wrapperCls,
      ].join(' ')}
    >
      <label
        htmlFor={name}
        className={[
          'relative text-sm text-blue-600 text-left w-full font-semibold',
          labelCls,
        ].join(' ')}
        tabIndex={-1}
      >
        {label}
        {required && '*'}
      </label>
      {children}
    </div>
  );
};

export default InputWrapper;
