import React from 'react';
import { RiAttachment2, RiSendPlaneFill } from 'react-icons/ri';
const MessageForm = ({ handleSubmit, text, setText, setImg }) => {
  return (
    <form
      className="relative w-full flex items-center justify-between p-4 border-t"
      onSubmit={handleSubmit}
    >
      <label
        htmlFor="img"
        className="relative text-blue-500 text-sm md:text-xl pr-8 cursor-pointer"
      >
        <RiAttachment2 />
      </label>
      <input
        onChange={(e) => setImg(e.target.files[0])}
        type="file"
        id="img"
        accept="image/*"
        style={{ display: 'none' }}
      />
      <div className="flex-1">
        <input
          type="text"
          placeholder="Enter message"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="relative w-full appearance-none px-1 md:px-4 py-2 text-sm md:text-base outline-none border-blue-600 flex-1"
        />
      </div>
      <button
        type="submit"
        className="relative text-sm md:text-xl py-2 px-4 rounded-full flex items-center justify-center text-blue-600 bg-gray-200 hover:bg-gray-600 hover:text-white"
      >
        <span className="text-sm mr-2">Send</span> <RiSendPlaneFill />
      </button>
    </form>
  );
};

export default MessageForm;
