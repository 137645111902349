import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/userReducer';
//import dataReducer from './reducers/dataReducer';
import uiReducer from './reducers/uiReducer';
import commonReducer from './reducers/commonReducer';
import eventReducer from './reducers/commonReducer';
import uploadReducer from './reducers/uploadReducer';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  user: userReducer,
  UI: uiReducer,
  common : commonReducer,
  event : eventReducer,
  upload : uploadReducer
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;