import React, { useState, useEffect, forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import PageLayout from "components/shared/layouts/PageLayout";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import Button from "components/shared/Button";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";
import { countries } from "store/countries";
import { states } from "store/states";

import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import {
  updateUser,
  updateProfileImage,
} from "../../redux/actions/userActions";

const _css =
  "relative w-full appearance-none p-2 md:p-4 rounded-md border border-gray-200";

const EditProfile = (props) => {
  const navigate = useNavigate();
  const { control, handleSubmit, register } = useForm();
  const [birthDate, setBirthDate] = useState(new Date());
  const [user, setUser] = useState({});
  const [countryCode, setCountryCode] = useState(
    props.user?.credentials?.country
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const onsubmit = () => {
    console.log(user);
    props.updateUser(user, navigate);
  };

  useEffect(() => {
    if (!props.user.credentials) return;
    if (props.user.credentials.userId) {
      setUser(props.user.credentials);
    }
  }, [props.user.credentials]);

  const photoUpload = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        const imageData = {
          data: reader.result,
          fileName: file.name,
          id: props.user.credentials.id,
        };

        props.updateProfileImage(imageData);
        // setUser(prev => ({
        //   ...prev,
        //   ['imageUrl']: reader.result,
        //   ['isImageChange'] : true
        // }))
      };
      reader.readAsDataURL(file);
    }
  };

  function handleChange(e) {
    const key = e.target.name;
    const value = e.target.value;

    setUser((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function handleDateChange(date, key) {
    setUser((prev) => ({
      ...prev,
      [key]: date,
    }));
  }

  function handleDropDownChange(e, key) {
    setUser((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  }

  function getYears() {
    const currentYear = new Date().getFullYear(),
      years = [];
    let startYear = currentYear - 100;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }

  const DateTimePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      type='button'
      onClick={onClick}
      ref={ref}
      className={[_css, "flex justify-start"].join(" ")}
    >
      {value}
    </button>
  ));

  // console.log(user);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full md:w-1/2 mx-auto h-full flex flex-col items-center justify-start'>
        <Title text='edit profile' size='lg' wrapperCls='my-8' />
        <form onSubmit={handleSubmit(onsubmit)}>
          {/* <div
          className='relative w-full h-60 md:h-72 border-b-2 border-blue-600'
          // style={{ height: 300 }}
        >
          <img
            src='/images/signup.jpg'
            alt='header'
            className='absolute object-bottom object-cover w-full h-full z-0'
          />
        </div> */}
          <div className='relative w-full pb-4 flex items-center justify-center mt-1'>
            <div className='relative w-full px-4 md:px-0 flex flex-col items-center justify-center'>
              <div className='relative w-1/3 flex items-center justify-center mt-4 pb-16'>
                <div
                  className=' relative w-8 h-8 md:w-32 md:h-32 mt-4 mb-4 text-white bg-gray-600 border rounded-full px-4 py-2 flex items-center justify-center bg-cover'
                  style={{
                    backgroundImage: `url(${
                      user && user.imageUrl !== ""
                        ? user.imageUrl
                        : "https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true"
                    })`,
                  }}
                >
                  <label
                    htmlFor='uploadPicture'
                    className='capitalize absolute w-max top-full mt-2 text-white text-xs bg-blue-600 p-2 rounded-full'
                  >
                    change picture
                  </label>
                  <input
                    type='file'
                    name='uploadPicture'
                    id='uploadPicture'
                    className='opacity-0 absolute appearance-none w-full'
                    {...register("profilePic")}
                    onChange={(e) => photoUpload(e)}
                  />
                </div>
                {/* <ImgUpload onChange={(e) => photoUpload(e)}
                  src={user && user.imageUrl !== '' ? user.imageUrl : 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true'} /> */}
                {/* <div
                className=" relative w-32 h-32 mt-4 mb-4 text-white bg-gray-600 rounded-full px-4 py-2 flex items-center justify-center bg-cover"
                style={{ backgroundImage: `url(${user.imageUrl})` }}
               >
                <label
                  htmlFor="uploadPicture"
                  className="capitalize absolute top-full mt-2 text-white text-xs bg-blue-500 p-2 rounded-full"
                >
                  change picture
                </label>
                <input
                  type="file"
                  name="uploadPicture"
                  id="uploadPicture"
                  className="opacity-0 absolute appearance-none w-full"
                  onChange={(e) =>  {alert('uploaded')}}
                  {...register("profilePic")}
                />
              </div> */}
              </div>

              <InputWrapper label='Full Name' name='fullName' fullWidth>
                <InputAlt
                  label='fName'
                  name='fullName'
                  placeHolder='Full Name'
                  value={user.fullName}
                  type='text'
                  css={_css}
                  {...register("fullName")}
                  onChange={(e) => handleChange(e)}
                />
              </InputWrapper>
              {/* <InputWrapper label='User Name' name='userName' fullWidth>
                <InputAlt
                  label='uName'
                  name='userName'
                  placeHolder='User Name'
                  value={user.userName}
                  type='text'
                  css={_css}
                  {...register("userName")}
                  onChange={(e) => handleChange(e)}
                />
              </InputWrapper> */}

              {/* <InputWrapper label='Birthday' name='birthday' fullWidth>
                <Controller
                  control={control}
                  name={"birthday"}
                  render={({ field }) => (
                    <DatePicker
                      className={_css}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {getYears().map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      customInput={<DateTimePickerCustomInput />}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='scroll'
                      selected={
                        user.birthDate && user.birthDate !== ""
                          ? new Date(user.birthDate)
                          : new Date()
                      }
                      onChange={(date) => handleDateChange(date, "birthDate")}
                      //dateFormat="dd/MM/yyyy"
                      value={user.birthDate ? new Date(user.birthDate) : null}
                      maxDate={new Date()}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                    />
                  )}
                />
              </InputWrapper> */}
              <InputWrapper label='Country' name='country' fullWidth>
                <select
                  name='country'
                  id='country'
                  className={_css}
                  {...register("country")}
                  onChange={(e) => handleDropDownChange(e, "country")}
                  value={user.country}
                >
                  {/* <option value="United States of America">
                United States of America
              </option>
              <option value="Canada">Canada</option> */}
                  {countries.map((c, i) => (
                    <option value={c.code} key={c.name + i}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </InputWrapper>
              {/* <div className='relative flex flex-col md:flex-row items-center w-full justify-evenly space-x-2'>
                <InputWrapper
                  label='City'
                  name='city'
                  wrapperCls={"flex-1 w-full md:w-2/3"}
                >
                  <InputAlt
                    label='city'
                    name='city'
                    placeHolder='City'
                    type='text'
                    css={_css}
                    {...register("city")}
                    value={user.city}
                    onChange={(e) => handleChange(e)}
                  />
                </InputWrapper>
                <InputWrapper
                  label='State'
                  name='state'
                  wrapperCls={"flex-1 w-full md:w-2/3"}
                >
                  <select
                    name='state'
                    id='state'
                    className={_css}
                    {...register("state")}
                    onChange={(e) => handleDropDownChange(e, "state")}
                    value={user.state}
                  > */}
                    {/* <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Canada">Canada</option> */}
                    {/* {states
                      .filter((item) => item.countryCode === user.country)
                      .map((c, i) => (
                        <option value={c.code} key={c.name + i}>
                          {c.name}
                        </option>
                      ))}
                  </select>
                </InputWrapper>
              </div> */}

              <div className='relative w-full flex items-center justify-evenly'>
                <Button
                  type='button'
                  cls='bg-gray-600 text-white mt-8 w-1/3 tracking-widest uppercase'
                  value='Cancel'
                  onClick={() => navigate(-1)}
                />
                <Button
                  type='submit'
                  cls='bg-blue-600 text-white mt-8 w-1/3 tracking-widest uppercase'
                  value='Save'
                  onClick={onsubmit}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  updateUser,
  updateProfileImage,
};

export default connect(mapStateToProps, mapActionsToProps)(EditProfile);
