import React, {useState} from "react";
import { useForm } from "react-hook-form";

import PageLayout from "components/shared/layouts/PageLayout";
import Button from "components/shared/Button";
import { InputAlt } from "components/shared/Input";
import InputWrapper from "components/shared/InputWrapper";
import Title from "components/shared/Title";

import { connect } from 'react-redux';
import { createSuggeston } from "../../redux/actions/commonActions";
import { useNavigate } from "react-router-dom";

const Suggestions = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const css =
    "relative w-full appearance-none p-4 rounded-md border border-gray-200";

  const onsubmit = (data) => {
    if (selectedFile) {
      console.log(data);
      props.createSuggeston(data, selectedFile, navigate);
    }
  };

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative flex flex-col w-full items-center justify-start px-4 md:px-64 pb-32'>
        <Title text='suggestions' size='lg' wrapperCls='my-8' />
        <form onSubmit={handleSubmit(onsubmit)} className='relative w-2/3 mt-8'>
          <InputWrapper label='Your Name' name='name' fullWidth>
            <InputAlt
              label='name'
              name='name'
              placeHolder='Your Name'
              type='text'
              css={css}
              {...register("name")}
            />
          </InputWrapper>
          <InputWrapper label='Your Email' name='email' fullWidth>
            <InputAlt
              label='email'
              name='email'
              placeHolder='Your Email'
              type='email'
              css={css}
              {...register("email")}
            />
          </InputWrapper>
          <InputWrapper label='Your Message' name='message' fullWidth>
            <textarea
              label='message'
              name='message'
              placeholder='Your Message'
              type='textarea'
              className={css}
              rows={4}
              cols={22}
              {...register("message")}
            />
          </InputWrapper>
          <InputWrapper label='Attach Files' name='file' fullWidth>
            <InputAlt
              label='file'
              name='file'
              placeHolder='Attach Files'
              type='file'
              css={css}
              {...register("file")}
              onChange={(e) => setSelectedFile(e.target.files[0])}
            />
          </InputWrapper>
          <Button
            type='submit'
            cls='bg-blue-500 text-white my-3 mx-auto w-1/3'
            inputCls='capitalize'
            value='Send'
            //   onClick={onsubmit}
          />
        </form>
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  createSuggeston
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(Suggestions);
