import React from "react";

const FormWrapper = ({ children, cls }) => {
  return (
    <div
      className={["relative w-full flex flex-col p-8 bg-transparent", cls].join(
        " "
      )}
      style={{ maxWidth: 500 }}
    >
      {children}
    </div>
  );
};

FormWrapper.defaultProps = {
  cls: "items-center justify-center",
};

export default FormWrapper;
