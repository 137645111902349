import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";

import PageLayout from "components/shared/layouts/PageLayout";
import ProfileHeader from "components/ProfileHeader";
import SideBar from "components/SideBar";
import Title from "components/shared/Title";
import { IoLocationOutline } from "react-icons/io5";

import { PROFILE_HEADER_BUTTONS } from "store";
import { getHelpData } from "../../redux/actions/commonActions";

const HelpSingle = (props) => {
  const [bike, setBike] = useState([]);
  const location = useLocation();
  const isAuthenticated = props.user.authenticated;
  const curUser = props.user.credentials;
  const [helpData, setHelpData] = useState(null);

  useEffect(() => {
    const query = location.pathname;
    if (query !== null) {
      const id = query.split("/").pop();
      props
        .getHelpData(id)
        .then((data) => {
          setHelpData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const renderLocationDataBlock = (icon, line1) => {
    return (
      // <Link to={`/navigation?dst=${line1}`}>
        <div className='relative text-black w-full flex items-center py-4 md:py-8 map_hover' onClick={() => showInMapClicked(line1)}>
          <span className='relative text-xl md:text-4xl mr-8'>{icon}</span>
          <span className='text-base font-semibold'>{line1}</span>
        </div>
      // </Link>
    );
  };

  const showInMapClicked = (des) => {
    window.open(`https://www.google.lk/maps/dir/${props.user.credentials.latitude},${props.user.credentials.longitude}/${des}`, '_blank', 'noreferrer' );
  };

  return (
    <PageLayout 
      lat={props.user?.credentials?.latitude}
      lan={props.user?.credentials?.longitude}
      isAuthenicated={props.user?.authenticated}
      >
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={curUser}
        isAuthenicated={isAuthenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
      <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        <div className='relative flex-1 my-4 p-4 max-h-96 overflow-hidden'>
          <Title text={helpData?.issue} size='xl' wrapperCls='pb-4' />
          <div className='relative w-full max-h-80 overflow-y-scroll border-t'>
            <div className='relative w-full h-auto'>
              {helpData ? (
                <div className='relative w-full flex flex-col items-start justify-between'>
                  <div className='relative w-full mt-8 flex flex-row items-center justify-start'>
              {renderLocationDataBlock(<IoLocationOutline />, helpData?.location)}
            </div>
                  <div className='relative flex-1 py-2'>
                    {helpData?.description}
                  </div>
                  <div className='relative flex-1 py-2'>
                    {helpData?.fullname}
                  </div>
                </div>
              ) : (
                <span className='text-sm py-2 capitalize text-gray-400 font-semibold'>
                  no details found
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapActionsToProps = {
  getHelpData,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, mapActionsToProps)(HelpSingle);
