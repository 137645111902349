import React, { useEffect, useState } from "react";
import PageLayout from "components/shared/layouts/PageLayout";

import SideBar from "components/SideBar";

import VideoList from "components/VideoList";
import UsersList from "components/UsersList";

import { connect } from "react-redux";
import { getUploadsByRidersNearbyData } from "../redux/actions/uploadActions";

const RidersNearby = (props) => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    props
      .getUploadsByRidersNearbyData()
      .then((data) => {
        setUserList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <div className='relative w-full flex items-start justify-start'>
      <SideBar isAuthenicated={props.user?.authenticated} lat={props.user?.credentials?.latitude} lan={props.user?.credentials?.longitude} />
        {/* <VideoList data={userList} currentUser={props.user.credentials} /> */}
        <UsersList users={userList} currentUser={props.user.credentials} />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getUploadsByRidersNearbyData,
};

export default connect(mapStateToProps, mapActionsToProps)(RidersNearby);
