import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "components/shared/layouts/PageLayout";
import SideBar from "components/SideBar";
import VideoList from "components/VideoList";
import ProfileHeader from "components/ProfileHeader";

import { PROFILE_HEADER_BUTTONS } from "store";

import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { getMyUploadData } from "../../redux/actions/uploadActions";

const MyProfile = (props) => {
  const [videoList, setVideoList] = useState([]);
  useEffect(() => {
    props
      .getMyUploadData()
      .then((data) => {
        setVideoList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <PageLayout lat={props.user?.credentials?.latitude}
    lan={props.user?.credentials?.longitude}
    isAuthenicated={props.user?.authenticated}>
      <ProfileHeader
        buttonNames={PROFILE_HEADER_BUTTONS}
        currentUser={props.user.credentials}
        isAuthenicated={props.user.authenticated}
      />
      <div className='relative w-full flex items-start justify-start'>
        <SideBar
          isAuthenicated={props.user?.authenticated}
          lat={props.user?.credentials?.latitude}
          lan={props.user?.credentials?.longitude}
        />
        <VideoList data={videoList} currentUser={props.user.credentials} />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getMyUploadData,
};

export default connect(mapStateToProps, mapActionsToProps)(MyProfile);
