import { useState, useLayoutEffect } from "react";

const useBounds = (sub) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setBounds();
    window.addEventListener("resize", setBounds());
  }, [window?.innerWidth]);

  const setBounds = () => {
    setHeight(window.innerHeight - sub);
  };

  return { height };
};

export default useBounds;
